.filter-button-container {
  display: flex;
  align-items: center;

  .filter-button {
    margin-left:auto;
    height: 35px !important;
    width: 100%;
    max-width: 102px;
    padding: 0 !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    border: 1px solid rgb(217, 217, 217) !important;
    color: rgba(0, 0, 0, 0.7) !important;
    font-weight: bold;

    &.has-filters {
      background: #3D4CC1;
      color: #ffffff !important;
    }

    .filter-counter {
      font-size: 10px;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #F75497;
      background-color: #ffffff;
      font-weight: bold;
      margin: 0 0 0 8px;
      border-radius: 3px;
      border: 1px solid rgb(185, 198, 228);
    }
  }

  .filter-clear-button {
    font-weight: bold;
    margin: 0 0 0 10px;
    color: #F75497;
    text-decoration: underline;
    cursor: pointer;
  }
}
