.form-select {
  caret-color: $mainBlueColor;
  width: 100%;

  &.ant-select.ant-select-single.ant-select-focused {
    .ant-select-selector {
      border-color: $mainBlueColor;
      box-shadow: none;
    }
  }
}
