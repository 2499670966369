.sign-in {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mobile {
    box-shadow: none;
    padding: 24px 16px 12px;
  }

  &__form {
    width: 100%;
  }

  &__title {
    font-size: 48px;
    font-weight: bold;
    text-align: center;
    color: $blackColor;
    margin-bottom: 16px;
  }

  &__field {
    margin-bottom: 24px;
    font-size: 14px;

    &:nth-child(3) {
      margin-bottom: 10px;
    }
  }

  &__label {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 8px;
  }

  &__input {
    caret-color: $mainBlueColor;

    &, &.ant-input {
      margin-top: 8px;
    }

    &:focus.ant-input,
    &:focus.ant-input-password,
    &:hover.ant-input-affix-wrapper-focused,
    &.ant-input-affix-wrapper-focused {
      border-color: $mainBlueColor;
      box-shadow: none;
    }

    &--error.ant-input,
    &--error.ant-input-password {
      border-color: $errorBorderColor;

      &:focus {
        border-color: $errorBorderColor;
      }
    }
  }

  &__error-container {
    display: flex;
    justify-content: space-between;
  }

  &__error {
    display: block;
    color: $errorColor;
    font-size: 12px;
  }

  &__forgot-password-link {
    display: block;
    font-size: 12px;
    color: $pinkColor;
    text-decoration: underline;
    margin-left: auto;
    margin-top: 16px;
    cursor: pointer;

    &:hover,
    &:active,
    &:visited {
      color: $darkPinkColor;
    }
  }

  &__submit.ant-btn {
    height: 40px;
    font-size: 18px;
    margin-top: 18px;
    padding: 0 32px;
    border-radius: 4px;
  }

  &__sign-up-text {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 22px;
  }

  &__sign-up-link {
    color: $mainBlueColor;
    text-decoration: underline;

    &:hover,
    &:active,
    &:visited {
      color: $mainBlueColor;
    }
  }

  &__or {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 18px 0;
    font-size: 12px;

    &:before {
      margin-right: 16px;
    }

    &:after {
      margin-left: 16px;
    }
  }

  &__terminated {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px;

    img {
      width: 80px;
      height: 80px;
      margin-bottom: 16px;
    }

    &-text {
      font-size: 14px;
    }

    @include mobile {
      padding: 32px 16px;
    }
  }

  &__with-google {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px;
    color: $blackColor;
    font-size: 18px;
    text-align: center;
    border: 1px solid $greyBorderColor;
    border-radius: 4px;
    cursor: pointer;
    height: 40px;

    &:hover,
    &:active,
    &:visited {
      color: $blackColor;
    }

    @include mobile {
      margin-bottom: 0;
    }
  }

  &__google-logo {
    margin: 0 8px;
  }
}
