.check-date-item {
  display: flex;
  flex-direction: column;
  border: 1px solid $greyColor5;
  border-radius: 8px;
  padding: 8px 16px;
  background-color: #fcfcfc;
  text-align: left;

  &__name {
    font-weight: bold;
  }

  &__date-wrapper {
    display: flex;
    align-items: center;
  }

  &__date {
    color: $greyColor4;
  }

  &__icon {
    margin-right: 8px;
    width: 14px;
  }
}
