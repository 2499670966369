.cookies-banner {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  background-color: #020202;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  padding: 8px 24px;
  z-index: 1001;

  @include mobile {
    padding: 8px 16px;
  }

  &__text {
    font-size: 18px;
    color: $whiteColor;
    opacity: 0.8;

    @include mobile {
      font-size: 14px;
    }
  }

  &__link {
    color: $whiteColor;
    text-decoration: underline;

    &:hover,
    &:active,
    &:focus,
    &:visited {
      color: $whiteColor;
      text-decoration: underline;
    }
  }

  &__button {
    &.ant-btn {
      padding: 7px 23px;
      text-transform: capitalize;
      font-size: 18px;
      border-radius: 8px;
      margin-left: 15px;

      @include mobile {
        padding: 5px 10px;
        font-size: 14px;
      }
    }
  }
}
