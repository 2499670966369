.menu-tabs.ant-menu {
  border-bottom: none;
  line-height: 24px;
  margin: 0 -16px;
  background-color: inherit;

  .ant-menu-item-selected {
    font-weight: bold;
  }

  .ant-menu-item {
    padding: 0;
    margin: 0 16px
  }
}
