.submit-button {
  font-size: 18px;
  height: 40px;

  &.ant-btn {
    @extend .submit-button;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 32px;
  }
  
  &__success {
    &.ant-btn {
      padding-left: 16px;
    }

    .success-icon {
      margin-right: 10px;
    }
  }
}
