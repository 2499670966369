.footer {
  position: absolute;
  bottom: 0;
  background-color: $whiteColor;
  width: 100%;
  height: 300px;
  padding-top: 40px;
  font-size: 20px;
  color: #000;

  @include tablet {
    height: 560px;
  }

  @include mobile {
    padding-top: 0px;
    font-size: 16px;
    height: 560px;
  }

  &__content {
    &.ant-col {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      @include mobile {
        text-align: center;
      }
    }
  }

  &__middle {
    padding: 16px 0 8px;

    @include mobile {
      padding: 20px 0;
    }
  }

  &__nav-link {
    display: block;
    margin: 16px 0;
    color: #000;

    &:first-child {
      margin-top: 0;
    }

    &-container {
      padding-right: 16px;

      @include tablet {
        margin-bottom: 50px;
      }

      @include mobile {
        padding-right: 0;
      }
    }

    &:hover,
    &:active,
    &:visited,
    &:focus {
      color: #000;
    }

    @include mobile {
      margin: 10px 0;
    }
  }

  &__logo {
    max-width: 200px;
  }

  &__bottom {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px 24px;
    height: 50px;
    border-top: 1px solid $greyColor4;

    @include mobile {
      padding: 8px 16px;
      border: none;
      height: auto;
    }
  }

  &__title {
    margin-bottom: 24px;

    @include mobile {
      margin: 24px 0;
    }
  }

  &__social-link {
    display: inline-block;
    margin: 0 15px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    img {
      width: 24px;
      height: 24px;
    }
  }

  &__copyright {
    &.ant-col {
      display: flex;
      justify-content: flex-end;
      font-size: 16px;

      @include mobile {
        justify-content: center;
      }
    }
  }

  &__subscribe {
    padding-right: 16px;

    @include mobile {
      padding: 0 16px;
      margin-bottom: 32px;
    }
  }

  &.footer-mobile {
    display: none;
  }

  .socials-link-wrapper {
    @include mobile {
      justify-content: center;
    }
  }
}

@include mobile {
  .auth {
    &-row {
      min-height: auto;
    }
    &-content {
      height: auto;
    }
  }
  .footer-desktop {
    display: none !important;
  }
}
