.latest-news {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  min-height: 167px;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  } 
}