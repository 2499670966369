.rich-text-editor {
  background: $whiteColor;

  .ql-toolbar {
    border-radius: 4px 4px 0 0;
  }

  .ql-container {
    border-radius: 0 0 4px 4px;
  }

  .ql-editor {
    min-height: 200px;
  }
}
