.account-details {
  &__container {
    display: flex;
  }

  &__item, &__progress-item {
    display: flex;
    align-items: center;
  }

  &__progress-item {
    flex: 1;
  }

  &__item-name {
    font-weight: bold;
    padding: 0 12px;
    white-space: nowrap;
  }

  &__progress {
    .ant-progress-bg {
      background-color: #71C639;
    }

    .ant-progress-inner, .ant-progress-bg {
      border-radius: 4px;
    }
  }

  &__percent {
    color: #71C639;
    font-weight: bold;
    padding-left: 12px;
  }

  &__additional-info {
    display: inline-block;
    margin-top: 8px;
    color: $greyColor4;
  }
}
