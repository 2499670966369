.image {
  width: 100%;
  height: 100%;
  object-fit: cover;

  &--empty {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #F3F3F4;
    background-color: lighten($mainBlueColor, 40);

    img {
      width: 100px;
    }
  }
}
