.top-scroll-table-container {
  position: relative;

  .top-scroll-table__top-scroll {
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    height: 20px;
  }

  .top-scroll-table__scroll-body {
    height: 1px;
  }
}
