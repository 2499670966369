.count-button {
  &.ant-btn {
    padding: 0;
    border-color: $greyColor4;
    border-radius: 50%;
    stroke: black;
    stroke-width: 8px;
    width: 24px;
    height: 24px;
    min-width: 0;
    font-size: 0;
  }

  svg {
    width: 12px;
    height: 12px;
  }

  &.ant-btn:hover {
    border-color: $greyColor;
  }

  &.ant-btn:focus {
    border-color: $greyColor4;
  }

  &.ant-btn[disabled],
  &.ant-btn[disabled]:hover {
    background-color: transparent;
    border-color: $greyColor4;
    fill: $greyColor4;
    stroke: $greyColor4;
  }
}
