.hotel-card {
  font-size: 14px;

  &__image-wrapper {
    position: relative;
    height: 100%;
    overflow: hidden;
  }

  &__hotel-image {
    position: absolute;
    height: 360px;
    width: calc(100% + 8px);
    object-fit: cover;
    object-position: left bottom;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;

    @media screen and (min-width: $laptopMin) {
      height: 100%;
    }
  }

  &__empty-hotel-image {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #F3F3F4;

    img {
      width: 150px;
    }
  }

  &__status-container {
    position: absolute;
    left: 40px;
    bottom: 40px;
  }

  &__hotel-info-container {
    height: 100%;
    padding: 16px 24px;
    border-radius: 8px;
    border: 1px solid $greyColor5;
    background-color: $whiteColor;
  }

  &__section {
    margin-bottom: 16px;
  }

  &__hotel-name {
    &.ant-typography {
      margin-bottom: 0;
    }
  }

  &__hotel-info-title {
    font-weight: bold;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }

  &__price, &__status {
    font-weight: bold;
    color: $mainBlueColor;
  }

  &__price {
    font-size: 24px;
    line-height: 24px;
  }

  &__status {
    font-size: 18px;
  }

  &__free-cancellation {
    display: flex;
    align-items: center;
    font-weight: bold;
    color: $pinkColor;

    img {
      margin-right: 8px;
    }
  }
}

@media screen and (max-width: 992px) {
  .hotel-card {
    &__hotel-image {
      position: relative;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      border-bottom-left-radius: 0;
      width: 100%;
    }

    &__hotel-info-container {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}

@media screen and (max-width: 576px) {
  .hotel-card {
    &__footer {
      align-items: flex-start;
      flex-direction: column;
    }
  }
}
