.pagination {
  .ant-pagination-prev,
  .ant-pagination-next {
    text-transform: none;
  }

  .ant-pagination-item {
    border: none !important;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    line-height: 36px;

    &:hover {
      background-color: $mainBlueColor !important;
      color: $whiteColor !important;
    }

    @include mobile {
      width: 26px;
      height: 26px !important;
      line-height: 26px !important;
    }
  }

  .ant-pagination-item-active {
    background-color: $mainBlueColor !important;
    color: $whiteColor !important;
  }
}
