.cancellation-payment-message {
  color: $pinkColor;
  font-weight: bold;
  
  &__free-cancellation {
    &.ant-col {
      display: flex;
      align-items: center;

      img {
        margin-right: 8px;
      }
    }
  }

  &__deposit-required {
    text-transform: uppercase;
    font-weight: bold;
  }

  &__free-cancellation {
    font-size: 16px;
  }
}

  

