.crop-event-image-modal {
  &__title {
    font-weight: bold;
    margin-bottom: 16px;
  }

  &__cropper-container {
    &.croppie-container {
      width: 100%;
      height: 500px;
    }

    margin-bottom: 60px;
  }

  &__buttons {
    &.ant-col {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
