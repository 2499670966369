.categories {
  &__tabs.ant-tabs {
    overflow: visible;
    height: 427px;

    .wrapper {
      position: absolute;
      width: 100vw;
      overflow-x: scroll;
      left: -4.17vw;
      padding: 0 4.17vw;

      @media  screen and (min-width: $laptopMin) {
        left: -13.5vw;
        padding: 0 13.5vw;
      }

      /* Hide scrollbar for IE, Edge and Firefox */
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &__tab {
    height: 360px;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: visible;

    &::after {
      content: '';
      flex: 0 0 4.17vw;
      max-width: 4.17vw;

      @media  screen and (min-width: $laptopMin) {
        flex: 0 0 12.5vw;
        max-width: 12.5vw;
      }
    }
  }

  &__loader-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 50px;
    height: 167px;
  }
}
