.profile-header {
  background-color: #F7FAFF;
  font-weight: 600;
  margin-bottom: 16px;

  @include mobile {
    margin-bottom: 24px;
    padding: 0 16px;
  }

  .ant-row {
    @include mobile {
      justify-content: flex-start;
    }
  }

  .ant-menu-horizontal {
    background-color: #F7FAFF;
    border-color: #F7FAFF;

    .ant-menu-item {
      padding: 0;
      margin-right: 32px;

      @include mobile {
        font-size: 12px;
        margin-right: 13px;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
