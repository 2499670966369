.modal {
  &.ant-modal {
    .ant-modal-content {
      border-radius: 32px;
    }

    .ant-modal-body {
      padding: 24px 32px 32px;

      @include mobile {
        padding: 16px;
      }
    }

    .modal__body {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 40px;
      text-align: center;

      @include mobile {
        padding: 0;
      }
    }

    .modal__loader {
      margin-bottom: 40px;
    }

    .modal__icon {
      margin-bottom: 40px;
      height: 54px;
      width: 54px;
    }

    .modal__text {
      padding: 0;
      margin-bottom: 30px;
      font-size: 18px;

      @include mobile {
        padding: 0;
      }
    }

    .modal__buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .button {
        margin: 15px;
        padding: 7px 50px;

        @include mobile {
          padding: 7px 30px;
          margin: 0 8px;

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .modal__error {
      color: $errorColor;
      font-size: 12px;
    }
  }
}

.modal--success {
  &.ant-modal {
    .modal__body {
      padding: 20px;
    }

    .modal__buttons {
      .button {
        margin: 15px 0 0;
      }
    }
  }
}

.modal--error {
  &.ant-modal {
    .modal__body {
      padding: 20px;
    }

    .modal__buttons {
      .button {
        margin: 15px 0 0;
      }
    }
  }
}

.modal--close-account {
  &.ant-modal {
    .modal__body {
      padding: 0;
    }
  }
}

.modal--create-listing {
  &.modal.ant-modal {
    .modal__buttons {
      .button {
        padding: 7px 15px;

        @include mobile {
          width: 100%;
          margin: 8px 0;
        }
      }

      @include mobile {
        flex-direction: column;
        align-items: center;
      }
    }
  }
}

.modal--cancel-booking {
  &.ant-modal {
    .ant-modal-body {
      @include mobile {
        padding: 24px 8px;
      }
    }
  }
}

.modal--account-verification {
  &.ant-modal {
    .ant-modal-body {
      padding: 15px 20px;
    }
  }
}

.modal.modal--search-filters {
  .ant-modal-body {
    padding: 48px;
  }
}