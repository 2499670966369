.close-account {
  margin: 40px 0 0;

  &__link {
    color: $mainBlueColor;
    cursor: pointer;
    font-weight: 600;
    margin-right: 5px;
  }

  &__modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 40px;
    text-align: center;

    @include mobile {
      padding: 14px;
    }
  }

  &__modal-icon {
    margin-bottom: 20px;
  }

  &__modal-text {
    padding: 0 50px;
    margin-bottom: 30px;
    font-size: 18px;

    @include mobile {
      padding: 0;
    }
  }

  &__modal-buttons {
    .button {
      margin: 15px;
      padding: 7px 50px;

      @include mobile {
        padding: 7px 30px;
        margin: 0 8px;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &__modal-error {
    color: $errorColor;
    font-size: 12px;
  }
}
