.cover-link-container {
  position: relative;

  .cover-link {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
  }
}
