.textarea-with-label {
  &__field {
    position: relative;
    font-size: 14px;
  }

  &__label {
    font-size: 12px;
    color: $greyColor;
  }

  &__error-label {
    color: $errorColor;
  }

  &__input {
    &:focus.ant-input {
      border-color: $greyBorderColor;
      box-shadow: none;
    }

    &--error.ant-input {
      border-color: $errorBorderColor;

      &:focus,
      &:hover {
        border-color: $errorBorderColor;
      }
    }
  }

  &__error {
    position: absolute;
    display: block;
    color: $errorColor;
    font-size: 12px;
  }
}
