.resale-deals {
  .container-medium {
    padding: 0;
    width:100%;
  }

  &__section {
    @extend .container-large;

    margin: 48px auto;

    @include mobile {
      margin: 16px auto 32px;
    }
  }

  &__section-title {
    &.ant-typography {
      font-size: 42px;
      line-height: 42px;
      margin-bottom: 16px;

      @include mobile {
        font-size: 28px;
      }
    }
  }

  &__section-subtitle {
    font-size: 18px;

    @include mobile {
      font-size: 16px;
    }
  }

  &__search {
    > .ant-row {
      padding-left: 16px;
      padding-right: 16px;
    }
    .listing-search {
      @include mobile {
        padding: 16px 16px 0;
      }
    }
  }

  &__search-title {
    font-size: 42px;
    line-height: 42px;
    font-weight: 700;
    color: #000;
    margin-bottom: 16px;

    @include mobile {
      padding-top: 16px;
      font-size: 26px;
      line-height: 32px;
    }
  }

  &__search-subtitle {
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 16px;
  }

  &__listings {
    &-title-container {
      margin-bottom: 20px;
    }

    &-title {
      font-size: 20px;
      line-height: 20px;
      text-align: center;

      @include mobile {
        font-size: 18px;
      }

      img {
        width: 8px;
      }
    }
  }

  .resale-spacer {
    height: 30px;
  }

  .faq-section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__link {
      display: flex;
      align-items: center;
      margin-left: 16px;
      white-space: nowrap;

      &:hover,
      &:active,
      &:visited {
        color: inherit;
      }
    }

    &__arrow-icon {
      margin-left: 8px;
      width: 20px;
    }
  }
}
