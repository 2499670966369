.card-details-form {
  &__row {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__expiry-date {
    margin-top: 4px;
  }

  &__cvv {
    margin-top: 4px;

    @include mobile {
      padding-right: 8px;
    }
  }

  &__currency {
    @include mobile {
      padding-left: 8px;
    }
  }

  &__expiry-month {
    padding-right: 8px;
  }

  &__submit {
    @include mobile {
      padding: 0 16px;
    }

    .ant-btn {
      height: 40px;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;
      color: $blackColor;
      margin: 32px;
      border-radius: 4px;
      padding: 0 32px;

      &:hover,
      &:focus {
        color: $blackColor;
        border-color: $mainYellowColor;
      }

      @include mobile {
        width: 100%;
        margin: 32px 0;
      }
    }
  }

  &__error {
    display: block;
    color: $errorColor;
    font-size: 12px;
  }

  &__container {
    background-color: $greyColor3;
    padding: 24px;
    margin: 0 0 16px;
  }

  &__info {
    &.ant-col {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      background: rgba(33, 150, 243, 0.1);
      padding: 16px 32px;
      margin: 16px 0;

      &.profile-section {
        @include mobile {
          margin: 16px 16px 8px;
        }
      }
    }

    &--yellow {
      &.ant-col {
        margin-top: 0;
        background: rgba(240, 133, 5, 0.1);
      }

      .card-details__info-title {
        color: #F08505;
      }
    }

    &--red {
      &.ant-col {
        margin-top: 0;
        background: rgba(203, 62, 42, 0.1);

        .card-details__info-title {
          color: $dangerColor;
        }
      }
    }
  }

  &__info-icon {
    margin-right: 24px;
    max-height: 20px;
    max-width: 20px;
  }

  &__info-text {
    text-align: justify;
    font-size: 14px;
    color: $greyColor2;
  }

  &__info-title {
    font-weight: bold;
  }

  &__card-type {
    margin-top: 4px;
    padding-right: 30px;

    @include mobile {
      padding-right: 0;
      margin-bottom: 20px;
    }
  }



  &__section-title {
    text-transform: uppercase;
    margin-bottom: 15px;
    color: $greyColor;
    font-size: 12px;
  }

  &__message-block {
    &.profile-section {
      padding: 60px;
      color: #424242;
      width: 100%;
      font-size: 16px;
      text-align: center;
    }
  }

  &__message-icon {
    margin-bottom: 30px;
  }

  &__buttons-bottom .button {
    min-width: 180px;
    margin: 32px 8px 40px;
    line-height: 22px;
  }

  &__link {
    color: $blueCheckboxColor;

    &:hover,
    &:visited,
    &:active {
      color: $blueCheckboxColor;
    }
  }
}
