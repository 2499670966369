.user-management-list {
  .ant-table-content {
    overflow-y: auto;
  }

  &__filters {
    .ant-select {
      margin-bottom: 10px;
    }
  }

  &__item {
    &-status .ant-select {
      width: 110px;
    }
    &-fee .ant-select {
      width: 90px;
    }
  }
  &__filter-category {
    min-width: 80px;
  }

  &__download-csv {
    &.ant-col {
      padding-left: 8px;
      text-align: right;

      .ant-btn {
        padding: 8px 15px;
      }
    }
  }
}
