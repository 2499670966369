.google-sign-in {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mobile {
    box-shadow: none;
    padding: 24px 16px 12px;
  }

  &__title {
    font-size: 26px;
    font-weight: bold;
    color: $blackColor;
    margin-bottom: 8px;
    text-align: center;
  }

  &__subtitle {
    text-align: center;
    font-size: 14px;
    color: $blackColor;
  }

  &__icon {
    margin: 30px 0;
    height: 80px;
    width: 80px;
  }

  &__login-link {
    width: 100%;
    margin-top: 18px;
  }
}
