.prepayment-details {
  &__container {
    max-width: $tabletMin;
    margin: 0 auto;
    padding: 0 24px;

    @media screen and (min-width: $desktopMin) {
      max-width: $tabletMiddle;
    }

    @media screen and (min-width: $desktopMiddle) {
      max-width: $laptopMin;
    }

    .hidden {
      display: none;
    }
  }

  &__back {
    display: flex;
    align-items: center;
    font-weight: bold;

    @include mobile {
      padding: 0 16px;
      margin-bottom: 16px;
    }

    img {
      margin-right: 8px;
    }
  }

  &__title {
    &.ant-typography {
      font-size: 28px;
      margin: 24px 0;

      @include mobile {
        padding: 0 16px;
      }
    }
  }

  &__body {
    @include mobile {
      padding: 0 16px;
      margin: 16px 0;
    }

    > div {
      &:first-child {
        padding-right: 34px;

        @include mobile {
          padding-right: 0;
        }
      }

      &:last-child {
        padding-left: 34px;

        @include mobile {
          padding-left: 0;
        }
      }
    }
  }

  &__row-with-padding {
    &.ant-row {
      padding: 0 24px;
    }
  }

  &__submit {
    margin: 32px 0;
  }

  &__powered-by-mango-pay {
    max-width: 130px;
    height: auto;
  }

  &__checkbox--error.ant-checkbox-wrapper {
    .ant-checkbox {
      .ant-checkbox-inner {
        border-color: red;
      }
    }
  }

  &__listing {
    &-image {
      border-radius: 16px;
      overflow: hidden;
    }

    &-hotel-name {
      font-size: 20px;
      font-weight: bold;
      margin: 16px 0 8px;
    }

    &-rating {
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: 14px;
      margin-bottom: 32px;
    }

    &-rating-label {
      margin-right: 16px;
    }

    &-price {
      &.ant-col {
        display: flex;
        justify-content: space-between;
        font-size: 20px;
        font-weight: bold;
      }
    }

    &-price-user-native {
      display: flex;
      justify-content: flex-end;

      span {
        font-size: 18px;
      }
    }
  }

  &__timer-block {
    &.ant-row {
      padding: 24px;
      background-color: #F7E996;
      border-radius: 16px;
      margin-bottom: 24px;
      font-weight: 700;

      @include mobile {
        display: none;
      }
    }

    &--mobile {
      &.ant-row {
        display: none;

        @include mobile {
          display: flex;
          position: sticky;
          top: -1px;
          z-index: 2;
          box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);
          margin: 8px 16px;
        }
      }
    }
  }

  &__timer-container {
    &.ant-col {
      display: flex;
      align-items: center;
    }

    p {
      margin: 0;
    }
  }

  &__timer {
    margin-left: 16px;
    font-size: 28px;
    color: #000;
  }

  &__error {
    display: block;
    color: $errorColor;
    font-size: 12px;
    margin-top: -10px;
  }
}
