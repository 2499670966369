.browse-all {
  &__button.ant-btn {
    border-radius: 32px;
    background: $ghostWhite;
    border: none;
    font-weight: bold;
    color: $blackColor;
    padding: 6px 16px;
    height: 37px;
    margin-top: 20px;
  }
}