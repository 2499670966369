.crop-article-image-modal {
  &__cropper-column {
    &.ant-col {
      border-right: 1px solid $greyColor4;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  &__title {
    font-weight: bold;
    margin-bottom: 16px;
  }

  &__cropper-container {
    &.croppie-container {
      width: 100%;
      height: 500px;
    }

    margin-bottom: 60px;
  }

  &__buttons {
    &.ant-col {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__preview-row {
    &.ant-row {
      margin-bottom: 16px;
    }
  }

  &__preview {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 16px;
    max-width: 100%;

    &--extra-large {
      border-radius: 0;
      max-width: 453px;
      width: 100%;
      height: 333px;
    }

    &--large {
      width: 400px;
      height: 344px;
    }

    &--medium {
      width: 247px;
      height: 167px;
    }

    &--small {
      width: 120px;
      height: 114px;
    }
  }
}
