.address {
  margin-top: 8px;

  &__title {
    text-transform: uppercase;
    margin-bottom: 10px;
    color: $greyColor;
    font-size: 12px;
  }

  &__field {
    margin-bottom: 15px;
    font-size: 14px;

    &.hidden {
      display: none;
    }
  }

  &__view-text {
    color: #0F0F0F;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
    margin-bottom: 32px;
    > div {
      margin-bottom: 10px;
    }
  }

  button {
    &.ant-btn {
      width: 146px;
      height: 38px;
      margin-right: 24px;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0;
      color: #000000;
      background-color: rgba(255,195,0,0);
      text-transform: uppercase;
    }

    &:last-child {
      &.ant-btn {
        margin-right: 0;
      }
    }
  }
  button[type="submit"] {
    &.ant-btn {
      background-color: rgba(255,195,0,1);
    }
  }

  &__buttons {
    margin-top: 25px;

    &.ant-col {
      display: flex;
      justify-content: flex-start;
    }
  }
}
