.listings-search-modal {
  text-align: left !important;
  padding: 0 !important;

  &__container {
    width: 100%;
  }

  &__text {
    margin-bottom: 16px;
  }

  .listing-search__filters-mobile-label {
    display: none;
  }
}
