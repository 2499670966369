.listing-list {
  &__filters-container {
    &.ant-row {
      margin-bottom: 5px;
    }

    @include mobile {
      padding: 0 16px;
    }
  }

  &__date-empty {
    color: $pinkColor !important;
    margin-top:60px !important;
    margin-bottom:60px !important;
  }

  &__body {
    padding-bottom: 40px;
    overflow-y: hidden;

    > div {
      height: 587px;

      @include mobile {
        height: auto !important;
      }

      > div {
        height: 100%;

        > div {
          &:first-child {
            height: 100%;
          }
          &:last-child {
            padding-top: 4px;
          }
        }
      }
    }

    .ant-spin-nested-loading {
      height: 100%;
    }
  }

  &__pagination {
    margin-bottom: 60px;
    padding-right: 32px;

    .ant-pagination {
      &-item {
        @include mobile {
          margin-right: 4px !important;
        }

        @include tablet {
          min-width: 28px !important;
          margin-right: 4px !important;
        }

        @include laptop {
          min-width: 28px !important;
          margin-right: 4px !important;
        }
      }

      &-prev {
        @include tablet {
          margin-right: 4px;
        }

        @include laptop {
          margin-right: 4px;
        }

        span {
          @include mobile {
            margin: 0 4px 0 4px;
            font-size: 12px;
          }

          @include tablet {
            margin: 0 5px 0 5px;
          }

          @include laptop {
            margin: 0 5px 0 5px;
          }
        }
      }

      &-next span {
        @include mobile {
          margin: 0 5px 0 1px;
          font-size: 12px;
        }

        @include tablet {
          margin: 0 5px 0 5px;
        }

        @include laptop {
          margin: 0 5px 0 5px;
        }
      }

      &-jump-prev {
        @include tablet {
          margin-right: 0 !important;
        }

        @include laptop {
          margin-right: 0 !important;
        }
      }
      &-jump-next {
        @include tablet {
          margin-right: 4px !important;
        }

        @include laptop {
          margin-right: 4px !important;
        }
      }
    }
  }
  &__items {
    padding-right: 32px;

    &-scroll {
      overflow-y: scroll;

      @include mobile {
        overflow-y: visible;
      }
    }
  }
  &__item {
    position: relative;
    margin: 4px 0 12px;
    padding: 12px;
    width: 100%;
    background-color: $greyColor3;

    &-price-wrapper, &-image-wrapper {
      display: flex !important;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    &-image-wrapper {
      padding-left: 0 !important;
    }
    &-discount {
      position: absolute;
      top: 8px;
      left: 8px;
      min-width: 60px;
      padding: 4px;
      text-align: center;
      font-size: 12px;
      font-weight: 700;
      color: $mainYellowColor;
      background-color: $mainBlueColor;
    }
    &-hotel {
      padding: 0 24px;

      &-title {
        font-size: 18px;
        line-height: 23px;
        font-weight: 600;
      }
      &-text {
        font-size: 14px;
        color: $greyColor2;
      }
      &-looking {
        margin-top: 9px;
        font-size: 12px;
        color: $mainBlueColor;
      }

      &-rating {
        .ant-rate {
          font-size: 14px;

          .ant-rate-star {
            margin: 0 0 0 1px;

            div {
              &:hover {
                transform: none;
              }
            }
          }
        }
      }
    }
    &-price-wrapper {
      border-left: 1px solid #D8D8D8;
      padding-left: 12px;

      .listing-list__item-price {
        &-original {
          font-size: 24px;
          text-decoration: line-through;
          color: #9E9E9E;
        }
        &-selling {
          position: relative;
          margin: 25px 0 0;
          font-size: 16px;
          font-weight: 700;
          color: $mainBlueColor;

          @include mobile {
            width: 100%;
            text-align: center;
          }
        }
        &-discount {
          position: absolute;
          top: -20px;
          right: -35px;

          @include mobile {
            right: 5px;
          }

          span {
            padding: 2px 4px;
            border-radius: 4px;
            font-size: 13px;
            font-weight: 400;
            background-color: #009688;
            color: white;
          }
        }
      }
      .button {
        line-height: 11px;
        margin-top: 17px;

        @include tablet {
          padding: 7px;
        }
      }
    }
    &-label {
      position: absolute;
      right: -5px;
      top: -5px;
    }
    &-link-mobile {
      display: none;
    }
    &:hover {
      box-shadow: 0 2px 8px 0 rgba(47,47,47,0.2);
    }
  }

  &__item--loading {
    min-height: 250px;
    border-radius: 16px;
    padding: 0;
    margin-top:16px;

    &:hover {
      box-shadow: none;
    }

    .listing-list__item-image {
      background-color: #e5e5e5;
      opacity:0.8;
      border-radius: 16px;
      overflow: hidden;
      width: 100%;
      height: 100%;

      .animation-slide-box {
        border-radius: 4px;
      }

      .animation-slide-box__inner {
        opacity: 0.4;
        -webkit-box-shadow: 0 0 50px 50px $whiteColor;
        -moz-box-shadow: 0 0 50px 50px $whiteColor;
        box-shadow: 0 0 50px 50px $whiteColor;
      }

      @include mobile {
        display: none;
      }
    }

    .listing-list__item-hotel {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .listing-list__item-hotel-line {
      background-color: #e5e5e5;
      opacity:0.8;
      border-radius: 4px;
      height: 30px;
      width: 100%;
      margin: 10px 0;
      z-index: 2;

      .animation-slide-box {
        border-radius: 4px;
      }

      .animation-slide-box__inner {
        opacity: 0.4;
        -webkit-box-shadow: 0 0 50px 50px $whiteColor;
        -moz-box-shadow: 0 0 50px 50px $whiteColor;
        box-shadow: 0 0 50px 50px $whiteColor;
      }
    }

    .listing-list__item-price {
      background-color: #e5e5e5;
      opacity:0.8;
      border-radius: 4px;
      height: 20px;
      width: 30%;
      margin: 10px 0;

      .animation-slide-box {
        border-radius: 4px;
      }

      .animation-slide-box__inner {
        opacity: 0.4;
        -webkit-box-shadow: 0 0 10px 10px $whiteColor;
        -moz-box-shadow: 0 0 10px 10px $whiteColor;
        box-shadow: 0 0 10px 10px $whiteColor;

        @include mobile {
          -webkit-box-shadow: 0 0 20px 20px $whiteColor;
          -moz-box-shadow: 0 0 20px 20px $whiteColor;
          box-shadow: 0 0 20px 20px $whiteColor;
        }
      }

      @include mobile {
        width: 50%;
      }
    }

    .listing-list__item-buy {
      background-color: #e5e5e5;
      opacity:0.8;
      border-radius: 4px;
      height: 50px;
      width: 100%;
      margin: 0;
      margin-top: 16px;

      .animation-slide-box {
        border-radius: 4px;
      }

      .animation-slide-box__inner {
        opacity: 0.4;
        -webkit-box-shadow: 0 0 20px 20px $whiteColor;
        -moz-box-shadow: 0 0 20px 20px $whiteColor;
        box-shadow: 0 0 20px 20px $whiteColor;
      }

      @include mobile {
        display: none;
      }
    }
  }

  &__link {
    text-decoration: underline;
    cursor: pointer;
    color: $pinkColor;
  }

  .leaflet-container {
    height: 100%;
    width: 100%;
    position: absolute !important;
    top: -42px;
  }
  .leaflet-marker-icon.leaflet-div-icon {
    width: auto !important;
    height: auto !important;
    min-width: 50px;
    border-radius: 8px;
    border-color: $mainBlueColor;
    overflow: hidden;

    &.resale {
      border-color: $pinkColor;
    }
  }

  &__map-container {
    &.ant-col {
      position: sticky;
      height: calc(100vh - 100px);
      top: 142px;

      @include mobile {
        margin-bottom:-42px;
      }
    }
  }

  &__title {
    &.ant-typography {
      font-size: 28px;

      @include mobile {
        font-size: 20px;
      }
    }
  }

  &__subtitle {
    font-size: 16px;

    @include mobile {
      display: none;
    }
  }

  &__search {
    padding-right: 32px;
    margin-bottom: 24px;

    @include mobile {
      padding: 0 16px;
    }

    &.ant-row {
      @include desktop {
        display: none;
      }
    }

    .listing-search {
      padding: 12px 24px;
      font-size: 16px;

      @media screen and (max-width: $laptopMax) {
        padding: 16px;
        height: auto !important;
      }

      &__wrapper {
        @media screen and (max-width: $laptopMax) {
          height: auto;
        }
      }

      > .ant-row > .ant-col {
        @media screen and (max-width: $laptopMax) {
          margin-bottom: 16px;
          padding: 0 !important;
          border: none !important;
        }
      }

      .button {
        @media screen and (max-width: $laptopMax) {
          width: 100%;
        }
      }

      &__dropdown-location {
        @media screen and (max-width: $laptopMax) {
          min-width: 235px !important;
        }
      }

      &__field-container {
        padding: 0 12px;

        @media screen and (max-width: $laptopMax) {
          padding: 0;
        }
      }

      &__label {
        font-size: 12px;
        margin-bottom: 0;
      }

      &__label-wrapper {
        &--filters {
          &.ant-row {
            @media screen and (max-width: $laptopMax) {
              display: initial;
            }
          }
        }
      }

      &__input {
        .ant-select-selector {
          font-size: 16px;

          .ant-select-selection-placeholder {
            font-size: 16px;
          }
        }

        &.ant-picker {
          margin-top: 7px;

          .ant-picker-input {
            input {
              font-size: 16px;

              &::placeholder {
                font-size: 16px;
              }
            }
          }
        }

        &-wrapper {
          .rooms-guests__select {
            &-wrapper {
              font-size: 16px;
              margin-top: 1px;
            }
          }
        }
      }

      &__buttons.ant-col {
        @media screen and (max-width: $laptopMax) {
          align-items: flex-end;
        }

        .search-icon {
          height: 40px;
        }

        .button {
          @media screen and (max-width: $laptopMax) {
            width: 100%;
            border-radius: 8px;
            background: $mainBlueColor;
            color: $whiteColor;
            padding: 7px 16px;
            font-weight: bold;
          }

          &--margin-left {
            @media screen and (max-width: $laptopMax) {
              margin-left: 24px;
            }
          }

          .mobile-label {
            display: none;

            @media screen and (max-width: $laptopMax) {
              display: initial;
            }
          }
        }
      }

      &__filter-row {
        &.ant-row {
          @media screen and (max-width: $laptopMax) {
            height: initial;
          }
        }
      }

      &__filters {
        @media screen and (max-width: $laptopMax) {
          width: 100%;
          justify-content: space-between;
        }
      }

      &__filters-icon {
        @media screen and (max-width: $laptopMax) {
          margin-left: 0;
        }
      }

      &__filters-mobile-label {
        @media screen and (max-width: $laptopMax) {
          display: initial;
          font-size: 16px;
          font-weight: normal;
        }
      }
    }
  }

  &__sort {
    @include desktop {
      text-align: end;
      padding-right: 16px;
    }
  }

  &__empty-list-container {
    &.ant-col {
      margin-top: 48px;
    }
  }

  &__empty-list-text {
    margin-bottom: 16px;
  }

  &__empty-list-link {
    cursor: pointer;
    text-decoration: underline;
  }
}
.seller-listings {
  margin-top: 32px;

  &__create {
    text-align: right;
  }

  &__item {
    display: block !important;
    width: 100%;
    margin-top: 16px;
    padding: 16px 32px !important;
    background-color: $greyColor3;
    text-align: left;

    .listing-view__hotel-data {
      span {
        font-weight: 400;
      }
      .seller-listings__item-price {
        img {
          height: 14px;
        }
        span {
          font-size: 18px;
          font-weight: 600;
        }
      }

      .seller-listings__item-price--usd {
        img {
          height: 26px;
        }
      }
    }
    &-header {
      margin-bottom: 16px;
    }
    &-buttons {
      margin-bottom: 10px;

      .button {
        margin-left: 16px;
        cursor: pointer;

        &.disabled {
          cursor: not-allowed;
        }
      }
    }
    &-ref {
      color: $greyColor2;
    }
    &-hotel {
      &-rating {
        margin-bottom: 5px;

        .ant-rate {
          font-size: 14px;

          .ant-rate-star {
            margin: 0 0 0 1px;

            div {
              &:hover {
                transform: none;
              }
            }
          }
        }
      }
      &-name {
        font-size: 24px;
        line-height: 31px;
        font-weight: 600;
      }
    }
    &-room-type {
      margin-bottom: 5px;
      padding-left: 38px;
      color: $greyColor2;
    }
    &-facilities {
      padding-left: 38px;
      color: $greyColor2;
    }
    &-attachments {
      margin-top: 16px;
    }
    &-dispute {
      margin-top: 16px;
      padding: 16px 32px;
      background-color: #F5F5F5;

      span {
        margin-left: 24px;
        color: $greyColor2;
      }
    }
  }

  .listing-view__hotel-location-row {
    font-weight: normal;
  }
}

@include mobile {
  .mobile-hidden {
    display:none !important;
  }
  .listing-list {
    &__my-purchases {
      padding: 0 16px;
    }
    &__items {
      padding: 0 16px;
    }
    &__item {
      padding: 0 0 8px;

      &-image {
        padding-top: 43%;

        &-wrapper {
          padding: 0 0 8px !important;
        }
      }
      &-price-wrapper {
        padding: 0;
      }
      &-hotel {
        padding: 8px;

        &-title {
          font-size: 16px;
        }
        &-text {
          font-size: 12px;
        }
      }
      &-link-mobile {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
      }
    }
    .leaflet-container {
      // display: none;
    }
  }
  .seller-listings {
    margin: 0;

    &-content {
      padding: 32px 16px 0;
    }
  }
}
.listing-map {
  &__popup {
    display: flex;
    margin-bottom: -20px !important;
    margin-left: 20px;
    pointer-events: none;

    &-link {
      display: flex;
      flex-flow: column;
    }
    &-image {
      width: 200px;
      margin-right: 0px;

      .image-bg-cover {
        border-top-left-radius: 6px !important;
        border-top-right-radius: 6px !important;
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
      }
      
    }
    &-text {
      max-width: 200px;
      padding:5px;
    }
    &-title {
      color: #000000;
    }
    &-discount {
      color: $mainBlueColor;
      font-weight: 700;
    }
    .leaflet-popup {
      &-content-wrapper {
        border-radius: 0;
        padding:0;
        border-radius: 8px;
        overflow: hidden;
        transform: translateY(100%);
        pointer-events: all;
        transition: box-shadow 0.5s;

        &:hover {
          box-shadow: 0 0 15px 2px rgba(62, 120, 185, 0.8);
        }
      }
      &-tip-container, &-close-button {
        display: none;
      }
      &-content {
        margin: 0;
        font-size: 12px;
        width:200px !important;
        overflow: hidden;
      }
    }
  }
  &__marker{
    padding: 4px 6px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    background: #F3F3F4;

    &.active {
      color: $whiteColor;
      background: $mainBlueColor;
    }

    &.listing-map__marker_resale {
      background: #ffd5ed;

      &.active {
        color: $whiteColor;
        background: $pinkColor;
      }
    }

  }

  &__controls {
    position: absolute;
    right: 15px;
    top: 0px;
    z-index: 400;
    display: flex;
    align-items: center;
    border-radius: 10px;
    background: $whiteColor;
    padding: 8px 6px;
    border: 1px solid $greyColor4;
  }

  &__switch {
    cursor: pointer;
    display:none;
    position: absolute;
    left: 15px;
    top: 0px;
    z-index: 400;
    display: flex;
    align-items: center;

    @include mobile {
      display: block;
    }
  }

  &__zoom-control {
    width: 26px;
    padding: 0 6px;
    border-right: 1px solid $greyColor4;
    cursor: pointer;

    &:last-child {
      border-right: none;
    }
  }
}
.listing-sort {
  font-size: 14px;

  &__text {
    color: $greyColor;
    padding-right: 10px;
  }

  &__label {
    font-weight: 600;
    color: $blackColor;
    cursor: pointer;

    &:hover,
    &:active,
    &:visited {
      color: $blackColor;
    }

    &.ant-dropdown-link {
      .anticon-down {
        margin-left: 5px;
        font-size: 12px;
      }
    }

    .ant-rate {
      line-height: 18px;

      .ant-rate-star > div:hover,
      .ant-rate-star > div:focus {
        transform: none;
        cursor: pointer;
      }
    }
  }

  &__dropdown {
    &.ant-dropdown-menu {
      padding: 0;

      .ant-dropdown-menu-item {
        padding: 5px 25px;
      }

      .ant-dropdown-menu-item-selected {
        color: $blackColor;
        background-color: #EEEEEE;
      }
    }
  }

  &__total {
    text-align: left;
    font-size: 14px;

    &.ant-col {
      @media screen and (max-width: $laptopMax) {
        text-align: left;
      }

      @include laptop {
        margin-bottom: 10px;
      }

      @include tablet {
        margin-bottom: 10px;
      }

      @include mobile {
        margin-bottom: 10px;
      }
    }
  }

  &__total-number {
    color: $mainBlueColor;
  }
}

.flex-column {
  display:flex !important;
  flex-flow:row;
  justify-content: space-between;
  align-items: center;
}

.map-switch {
  white-space: nowrap;
  font-size: 16px;
  font-weight: bold;
  font-variant: tabular-nums;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.7) !important;
  height: 35px;
  border-radius: 8px;
  padding: 0 11px;
  border: 1px solid #d9d9d9;
  display:none;

  img {
    margin-top:-2px;
    height: 16px;
  }

  @include mobile {
    display: block;
  }
}
