.hotel-card-status {
  display: flex;
  padding: 8px 24px;
  border-radius: 32px;
  color: $whiteColor;

  &--active, &--sold {
    background-color: $successColor;
  }

  &--draft, &--pending {
    background-color: $mainYellowColor;
  }

  &--suspended, &--in-dispute, &--canceled {
    background-color: $errorColor;
  }

  &--expired {
    background-color: $dangerColor;
  }

  &__icon {
    width: 18px;
    margin-right: 8px;
  }

  &__status-text {
    text-transform: capitalize;
  }
}
