.select-with-label {
  &__field {
    position: relative;
    font-size: 14px;
    width: 100%;

    .ant-select-disabled {
      .ant-select-selection-item {
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }

  &__label {
    display: block;
    font-size: 12px;
    color: $greyColor;

    &--error {
      color: $errorColor;
    }
  }

  &__input {
    width: 100%;

    &:focus.ant-select {
      border-color: $greyBorderColor;
      box-shadow: none;
    }

    &--error.ant-select {
      &.ant-select-single {
        .ant-select-selector {
          border-color: $errorColor !important;
        }
      }

      &:focus {
        &.ant-select-single {
          .ant-select-selector {
            border-color: $errorColor !important;
          }
        }
      }
    }

    &.ant-select-focused {
      .ant-select-selector {
        box-shadow: none !important;
      }
    }

    &.ant-select-single {
      .ant-select-selector {
        border: 1px solid $greyBorderColor !important;
      }
    }
  }

  &__error {
    display: block;
    color: $errorColor;
    font-size: 12px;
  }
}
