.listing-amenities-modal {
  text-align: left !important;
  padding: 0 5px !important;

  &__container {
    width: 100%;
  }

  &__content {
    max-height: 500px;
    overflow-y: auto;
  }

  &__amenity {
    margin: 12px 0;
  }
}
