.sign-up {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 0 32px;

  @include mobile {
    box-shadow: none;
    padding: 0 0 12px;
  }

  &__form {
    width: 100%;
  }

  &__title {
    font-size: 48px;
    font-weight: bold;
    color: $mainBlueColor;
    margin-bottom: 16px;
    text-align: center;

    @include tablet {
      font-size: 32px;
    }

    @include mobile {
      font-size: 32px;
    }
  }

  &__subtitle-container {
    text-align: center;
  }

  &__bold-text {
    font-weight: bold;
  }

  &__field {
    margin-bottom: 8px;
    font-size: 14px;

    &:nth-child(3) {
      margin-bottom: 10px;
    }

    &--first-name {
      margin-right: 8px;

      @include mobile {
        margin-right: 0;
      }
    }

    &--last-name {
      margin-left: 8px;

      @include mobile {
        margin-left: 0;
      }
    }

    &--promocode {
      margin-bottom: 24px;
    }
  }

  &__last-field {
    padding-bottom: 16px;
  }

  &__label {
    font-size: 12px;
    color: $greyColor;
  }

  &__input {
    &:focus.ant-input,
    &:focus.ant-input-password {
      border-color: $greyBorderColor;
      box-shadow: none;
    }

    &--error.ant-input,
    &--error.ant-input-password {
      border-color: $errorBorderColor;

      &:focus {
        border-color: $errorBorderColor;
      }
    }
  }

  &__error {
    display: block;
    color: $errorColor;
    font-size: 12px;
  }

  &__hint {
    display: block;
    font-size: 12px;
    color: $greyColor;
    margin-top: 8px;
    margin-bottom: 16px;

    &--error {
      color: $errorColor;
    }
  }

  &__submit.ant-btn {
    height: 40px;
    font-size: 18px;
    margin-top: 18px;
    padding: 0 32px;
  }

  &__checkbox-container {
    margin-bottom: 12px;
  }

  &__checkbox.ant-checkbox-wrapper {
    display: flex;
    font-size: 12px;

    &.sign-up__checkbox--center-label {
      align-items: center;

      .ant-checkbox {
        top: 0;
      }
    }

    &:hover {
      .ant-checkbox-inner {
        border-color: $greyBorderColor;
      }
    }

    .ant-checkbox {
      top: 2px;
      margin-right: 9px;
      width: 18px;
      height: 18px;

      .ant-checkbox-inner {
        border-color: $greyBorderColor;
        border-radius: 3px;
      }
    }

    .ant-checkbox-checked {
      .ant-checkbox-inner {
        border-color: $blueCheckboxColor;

        &:after {
          top: 46%;
          left: 16%;
          width: 7px;
          height: 12px;
        }
      }
    }
  }

  &__checkbox--error.ant-checkbox-wrapper {
    .ant-checkbox {
      .ant-checkbox-inner {
        border-color: red;
      }
    }
  }

  &__login-text {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 22px;
  }

  &__login-link {
    color: $mainBlueColor;
    text-decoration: underline;

    &:hover,
    &:active,
    &:visited {
      color: $mainBlueColor;
    }
  }

  &__or {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 18px 0;
    font-size: 12px;

    &:before {
      margin-right: 16px;
    }

    &:after {
      margin-left: 16px;
    }
  }

  &__with-google {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px;
    color: $blackColor;
    font-size: 18px;
    text-align: center;
    border: 1px solid $greyBorderColor;
    border-radius: 4px;
    cursor: pointer;
    height: 40px;

    &:hover,
    &:active,
    &:visited {
      color: $blackColor;
    }
  }

  &__google-logo {
    margin: 0 8px;
  }

  &__listing {
    margin-bottom: 32px;

    @include tablet {
      margin-bottom: 16px;
    }

    @include mobile {
      margin-bottom: 16px;
    }
  }
}

.sign-up-row {
  @extend .container-xl;

  min-height: calc(100vh - 135px);
  padding: 10px 32px;

  @include mobile {
    padding: 10px 16px;
  }

  .listing-card__container {
    max-height: 250px;

    @include tablet {
      max-height: 200px;
    }

    @include mobile {
      max-height: 200px;
    }
  }

  .auth-footer {
    position: relative;
    margin-top: 16px;
  }
}
