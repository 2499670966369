.sell-booking {
  display: flex;
  align-items: center;
  border-radius: 16px;
  margin: 0 -16px;

  .plainHtml {
    h2 {
      font-size: 34px;
      margin-bottom: 0;
      color: rgba(0,0,0,.85);
      font-weight: 600;
      line-height: 1.35;
    }
    h3 {
      font-size: 24px;
      margin-bottom: 16px;
      font-weight: 400;
    }
  }

  &__item {
    padding: 0 16px;
  }

  &__image {
    max-width: 400px;
    margin-bottom: 24px;
  }

  &__subtitle {
    font-size: 24px;
    margin-bottom: 16px;

    @include mobile {
      font-size: 18px;
    }
  }

  &__step-list {
    display: flex;
    margin: 16px -16px;

    @include tablet {
      margin: 16px 0;
    }

    @include mobile {
      margin: 0;
    }
  }

  &__step-item {
    margin: 0 16px;
  }

  &__actions {
    margin: 0 -16px;

    @include laptop {
      display: flex;
      justify-content: center;
    }

    @include tablet {
      display: flex;
      justify-content: center;
    }

    @include mobile {
      display: flex;
      justify-content: space-between;
      margin: 24px 0;
    }
  }

  &__sell-container {
    @include mobile {
      width: 70%;
    }
  }

  &__buy-container {
    @include mobile {
      width: 25%;
    }
  }

  &__button {
    &.ant-btn {
      height: 48px;
      margin: 0 16px;
      border-radius: 8px;
      padding: 5px 30px;

      @include mobile {
        width: 100%;
        margin: 0 8px;
      }

      &:first-child {
        @include mobile {
          margin-left: 0;
        }
      }

      &:last-child {
        @include mobile {
          margin-right: 0;
        }
      }
    }

    &--sell {
      &.ant-btn {
        color: $whiteColor;
        background-color: $pinkColor;
        border-color: $pinkColor;
        min-width: 185px;

        &:hover {
          color: $whiteColor;
          background-color: $pinkColor;
          border-color: $pinkColor;
          min-width: 185px;
        }
      }
    }
  }

  &__seller-info {
    display: inline-block;
    margin: 16px 0 0;
    font-size: 23px;
    color: #A2ACFF;

    &:hover,
    &:active,
    &:visited {
      color: #A2ACFF;
    }

    @include laptop {
      text-align: center;
    }

    @include tablet {
      text-align: center;
    }

    @include mobile {
      text-align: center;
      margin: 0;
    }

    &-image {
      margin-right: 8px;
    }
  }

  &__title.ant-typography {
    font-size: 34px;
    margin-bottom: 0;

    @include mobile {
      font-size: 24px;
      margin-bottom: 8px;
    }
  }
}

@media screen and (max-width: $laptopMax) {
  .sell-booking {
    flex-direction: column-reverse;

    &__step-item {
      padding: 8px 0;
    }

    &__item {
      display: flex;
      flex-direction: column;
    }
  }
}

@media screen and (max-width: $tabletMax) {
  .sell-booking {

    &__image {
      width: 380px;
    }

    &__step-list {
      display: flex;
      flex-direction: column;
    }
  }
}

@media screen and (max-width: 576px) {
  .sell-booking {
    &__step-list-wrapper {
      display: flex;
    }

    &__image {
      width: 100%;
    }
  }
}

.sell-booking-step {
  display: flex;
  align-items: center;

  &__number {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background-color: $pinkColor;
    color: $whiteColor;
    border-radius: 100%;
    margin-right: 16px;
  }

  &__name {
    color: $pinkColor;
  }
}
