.input-with-label {
  &__field {
    position: relative;
    font-size: 14px;

    .ant-input-number {
      width: 100%;
    }
  }

  &__label {
    font-size: 12px;
    color: $greyColor;
  }

  &__label-error {
    color: $errorColor;
  }

  &__input {
    &:focus.ant-input,
    &:focus.ant-input-number {
      border-color: $greyBorderColor;
      box-shadow: none;
    }

    &--error.ant-input,
    &--error.ant-input-number,
    &--error.ant-input-password {
      border-color: $errorBorderColor;

      &:focus,
      &:hover {
        border-color: $errorBorderColor;
      }
    }
  }

  &__error {
    display: block;
    color: $errorColor;
    font-size: 12px;
  }
}
