.update-card {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 16px;
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 26px;
  min-height: 250px;


  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: transparent linear-gradient(0deg, #20223A 0%, #00000000 50%) 0% 0% no-repeat padding-box;
    z-index: -1;

    @include mobile {
      background: transparent linear-gradient(0deg, #20223A 0%, #00000000 100%) 0% 0% no-repeat padding-box;
    }
  }

  &-main {
    .update-card__text {
      &.ant-typography {
        @media screen and (min-width: $laptopMin) {
          width: 55%;
        }
      }
    }
  }

  &-secondary {
    .blog__section-title {
      &.ant-typography {
        @media screen and (min-width: $laptopMin) {
          font-size: 25px;
          line-height: 1.3;
          margin-bottom: 15px;
        }
      }
    }
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
  }

  &__title {
    &.ant-typography {
      color: $whiteColor;
    }
  }

  &__text {
    &.ant-typography {
      color: $whiteColor;
      font-size: 12px;
      margin-bottom: 0;
    }
  }
}
