.finance-settings {
  &__comment {
    margin: 10px 0 16px;
    font-size: 12px;
    color: rgba(0,0,0,0.5);
  }
  &__submit .button {
    margin-right: 16px;
    min-width: 112px;
  }
  &__table {
    margin: 56px 0;

    .ant-table tbody > tr > td, .ant-table thead > tr > th {
      border: 1px solid #f0f0f0;
      font-size: 16px;
    }
    .ant-table tbody > tr.ant-table-row:hover > td {
      background: white;
    }
  }
}

.listing-fees-configuration {
  &__container {
    margin-bottom: 32px;
  }

  &__submit-container {
    text-align: right;
  }

  &__payment-submit-container {
    margin-top: 30px;
  }
}
