.admin-category {
  &__title {
    font-size: 20px;
  }

  &__row {
    margin-top: 24px;
  }

  &__reset {
    text-align: right;
  }

  &__heading {
    margin-bottom: 15px;
  }
}
