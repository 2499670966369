.google-sign-up {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mobile {
    box-shadow: none;
    padding: 24px 16px 12px;
  }

  &__form {
    width: 100%;
  }

  &__title {
    font-size: 26px;
    font-weight: bold;
    color: $blackColor;
    margin-bottom: 16px;
  }

  &__checkbox-container {
    margin-bottom: 12px;
  }

  &__checkbox.ant-checkbox-wrapper {
    display: flex;
    font-size: 12px;

    &.google-sign-up__checkbox--center-label {
      align-items: center;

      .ant-checkbox {
        top: 0;
      }
    }

    &:hover {
      .ant-checkbox-inner {
        border-color: $greyBorderColor;
      }
    }

    .ant-checkbox {
      top: 2px;
      margin-right: 9px;
      width: 18px;
      height: 18px;

      .ant-checkbox-inner {
        border-color: $greyBorderColor;
        border-radius: 3px;
      }
    }

    .ant-checkbox-checked {
      .ant-checkbox-inner {
        border-color: $blueCheckboxColor;

        &:after {
          top: 46%;
          left: 16%;
          width: 7px;
          height: 12px;
        }
      }
    }
  }

  &__checkbox--error.ant-checkbox-wrapper {
    .ant-checkbox {
      .ant-checkbox-inner {
        border-color: red;
      }
    }
  }

  &__submit.ant-btn {
    width: 100%;
    height: 45px;
    font-size: 18px;
    font-weight: 600;
    color: $blackColor;
    margin: 55px 0 20px;
    border-radius: 8px;
    padding: 0 32px;

    &:hover,
    &:focus {
      color: $blackColor;
      border: $mainYellowColor;
    }
  }

  &__field {
    margin-bottom: 24px;
    font-size: 14px;

    &:nth-child(3) {
      margin-bottom: 10px;
    }

    &--first-name {
      margin-right: 8px;

      @include mobile {
        margin-right: 0;
      }
    }

    &--last-name {
      margin-left: 8px;

      @include mobile {
        margin-left: 0;
      }
    }
  }

  &__label {
    font-size: 12px;
    color: $greyColor;
  }

  &__error {
    display: block;
    color: $errorColor;
    font-size: 12px;
  }
}
