.admin-cookie-policy {
  .ql-editor {
    height: 600px;
  }

  &__submit {
    margin: 32px;

    .button {
      padding: 7px 40px;
    }
  }

  &__error {
    display: block;
    color: $errorColor;
    font-size: 12px;
  }
}
