.best-deal {
  &__listing {
    margin-bottom: 16px;
  }

  &__listing-second-row {
    .listing-card__container {
      max-height: 200px;
    }
  }
}
