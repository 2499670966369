.home-page {
  h2 {
    font-size: 32px !important;
    margin-bottom: 0px !important;
  }

  &__resale-deals-swiper {
    height: 550px;

    @include mobile {
      height: auto;
    }

    @include tablet {
      height: auto;
    }

    @include laptop {
      height: auto;
    }
  }

  &__resale-deals-small-swiper {
    height: 250px;
    padding-left:28px !important;
    padding-right:28px !important;

    &.no-swiping {
      padding-left:0px !important;
      padding-right:0px !important;
      display:flex;
      flex-direction: row;
    }

    &.show-4 {
      .swiper-slide {
        width:25%;
        max-height: 12em;
        padding:5px;
      }
    }
    &.show-3 {
      .swiper-slide {
        width:33%;
        max-height: 12em;
        padding:5px;
      }
    }
    &.show-2 {
      .swiper-slide {
        width:50%;
        max-height: 12em;
        padding:5px;
        .listing-card__container {
          max-height: 30em;
        }
      }
    }
    &.show-1 {
      .swiper-slide {
        width:100%;
        max-height: 12em;
        padding:5px;
        .listing-card__container {
          max-height: 30em;
        }
      }
    }


    .swiper-button-prev {
      left: 1px;
      margin-top: -55px;
      &::after {
        color: #3D4CC1;
        font-size: 35px;
      }
    }
    .swiper-button-next {
      right: 1px;
      margin-top: -55px;
      &::after {
        color: #3D4CC1;
        font-size: 35px;
      }
    }

    .swiper-wrapper {
      height: 170px;
    }

    .swiper-pagination {
      @include mobile {
        bottom: unset !important;
      }
    }
  }

  .plainHtml {
    p {
      color: $blackColor;
      font-size: 18px;
      margin-bottom: 0px;
      line-height: 26px;

      strong {
        em {
          display: block;
          margin-top: 1em;
          margin-bottom: 0.5em;
          font-size: 20px;
        }
      }
    }
  }

  .container-medium {
    padding: 0;
  }

  &__section {
    @extend .container-xl;

    margin: 36px auto;

    @include mobile {
      margin: 36px auto;
    }
  }

  &__section-title {
    &.ant-typography {
      font-size: 32px;
      font-weight: bold;
      line-height: 32px;
      margin-bottom: 0px;

      @include mobile {
        text-align: center;
      }
    }
  }

  &__responsive-hide {
    @include tablet {
      display: none !important;
    }

    @include laptop {
      display: none !important;
    }
  }

  &__search-container {
    @extend .container-xl;

    // background-image: url("/images/home-header.png");
    background-position: center;
    background-size: cover;
    width: 100%;

    @include mobile {
      // background-image: url("/images/home-header-mobile.png");
      // height: 575px;
    }

    .listing-search {
      width: 850px;

      @include mobile {
        width: 100%;
      }
    }
  }

  &__mini-resale-deals {
    margin: 20px auto 0;

    width: 100%;

    .swiper-pagination {
      margin-top: 20px;
    }
  }

  &__search {
    &.ant-col {
      margin: 16px auto 26px;

      @include mobile {
        margin: 24px auto;
      }
    }

    .listing-search {
      @include mobile {
        padding: 16px 16px 0;
      }
    }
  }

  &__search-title {
    font-size: 36px;
    line-height: 36px;
    font-weight: bold;
    color: $blackColor;
    margin-bottom: 6px;
    min-height: 36px;

    @include mobile {
      padding-top: 0;
      font-size: 22px;
      line-height: 32px;
      margin-bottom: 6px;
      min-height: 32px;
    }
  }

  &__search-subtitle {
    color: $blackColor;
    font-size: 20px;
    min-height: 20px;
    @include mobile {
      font-size: 18px;
      min-height: 18px;
    }
  }

  &__fixed-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 100vh;

    @include laptop {
      min-height: auto;
    }

    @include tablet {
      min-height: auto;
    }

    @include mobile {
      min-height: auto;
    }
  }

  &__action-buttons {
    &.ant-col {
      display: flex;
      align-items: stretch;
      justify-content: center;
      gap: 16px;

      a {
        text-align: center;
      }

      @include mobile {
        flex-direction: column;
        margin-top: 0;
        gap: 16px;
      }
    }
  }

  &__sell-container {
    max-width: 400px;
    min-width: 400px;
    background-color: $pinkColor;
    border-radius: 8px;
    padding: 12px;
    color: $whiteColor;
    text-align: center;
    height: 100%;
    width:100%;
    display: flex;
    flex-flow: column;
    margin-left:auto;
    margin-right:auto;

    .sell-main {
      flex:1;
      margin-top: 16px;

      @include mobile {
        margin-bottom: 8px;
      }
    }

    @include tablet {
      max-width: 350px;
      min-width: 350px;
    }

    @include mobile {
      padding: 8px;
      max-width: 300px;
      min-width: 300px;
    }
  }

  &__sell-button {
    display: block;
    width: 100%;
    padding: 8px;
    height: auto;
    border-radius: 8px;
    color: $pinkColor;
    background-color: $whiteColor;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    font-size: 20px;

    &:hover,
    &:active,
    &:focus {
      color: $pinkColor;
      background-color: $whiteColor;
    }

    @include mobile {
      font-size: 16px;
    }
  }

  &__buy-container {
    max-width: 400px;
    min-width: 400px;
    background-color: $mainBlueColor;
    border-radius: 8px;
    padding: 12px;
    color: $whiteColor;
    text-align: center;
    height: 100%;
    display: flex;
    flex-flow: column;
    margin-left:auto;
    margin-right:auto;

    .buy-main {
      flex:1;
      margin-top: 16px;

      @include mobile {
        margin-bottom: 8px;
      }
    }

    @include tablet {
      max-width: 350px;
      min-width: 350px;
    }

    @include mobile {
      padding: 8px;
      max-width: 300px;
      min-width: 300px;
    }
  }

  &__buy-button {
    display: block;
    width: 100%;
    padding: 8px;
    height: auto;
    border-radius: 8px;
    color: $mainBlueColor;
    background-color: $whiteColor;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    font-size: 20px;

    &:hover,
    &:active,
    &:focus {
      color: $mainBlueColor;
      background-color: $whiteColor;
    }

    @include mobile {
      font-size: 16px;
    }
  }

  &__hotel-highlights {
    width:100%;

    .hotel-highlight-placeholder {
      .placeholder {
        display:block;
        width:100%;
        background-color:#F8F8F8;
        color: #808080;
        height: 150px;
        line-height: 150px;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
        text-align: center;
      }
    }
  }

  &__subscribe {
    background: #467CCF;
    padding: 8px;
    font-size: 22px;
    color: $whiteColor;

    .subscribe-form {
      max-width: 100%;
      padding-right: 0;

      &__input-container {
        width: 100%;
      }

      &__input {
        &.ant-input {
          padding: 8px 16px;
        }
      }

      &__button {
        &.ant-btn,
        &.ant-btn:disabled {
          padding: 8px 16px;
          background: $pinkColor;
          border-color: $pinkColor;

          &:hover,
          &:focus {
            background: $pinkColor;
            border-color: $pinkColor;
          }
        }
      }
    }
  }

  &__listings {
    &-title-container {
      margin-bottom: 20px;
    }

    &-title {
      &.ant-col {
        display: flex;
        align-items: center;

        @include mobile {
          flex-direction: column;
          justify-content: center;
        }
      }
    }

    .home-page__section-title {
      &.ant-typography {
        margin-bottom: 0;
      }
    }

    &-link {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background: $pinkColor;
      border-radius: 6px;
      font-size: 16px;
      padding: 8px 12px;
      color: $whiteColor;
      margin-left: 48px;

      &:hover,
      &:active,
      &:visited {
        color: $whiteColor;
      }

      img {
        width: 24px;
        margin-right: 8px;
      }

      @include mobile {
        margin: 12px 0 0 0;
      }
    }

    &__arrow-icon {
      margin-left: 8px;
      width: 20px;
    }

    .ant-carousel {
      width: 100%;

      .slick-slide {
        padding: 0 8px;
      }

      .slick-arrow, .slick-arrow:hover, .slick-arrow:focus {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin: 0;
        transform: translateY(-50%);
        z-index: 10;
        color: $mainBlueColor;

        @include tablet {
          width: 25px;
          height: 25px;
        }
      }

      .slick-prev {
        left: -60px;

        @include tablet {
          left: -25px;
        }

        @include mobile {
          left: -25px;
        }
      }

      .slick-next {
        right: -60px;

        @include tablet {
          right: -25px;
        }

        @include mobile {
          right: -25px;
        }
      }
    }
  }

  &__reviews-section {

    @include mobile {
      margin-top:0;
    }

    .swiper-with-navigation {
      .swiper-initialized {
        padding-left:20px;
        padding-right:20px;

        .swiper-button-prev {
          left: 1px;
          &::after {
            color: #3D4CC1;
            font-size: 35px;
          }
        }
        .swiper-button-next {
          right: 1px;
          &::after {
            color: #3D4CC1;
            font-size: 35px;
          }
        }
      }
    }
  }

  &__partners-section {
    margin-top: 72px;
  }

  &__partners-subtitle {
    margin-top: 8px;
    color: $blackColor;
    font-size: 20px;
  }

  &__listing {
    margin-bottom: 16px;
  }

  &__listing-second-row {
    .listing-card__container {
      max-height: 200px;
    }
  }

  &__listings-title-container {
    h3 {
      font-size: 20px;
      line-height: 20px;
      text-align: center;
      margin: 0;
    }
  }

  &__who-we-are {
    &__subtitle {
      margin-top: 8px;
      color: $blackColor;
      font-size: 20px;

      &--italic {
        font-style: italic;
        font-weight: bold;
        font-size: 22px;
      }

      @include mobile {
        font-size: 16px;
      }
    }

    &__items {
      &.ant-row {
        margin-top: 16px;
        margin-bottom: 32px;
      }
    }

    &__item {
      &.ant-row {
        height: 100%;
        background: #FBEBF2;
        border-radius: 16px;
        padding: 30px;
        text-align: center;
        color: $blackColor;
        cursor: pointer;

        &:hover,
        &:active,
        &:visited {
          color: $blackColor;
        }
      }
    }

    &__item-icon {
      margin-bottom: 32px;
    }

    &__item-label {
      font-size: 16px;
      font-weight: 600;
    }

    &__more {
      &.ant-btn {
        min-width: 215px;
        padding: 12px;
        height: auto;
      }
    }
  }

  &__blog-title {
    &.ant-typography {
      margin-bottom: 40px;
    }
  }

  &__faq {
    background: #E9EAF6;
    border-radius: 16px;
    padding: 70px;

    @include mobile {
      padding: 32px 16px;
      border-radius: 0;
    }

    &-subtitle {
      font-size: 20px;
      font-weight: 600;
      color: $blackColor;

      @include mobile {
        display: inline-block;
        text-align: center;
      }
    }

    .faq-section-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 40px;

      &__link {
        display: flex;
        align-items: center;
        margin-left: 16px;
        white-space: nowrap;
        color: $blackColor;
        font-size: 16px;
        font-weight: 600;

        &:hover,
        &:active,
        &:visited {
          color: inherit;
        }

        @include mobile {
          display: none;
        }
      }

      &__arrow-icon {
        margin-left: 8px;
        width: 20px;
      }
    }
  }
}
