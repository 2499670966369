.blog-article-big-card {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  overflow-wrap: break-word;
  color: inherit;


  &:hover {
    color: inherit;
  }

  &__image {
    border-radius: 16px;
    object-fit: cover;
    min-height: 167px;
  }

  &__title {
    font-size: 16px;
    line-height: 16px;
    font-weight: bold;
    margin: 8px 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 32px;
  }

  &__item {
    margin: 6px 0;
  }

  &__description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-size: 14px;
  }

  &__read-more {
    color: $pinkColor;
    font-size: 14px;
    text-decoration: underline;
  }
}
