.kyc-failure-reasons-modal {
  padding: 20px 20px 0 !important;

  &__content {
    text-align: left;
    margin-bottom: 0 !important;

    .ant-checkbox-group .ant-checkbox-group-item {
      width: 100%;
      margin-bottom: 5px;
    }
  }

  &__label {
    display: inline-block;
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 16px;
  }

  &__error {
    display: block;
    color: $errorColor;
    font-size: 12px;
  }
}
