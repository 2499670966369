.seller-guarantees-card {
  text-align: center;

  &__img-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    background-color: #F3F3F4;
    border-radius: 16px;
  }

  &__item {
    margin: 16px 0;
  }

  &__title {
    font-size: 20px;
  }
}
