.menu {
  &.ant-menu {
    font-weight: 600;

    .ant-menu-item {
      padding: 0;
    }

    &.ant-menu-vertical-left {
      border-right: none;
    }

    &.ant-menu-horizontal {
      .ant-menu-item {
        margin-right: 32px;

        @include mobile {
          font-size: 12px;
          margin-right: 16px;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
