.partners-list {
  display: flex;
  flex-flow: row wrap;
  margin: -16px -16px;

  &__col {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
    padding: 16px 8px;

    @media screen and (max-width: $laptopMax) {
      width: 50%;
    }
  }

  &__image {
    max-width: 100%;
  }

  .lazyload-wrapper {
    height: auto;

    width: 80%;

    @media screen and (max-width: $laptopMax) {
      width: 40%;
    }

    @media screen and (max-width: $mobileMax) {
      width: 60%;
    }
  }
}
