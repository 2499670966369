.sort-hotel-list.ant-select-single:not(.ant-select-customize-input) {
  margin-bottom: 14px;
  font-weight: bold;

  @include desktop {
    margin-bottom: 0;
  }

  @include laptop {
    margin-bottom: 0;
  }

  @include tablet {
    margin-bottom: 0;
  }

  @include mobile {
    margin-bottom: 0;
  }

  &.ant-select-show-arrow {
    
    .ant-select-selector {
      .ant-select-selection-item {
        &::before {
          content: "Sorting by ";
        }
      }
    }

    &:not(.ant-select-disabled) {
      .ant-select-selector {
        background: #3D4CC1;
        color: #ffffff !important;
        .ant-select-selection-item {
          background: #3D4CC1;
          color: #ffffff !important;
          &::before {
            content: "Sorted by ";
          }
        }
      }
      .ant-select-arrow {
        color: #ffffff  !important;
      } 
    }
  }

  .ant-select-selector {
      height: 35px;
      border-radius: 8px;
      background: none;

      .ant-select-selection-search-input {
        height: 33px;
      }

      .ant-select-selection-placeholder, .ant-select-selection-item {
        line-height: 33px;
        color: rgba(0, 0, 0, 0.7) !important;
      }
  }
}


