.back-link {
  display: block;
  align-items: center;
  color: $mainBlueColor;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 14px;

  &__container {
    width: 100%;
    height: 48px;
    padding: 0 20px;
    background-color: #F7FAFF;

    .button.ant-btn {
      padding: 5px 15px;
    }
  }
  &__align-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &:hover, &:active, &:visited {
    color: $mainBlueColor;
  }
  &__label {
    margin-left: 10px;
  }
  &__title {
    text-transform: capitalize;
    font-size: 18px;
    font-weight: 600;

    &--responsive {
      @media screen and (max-width: 543px) { display: none }
    }
  }
  &__buttons {
    display: flex;

    .button {
      margin-left: 16px;
    }
  }
}
