.my-listings {
  &__listing-item {
    position: relative;
    margin-bottom: 40px;
  }

  &__listing-item-link {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
  }

  &__pagination {
    display: flex;
    justify-content: center;
  }

  @media screen and (min-width: $laptopMin) {
    max-width: $mobileMax;
    margin: 0 auto;
  }
}
