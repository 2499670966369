.listing-card {
  padding: 0 15px;
  margin-bottom: 24px;
  z-index: 0;

  font-size: 10px;

  @include mobile {
    padding: 0;
  }

  &--small {
    .listing-card__selling-info-container {
      height: 4em;
      position: absolute;
      top: 2.9em;
      right: 1.6em;
    }
  }

  &__container {
    position: relative;
    border-radius: 16px;
    overflow: hidden;
    min-height: 20em;
    max-height: 45.2em;
    height: 100%;
    width: 100%;
    color: $whiteColor;

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: transparent linear-gradient(0deg, rgba(38, 38, 38, 0.5) 0%, #00000000 30%) 0% 0% no-repeat padding-box;
      z-index: 0;
    }

    @include laptop {
      max-height: 70.4em;
    }

    @include tablet {
      max-height: 70.4em;
    }

    @include mobile {
      max-height: 20em;
    }
  }

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }

  &__details-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.8em;
  }

  &__selling-info-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 4.5em;
  }

  &__price-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.9);
    font-size: 10px;
    border-radius: 0.8em;
    text-align: right;
    padding: 0 1.2em;
    font-weight: 700;
    backdrop-filter: blur(5em);
    height: 100%;
  }

  &__original-price {
    color: #F61212;
    text-decoration: line-through;
    font-size: 1em;
  }

  &__price {
    color: #05061A;
    font-size: 12px;
  }

  &__time-left-container {
    border-radius: 0 0.8em 0.8em 0;
    background-color: $pinkColor;
    color: $whiteColor;
    font-size: 1.2em;
    padding: 0 1em;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  &__time-left {
    display: flex;
    align-items: center;
    font-size: 10px;
    line-height: 10px;

    img {
      width: 10px;
      margin-right: 5px;
    }
  }

  &__info {
    position: absolute;
    bottom: 1.6em;
    left: 0;
    right: 0;
    padding: 0 1.6em;
  }

  &__city-name {
    color: $whiteColor;
    font-size: 2.8em;
    line-height: 2.8em;
    font-weight: bold;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__dates {
    font-size: 1.8em;
  }

  &__hotel-name {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;

    font-size: 1.8em;
    color: $blackColor;
    margin-top: 0.8em;
  }

  &__discount {
    position: absolute;
    left: 0;
    top: 2.4em;
    border-radius: 0 0.4em 0.4em 0;
    background: #F75497;
    color: $whiteColor;
    padding: 0.4em 0.8em;
    font-size: 1.4em;
  }

  &__hotel-rating {
    position: absolute;
    top: 2.4em;
    right: 1.6em;
    display: flex;
    align-items: center;
    border-radius: 0.8em;
    padding: 0.4em 0.8em;
    background-color: $whiteColor;
    color: $blackColor;

    .ant-rate {
      font-size: 1.4em;
      margin-left: 0.4em;

      .ant-rate-star {
        margin: -1px 0 0 0;

        div {
          &:hover {
            transform: none;
          }
        }
      }
    }
  }

  &____response-container {
    font-size: 10px;
  }
}
