.about-us {
  @extend .container-large;

  @include mobile {
    padding: 0 16px;
  }

  .plainHtml {
    h2 {
      font-size: 32px;
      margin-bottom: 0.5em;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 600;
      line-height: 1.35;
    }
    h3 {
      font-size: 22px;
      margin-bottom: 16px;
      min-height: 58px;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 600;
      line-height: 1.35;
    }
    p {
      font-size: 20px;
      line-height: 32px;
    }
  }

  &__section {
    .discover {
      h2 {
        font-size: 32px;
        margin-bottom: 0 !important;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 600;
        line-height: 1.35;
      }
      p {
        margin-top: 0.5em;
        max-width: 75%;
      }
    }

    &.ant-row {
      margin: 80px 0;

      &:first-child {
        margin: 32px 0 80px;
      }

      @include mobile {
        margin: 32px 0;
      }
    }

    &--white {
      &.ant-row {
        padding: 32px 0;
        background-color: $whiteColor;
        border-radius: 16px;

        @include mobile {
          padding: 32px;
        }
      }
    }
  }

  &__title {
    &.ant-typography {
      font-size: 32px;

      @include mobile {
        font-size: 24px;
      }
    }

    &--help {
      &.ant-typography {
        margin-bottom: 48px;
      }
    }
  }

  &__description {
    font-size: 20px;
    line-height: 32px;

    @include mobile {
      font-size: 18px;
      line-height: 24px;
    }
  }

  &__image {
    max-width: 100%;

    @include mobile {
      margin-bottom: 32px;
    };
  }

  &__line {
    border: none;
    border-top: 1px solid $greyColor4;
  }

  &__help-item {
    &.ant-col {
      display: flex;
      flex-direction: column;
    }
    p {
      font-size: 18px !important;
      line-height: 28px !important;
    }
  }

  &__help-item-image {
    margin-bottom: 48px;
    max-width: 100%;
    height: 160px;

    @include tablet {
      height: 120px;
    }

    @include mobile {
      height: auto;
    }
  }

  &__help-item-title {
    &.ant-typography {
      font-size: 22px;
      margin-bottom: 16px;
      min-height: 58px;

      @include mobile {
        height: auto;
      }
    }
  }

  &__help-item-description {
    font-size: 18px;

    @include mobile {
      margin-bottom: 32px;
    }
  }

  &__choose-us {
    max-width: 75%;
    font-size: 20px;

    @include mobile {
      max-width: 100%;
      font-size: 18px;
    }
  }

  &__discover-deals {
    display: inline-block;
    background-color: $pinkColor;
    color: $whiteColor;
    border-radius: 8px;
    padding: 16px 32px;
    margin-top: 32px;
    font-weight: bold;
    text-align: center;

    @include mobile {
      width: 100%;
      padding: 12px 24px;
    }

    &:hover,
    &:focus,
    &:visited {
      background-color: $pinkColor;
      color: $whiteColor;
    }
  }
}
