.admin-dashboard {
  &__column {
    &:first-child {
      &.ant-col {
        padding-right: 16px;
      }
    }

    &:last-child {
      &.ant-col {
        padding-left: 16px;
      }
    }
  }

  &__static-column {
    padding: 0 16px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  &__block {
    margin-bottom: 40px;
  }

  &__section-title {
    font-size: 18px;
    font-weight: 600;
    margin-right: 16px;
  }

  &__date-range-picker {
    max-width: 210px;
  }

  &__filter-country {
    min-width: 150px;

    &-tooltip {
      display: flex;
      justify-content: space-between;
      margin: 2px 0;

      &-image {
        width: 15px;
        height: 15px;
      }

      &-text {
        font-size: 12px !important;
      }
    }
  }

  &__section {
    margin: 8px 0 0;
    padding: 16px 0;
    background-color: $greyColor3;
  }

  &__section-element {
    padding: 0 16px;
  }

  &__section-element--right-bordered {
    border-right: 1px solid #D8D8D8;
  }

  &__section-element-title {
    color: $greyColor2;
  }

  &__section-element-title--yellow {
    &:before {
      content: "";
      display: inline-block;
      margin-right: 4px;
      width: 8px;
      height: 8px;
      background-color: $mainYellowColor;
      border-radius: 50%;
    }
  }

  &__section-element-title--green {
    &:before {
      content: "";
      display: inline-block;
      margin-right: 4px;
      width: 8px;
      height: 8px;
      background-color: #009688;
      border-radius: 50%;
    }
  }

  &__section-element-title--blue {
    &:before {
      content: "";
      display: inline-block;
      margin-right: 4px;
      width: 8px;
      height: 8px;
      background-color: $mainBlueColor;
      border-radius: 50%;
    }
  }

  &__section-element-title--red {
    &:before {
      content: "";
      display: inline-block;
      margin-right: 4px;
      width: 8px;
      height: 8px;
      background-color: $dangerColor;
      border-radius: 50%;
    }
  }

  &__section-element-title--yellow {
    &:before {
      content: "";
      display: inline-block;
      margin-right: 4px;
      width: 8px;
      height: 8px;
      background-color: $mainYellowColor;
      border-radius: 50%;
    }
  }

  &__section-element-number {
    font-size: 24px;
    font-weight: 600;
  }

  &__static-section {
    background-color: $greyColor3;
    padding: 16px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 12px;
    font-weight: 600;
  }

  &__static-section-title {
    color: $blackColor;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  &__static-section-row {
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__static-section-number {
    text-align: right;
    font-weight: normal;
  }

  &__select-country {
    display: inline-block;
    margin-right: 10px;
  }
}
