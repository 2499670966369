$whiteColor: #ffffff;
$mainBlueColor: #3D4CC1;

$mainYellowColor: #FFC300;
$mainYellowColorDisabled: #F6DE91;
$blackColor: #363636;
$errorColor: #FF0303;
$errorBorderColor: #EB2222;
$dangerColor: #CB3E2A;
$dangerColorDisabled: #E59F95;
$greyColor: #808080;
$greyColor2: #616161;
$greyColor3: #FAFAFA;
$greyColor4: #B1B3BF;
$greyColor5: #F0F0F0;
$greyBorderColor: #BFBFBF;
$blueCheckboxColor: #2196F3;
$lightPinkColor: #fA9EC4;
$pinkColor: #F75497;
$darkPinkColor: #F40B6C;
$successColor: #71C639;
$successColor: #71C639;
$ghostWhite: #2636B80A;

$mobileES: 360px; // ES - mean extra small
$mobileMin: 576px;
$mobileMax: 767px;
$tabletMin: 768px;
$tabletMiddle: 880px;
$tabletMax: 991px;
$laptopMin: 992px;
$laptopMax: 1199px;
$desktopMin: 1200px;
$desktopMiddle: 1440px;
$desktopMiddle2: 1640px;
$desktopMax: 1920px;

