.filters-input {
  &-wrapper {
    display: flex;
    align-items: center;
    margin-right: 16px;
  }
  &__label {
    margin-right: 16px;
  }
  &__per-page .filters-input {
    width: 64px;
  }
}
