.admin-listings {
  .ant-table-content {
    overflow-y: auto;
  }

  &__filters {
    .ant-select {
      margin-bottom: 10px;
    }
  }

  &__date-range-picker {
    max-width: 210px;

    &.ant-picker-range {
      margin-right: 16px;
    }
  }

  &__date-type {
    &.ant-switch {
      margin-right: 16px;
    }
  }

  &__download-csv {
    &.ant-col {
      padding-left: 8px;
      text-align: right;

      .ant-btn {
        padding: 8px 15px;
      }
    }
  }

  &__select-country {
    display: inline-block;
    margin-right: 10px;
  }

  &__filter {
    &-type {
      width: 120px;
    }
  }
}
