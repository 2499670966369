.social-link {
  display: inline-block;
  margin: 0 15px;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  img {
    width: 24px;
    height: 24px;
  }
}

.socials-link-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}