.create-dispute {
  .create-dispute__checkbox-group {
    .ant-checkbox-wrapper,
    .ant-checkbox-group-item {
      width: 100%;
      margin: 0 0  16px;
    }
  }
}

