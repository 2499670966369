.reviews {
  margin-top: 48px;

  @include mobile {
    margin-top: 8px;
  }

  .ant-carousel {
    width: 100%;

    .slick-list {
      border: 1px solid #F3F3F4;
      border-radius: 20px;
      background-color: #FFFFFF;
      max-width: 480px;
      margin: 0 auto;

      .slick-slide {
        padding: 32px;
      }
    }

    .slick-arrow, .slick-arrow:hover, .slick-arrow:focus {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin: 0;
      transform: translateY(-50%);
      z-index: 10;

      img, svg {
        width: 100%;
        height: 100%;
      }
    }

    .slick-prev {
      left: -100px;
      transform: rotate(180deg) translateY(50%);

      &:hover {
        transform: rotate(180deg) translateY(50%);
      }
    }

    .slick-next {
      right: -100px;
    }

    .slick-dots {
      display: flex;
      align-items: center;

      &.slick-dots-bottom {
        bottom: -70px;
      }

      li {
        width: 12px;
        height: 12px;
        top: 1px;

        button {
          opacity: 1;
          border-radius: 50%;
          width: 9px;
          height: 9px;
          background: #E6E6E6;
        }

        &.slick-active {
          width: 15px;
          top: 0;

          button {
            width: 12px;
            height: 12px;
            background: $mainBlueColor;
          }
        }
      }
    }
  }
}
