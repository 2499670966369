@font-face {
  font-family: "SF Pro Display";
  src: url("./../fonts/SFProDisplay-Light.woff");
  font-display: swap;
  font-weight: 300;
}
@font-face {
  font-family: "SF Pro Display";
  src: url("./../fonts/SFProDisplay-Regular.woff");
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("./../fonts/SFProDisplay-Medium.otf");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("./../fonts/SFProDisplay-Semibold.ttf");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("./../fonts/SFProDisplay-Bold.woff");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("./../fonts/Lato-Thin.ttf");
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("./../fonts/Lato-ThinItalic.ttf");
  font-weight: 100;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Lato";
  src: url("./../fonts/Lato-Light.ttf");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("./../fonts/Lato-LightItalic.ttf");
  font-weight: 300;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Lato";
  src: url("./../fonts/Lato-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("./../fonts/Lato-Bold.ttf");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("./../fonts/Lato-BoldItalic.ttf");
  font-weight: 700;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Lato";
  src: url("./../fonts/Lato-Black.ttf");
  font-weight: 900;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("./../fonts/Lato-BlackItalic.ttf");
  font-weight: 900;
  font-display: swap;
  font-style: italic;
}
