.follow-us {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 16px;
  background-color: $whiteColor;
  height: 170px;
  border: 1px solid #f0f0f0;
  padding: 20px;
  overflow: hidden;
  margin: 5px 0;

  &__title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0;
  }

  &__text {
    font-size: 12px;
    margin-bottom: 0;
  }

  &__additional-text {
    display: none;

    @media screen and (min-width: $desktopMiddle2)  {
      display: inline;
    }
  }
}
