.reset-password-email-sent {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mobile {
    box-shadow: none;
    padding: 24px 16px 12px;
  }

  &__title {
    font-size: 26px;
    font-weight: bold;
    color: $blackColor;
    margin-bottom: 8px;
    text-align: center;
  }

  &__subtitle {
    text-align: center;
    font-size: 14px;
    color: $blackColor;
  }

  &__icon {
    margin: 23px 0;
  }

  &__redirect-link {
    width: 100%;
  }

  &__resend-email-label {
    font-size: 14px;
    color: $blackColor;
  }

  &__resend-email-link {
    color: $mainBlueColor;
    font-weight: 600;
    cursor: pointer;
  }
}
