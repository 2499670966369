.header {
  &.ant-row {
    min-height: 100px;
    background-color: $whiteColor;
    position: sticky;
    top: 0;
    z-index: 500;

    @include mobile {
      min-height: 50px;
      position: relative;
    }
  }

  @include mobile {
    min-height: 55px;
    padding: 0 16px;
  }

  &__logo {
    width: 100%;
    padding-right: 24px;

    @include mobile {
      max-height: 30px;
      width: auto;
    }
  }

  &__caret-down-outlined {
    &.anticon {
      color: $mainBlueColor;
    }
  }

  &__links {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;

    @include mobile {
      display: none;
    }
  }

  &__link {
    font-size: 16px;
    line-height: 26px;
    color: #05061A;
    font-weight: bold;
    margin: 0 10px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover,
    &:active,
    &:visited {
      color: #05061A;
    }

    @include mobile {
      display: block;
      margin: 12px 0;
      text-align: center;
      font-size: 16px;
    }

    &--resell {
      cursor: pointer;
      color: $whiteColor;
      background: $pinkColor;
      border-radius: 6px;
      padding: 4px 12px;

      &:hover,
      &:active,
      &:visited {
        color: $whiteColor;
      }
    }

    &--pointer {
      cursor: pointer;
    }

    &--register {
      display: none;
      @include desktopMiddle {
        display: initial;
      }

      &-search {
        display:none;
      }
    }
  }

  &__link-icon {
    margin-right: 8px;
  }

  &__user {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: $whiteColor;
    cursor: pointer;
    margin-left: 8px;

    @include tablet {
      margin-left: 5px;
    }
  }

  &__avatar {
    max-height: 48px;
    max-width: 48px;
    border-radius: 50%;
    margin: 0 8px 0 0;
  }

  &__user-links {
    &.ant-dropdown {
      box-shadow: none;

      .ant-dropdown-menu {
        min-width: 200px;
        position: relative;
        left: 16px;
        border-radius: 16px;
        border: 1px solid $greyColor4;
        margin-top: 15px;
        padding: 22px;
        box-shadow: none;
        box-sizing: border-box;

        &:before {
          display: none;
        }

        .ant-dropdown-menu-item {
          padding: 8px 0;

          &:first-child {
            padding-top: 0;
          }

          &:last-child {
            padding-bottom: 0;
          }

          &:hover {
            background: none;
          }

          a {
            display: flex;
            align-items: center;
          }
        }

        .ant-dropdown-menu-item-divider {
          margin: 8px 0;
        }
      }
    }
  }

  &__sign-out-link {
    width: 100%;
    background: $whiteColor;
    border-radius: 8px;
    font-size: 16px;
    color: $mainBlueColor;
    text-align: center;
    padding: 10px;
    margin: 32px 0 16px;
  }

  &__user-link-icon {
    width: 16px;
    max-height: 16px;
    margin-right: 16px;
  }

  &__mobile-menu {
    display: none;

    @include mobile {
      display: flex;
      justify-content: flex-end;
    }
  }

  &__mobile-menu-content {
    @include mobile {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }

    .header__avatar {
      max-height: 75px;
      max-width: 75px;
      height: 75px;
      width: 75px;
      margin-right: 16px;
    }

    .header__user-link {
      color: $whiteColor;
      margin: 20px 0;
      font-size: 20px;
    }

    .header__user-link-icon {
      width: 24px;
      max-height: 24px;
      margin-right: 32px;
    }

    .header__user-name-container {
      text-align: left;
    }

    .header__user-name {
      color: $whiteColor;
      font-size: 28px;
    }
  }

  &__mobile-menu-sandwich {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }

  &__mobile-drawer-content {
    &.ant-row {
      height: 100%;
    }
  }

  &__mobile-menu-drawer {
    z-index: 10000;

    .ant-drawer-close {
      color: $whiteColor;
      padding: 4px;
      font-size: 20px;
      top: 10px;
      right: 10px;
    }

    .ant-drawer-body {
      a {
        display: block;
      }
    }
  }

  &__mobile-user {
    display: flex !important;
    align-items: center;
    text-align: center;
    color: $whiteColor;
    margin-bottom: 32px;
  }

  &__edit-profile {
    font-family: initial;
    font-size: 16px;
  }

  .listing-search {
    padding: 8px 12px;
    font-size: 14px;

    &__field-container {
      padding: 0 6px;
    }

    &__label {
      font-size: 12px;
      margin-bottom: 0;
    }

    &__input {
      .ant-select-selector {
        font-size: 14px;

        .ant-select-selection-placeholder {
          font-size: 14px;
          margin-top: 0;
        }

        .ant-select-selection-item {
          margin-top: 1px;
        }
      }

      &.ant-picker {
        margin-top: 11px;

        .ant-picker-input {
          input {
            font-size: 14px;

            &::placeholder {
              font-size: 14px;
            }
          }
        }
      }

      &-wrapper {
        .rooms-guests__select {
          &-wrapper {
            font-size: 14px;
            margin-top: 4px;
          }
        }
      }
    }

    &__buttons.ant-col {
      .search-icon {
        height: 40px;
      }
    }
  }

  &__search-container {
    &.ant-col {
      display: none;

      @include desktop {
        display: initial;
      }
    }
  }
}
