.admin-categories {
  &__column {
    .ant-table-row {
      .ant-table-cell {
        word-break: break-word;
      }
    }
    &:first-child {
      padding-right: 15px;

      @include mobile {
        padding: 0 15px;
      }
    }

    &:last-child {
      padding-left: 15px;

      @include mobile {
        padding: 0 15px;
      }
    }
  }

  &__form {
    &-body {
      background-color: $greyColor3;
      padding: 20px 32px;
      margin: 0 0 8px;
    }

    &-title {
      text-transform: uppercase;
      margin-bottom: 15px;
      color: $greyColor;
      font-size: 12px;
    }

    &-submit .ant-btn {
      height: 40px;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;
      color: $blackColor;
      margin: 24px;
      border-radius: 4px;
      padding: 0 35px;
    }
  }
}
