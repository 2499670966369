.form-title {
  font-size: 42px;
  margin-bottom: 24px;
}

.form-subtitle {
  font-size: 20px;
  margin-bottom: 16px;
}

.form-title, .form-subtitle {
  font-weight: bold;
}
