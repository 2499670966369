.profile {
  &__avatar-uploader {
    padding-right: 32px;
    margin: 5px 0 24px;

    @include mobile {
      padding-right: 0;

      &.ant-col {
        display: flex;
        justify-content: center;
      }
    }
  }

  &__avatar-img-container {
    max-width: 168px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
  }

  &__avatar-img {
    width: 100%;
    display: block;
  }

  &__avatar-upload-button-container {
    width: 100%;

    .ant-upload {
      width: 100%;
    }
  }

  &__avatar-upload-button {
    width: 100%;
    cursor: pointer;
    background: $mainYellowColor;
    padding: 10px;
    text-align: center;
    font-weight: 600;
  }

  &__title {
    color: $greyColor;
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 16px;
  }

  &__field {
    margin-bottom: 10px;
    font-size: 14px;

    &--first-name {
      margin-right: 14px;
    }

    &-tooltip {
      padding: 0 1px;
      color: $dangerColor;
    }

    &--error {
      .ant-select-selector {
        border-color: $errorBorderColor !important;

        &:focus {
          border-color: $errorBorderColor !important;
        }
      }
    }
  }

  &__label {
    margin-top: 12px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5);
  }

  &__input {
    height: 38px;

    &.ant-input {
      color: rgba(0, 0, 0, 0.87);
      font-family: "SF Pro Display", Calibri, Candara, Arial, sans-serif;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 18px;
    }

    &:focus.ant-input,
    &:focus.ant-input-password {
      border-color: $greyBorderColor;
      box-shadow: none;
    }

    &--error.ant-input,
    &--error.ant-input-password {
      border-color: $errorBorderColor;

      &:focus {
        border-color: $errorBorderColor;
      }
    }
  }

  &__error-container {
    display: flex;
    justify-content: space-between;
  }

  &__error {
    display: block;
    color: $errorColor;
    font-size: 12px;
  }

  &__date-picker {
    &.ant-picker {
      display: block;
    }

    &--error {
      &.ant-picker {
        border-color: $errorBorderColor;

        &:focus {
          border-color: $errorBorderColor;
        }
      }
    }
  }

  &__nationality-select {
    &.ant-select {
      display: block;
    }
  }

  &__additional-services {
    margin-bottom: 40px !important;

    .additional-services__collapse {
      .ant-collapse-item .ant-collapse-header {
        padding: 12px 0;
      }

      .ant-collapse-item .ant-collapse-content {
        padding: 10px 0;

        .ant-collapse-content-box {
          padding: 10px 12.5px;
        }
      }
    }





    &-button {
      cursor: pointer;
      text-align: center;
      margin: 0;
      padding: 10px 16px;
      text-transform: uppercase;
      font-weight: 600;
      background-color: #F7F7F7;
      font-size: 16px;
    }
  }

  &__password {
    &-image {
      width: 18px;
      height: 18px;
      margin: 0 14px;
      cursor: pointer;
    }
  }

  &__history {
    .profile-history .ant-collapse {
      background-color: transparent;
    }

    .profile-history__list-item {
      background-color: white;
    }
  }

  &__buttons-bottom {
    margin: 10px 0 40px;

    @include mobile {
      padding: 0 16px;
    }

    .button {
      margin-right: 8px;
      line-height: 22px;

      &:last-child {
        margin-right: 0;
      }

      @include mobile {
        padding: 7px 10px;
        font-size: 12px;
      }
    }
  }

  &__required-hint {
    display: inline-block;
    width: 100%;
    margin: 18px 0;
    text-align: center;
  }

  &__form-item {
    height: 100%;
  }
}
