.form-container {
  padding: 24px;
  border: 1px solid #F3F3F4;
  border-radius: 20px;
  background-color: #FFFFFF;

  @include mobile {
    padding: 16px;
  }
}
