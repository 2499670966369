.hotel-view {
  @extend .container-large;
  padding: 0 16px;

  &__hotel {
    &-images {
      @include mobile {
        margin-bottom: 16px;
      }

      .ant-carousel {
        width: 100%;
        max-width: 1000px;
        border-radius: 16px;
        overflow: hidden;


        .slick-arrow, .slick-arrow:hover, .slick-arrow:focus {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          background-color: rgba(255, 255, 255, 0.6);
          margin: 0;
          transform: translateY(-50%);
          z-index: 10;

          img, svg {
            width: 100%;
            height: 100%;
          }

          @include mobile {
            width: 32px;
            height: 32px;
          }
        }

        .slick-prev {
          left: 25px;
          transform: rotate(180deg) translateY(50%);

          @include mobile {
            left: 10px;
          }

          &:hover {
            transform: rotate(180deg) translateY(50%);
          }
        }

        .slick-next {
          right: 25px;

          @include mobile {
            right: 10px;
          }
        }
      }

      &-item {
        width: 100%;
        cursor: pointer;
      }

      &-mini {
        margin: 16px 0 16px -14px !important;

        .ant-col {
          flex: auto;
          max-width: calc(25% - 14px);
          width: calc(25% - 14px);
          margin: 0 0 14px 14px;
          position: relative;
          border: none;
          border-radius: 8px;
          overflow: hidden;
          cursor: pointer;

          &.active {
            border: 2px solid $mainBlueColor;
          }
        }

        &-button {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #F7F7F7;
          opacity: 0.9;
          cursor: pointer;
        }
      }
      &-empty {
        margin-bottom: 40px;
      }
    }

    &-description {
      &.ant-row {
        font-size: 14px;
        line-height: 20px;
        word-break: break-word;

        @include mobile {
          display: none;
        }

        &.mobile-view {
          @include mobile {
            display: block;
          }
        }
      }

      &-title {
        font-size: 28px;
        margin-bottom: 32px;
      }

      &.mobile-view {
        display: none;
      }
    }

    &-name {
      .ant-typography {
        font-weight: 600;
        font-size: 36px;
        line-height: 56px;
        margin-bottom: 8px;

        @include mobile {
          font-size: 32px;
          line-height: 40px;
        }
      }
    }

    &-name-container {
      margin-bottom: 16px;
    }

    &-rate {
      margin-bottom: 16px;
      align-items: center;

      .ant-rate {
        .ant-rate-star {
          margin: 0 0 0 1px;

          div {
            &:hover {
              transform: none;
            }
          }
        }
      }

      .rating {
        display: inline-block;
        font-size: 18px;
        font-weight: 600;
        margin: 5px 4px 0 0;
      }
    }
    &-data {
      img {
        width: 24px;
        height: 24px;
        margin: 0 14px 8px 0;
      }
      span {
        font-size: 14px;
        font-weight: 600;
        color: $greyColor2;
      }
    }

    &-location {
      display: flex;
      align-items: center;

      &-row {
        font-size: 14px;
        font-weight: 600;
        color: $greyColor2;
      }
    }
  }

  &__facilities-container {
    margin-top: 10px;

    &:first-child {
      margin-top: 0;
    }
  }

  &__facilities {
    width: 100%;
    margin: 48px 0;

    &.mobile-view {
      display: none;
    }

      > span, > div {
      width: 100%;
    }
    .hotel__field-facilities-item {
      @include tablet {
        width: 100%;
      }
    }

    .hotel__field-facilities-item-room-type {
      width: 100%;
    }
  }

  &__facilities-label-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    img {
      width: 20px;
      margin-left: 12px;
    }
  }

  &__facilities-label {
    display: inline-block;
    font-size: 28px;
    line-height: 28px;
  }

  &__facilities-see-all {
    margin-top: 5px;
    cursor: pointer;
  }

  &__facilities-icon {
    width: 24px;
    margin-right: 16px;
  }

  &__facilities-wrapper {
    max-width: 400px;
    margin-left:auto;

    .hotel-view__facilities {
      width: 100%;
      margin: 0;
    }
  }

  &__details-container {
    position: sticky;
    top: 110px;
    z-index: 5;
  }

  &__additional-info {
    &.ant-col {
      margin-top: 32px;
    }
  }

  &__rating {
    &.ant-col {
      text-align: right;
      padding-right: 50px !important;

      @media screen and (max-width: $laptopMax) {
        padding-right: 32px !important;
      }

      @include mobile {
        text-align: left;
        margin-top: 8px;
      }
    }
  }

  &__carousel-modal {
    display: none;
    justify-content: center;
    align-items: center;

    &.visible {
      display: flex;

      @include mobile {
        display: none;
      }
    }
    .hotel-view__carousel-modal-close {
      position: absolute;
      top: 24px;
      right: 24px;
      padding: 4px;
      cursor: pointer;
    }
  }
}

.hotel-view {
  @include mobile {
    .hotel-view {
      &__hotel {
        &-images {
          max-width: 100vw;
        }
        &-images-mini {
          display: none;
        }
        &-name {
          font-size: 24px;
          line-height: 31px;
        }
        &-description {
          margin-top:24px;
        }
      }

      &__facilities {
        display: none;

        &.mobile-view {
          display: block;
          padding: 16px;
          margin-bottom: 32px;
        }
      }

      &__facilities .hotel__field-facilities-item {
        width: 100%;
      }
    }
    .mobile-padding {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}
