.account-verification-modal {
  text-align: left !important;
  padding: 0 5px !important;

  &__container {
    width: 100%;
  }

  &__title {
    font-size: 24px;
    font-weight: 600;
  }

  &__steps-counter {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    color: $greyBorderColor;
    margin-top: 8px;
  }

  &__steps {
    &.ant-col {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;
    }
  }

  &__step {
    width: 100%;
    height: 4px;
    border-radius: 3px;
    background-color: #D8D8D8;
    margin: 0 4px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &--active {
      background-color: $mainBlueColor;
    }
  }

  .modal__buttons {
    @include mobile {
      flex-direction: column;
    }

    .button {
      &.ant-btn {
        padding: 7px 15px;
        margin: 8px 0 0;
      }
    }

    .button--back {
      &.ant-btn {
        background: none;
        border: none;
        box-shadow: none;
        font-size: 14px;
        font-weight: normal;
        text-transform: none;
        padding: 0;
        color: #616161;
      }
    }

    .profile-details__submit-container {
      margin-top: 24px;
      text-align: right;
    }
  }

  &__message {
    &.ant-col {
      display: flex;
      align-items: center;
      background: rgba(33, 150, 243, 0.1);
      padding: 16px 32px;
      color: $greyColor2;
      margin: 8px 0;
    }
  }

  &__message-icon {
    margin-right: 24px;
  }

  &__message-title {
    font-weight: bold;
  }

  &__message-link {
    color: $blueCheckboxColor;

    &:hover,
    &:active,
    &:visited {
      color: $blueCheckboxColor;
    }
  }

  .form-section {
    padding: 16px;
    margin: 0;
  }

  &__profile-details {
    &.ant-row {
      height: 100%;
    }
  }

  .profile-details {
    flex-direction: row;
  }
}
