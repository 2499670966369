.seller-account-restrict-access {
  &__modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 60px 0 55px;
    text-align: center;
  }

  &__modal-text {
    font-size: 18px;
    margin: 45px 0;
  }

  &__modal-link {
    background: $mainYellowColor;
    color: $blackColor;
    text-transform: uppercase;
    font-weight: 600;
    padding: 8px 16px;
    border-radius: 4px;

    &:hover,
    &:active,
    &:visited {
      background: $mainYellowColor;
      color: $blackColor;
    }
  }
}
