.admin-geo-data {
  &__content {
    margin: 16px 0 40px;
    padding: 16px;
    background-color: $greyColor3;
  }

  &__types-select {
    background: $whiteColor;
    padding: 4px;
    border-radius: 4px;
    border: 1px solid $greyBorderColor;
    margin-bottom: 20px;

    .ant-radio-button-wrapper {
      background: $whiteColor;
      color: $greyBorderColor;
      border: none;
      height: 24px;
      line-height: 22px;

      &:before {
        display: none !important;
      }

      &:hover {
        color: $greyBorderColor;
      }

      &:first-child {
        border: none;
      }
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      background: $mainYellowColor;
      color: $blackColor;
      border-radius: 4px;
      border: none;

      &:hover {
        color: $blackColor;
      }
    }
  }

  &__state-name {
    color: $greyColor2;
  }

  &__state-value {
    font-weight: 600;
  }

  &__state-value--green {
    color: #009688
  }

  &__state-value--blue {
    color: $mainBlueColor;
  }

  &__state-value--red {
    color: $dangerColor;
  }

  &__empty {
    color: $greyColor2;
    text-align: center;
  }
}
