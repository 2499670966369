.listing-item {
  color: #000;
  position: relative;

  &.searching {
    .listing-item__image { 
      box-shadow: 0 0 15px -1px rgba(0, 0, 0, 0.8);
      opacity: 1;
      animation: pulse 2s infinite;
    }
    .listing-item__info {
      box-shadow: 0 0 15px -1px rgba(0, 0, 0, 0.8);
      opacity: 1 !important;

      @include mobile {
        padding: 16px;
      }
    }
  }

  .resale-deals-badge {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: #f75497;
    color: #fff;
    z-index: 1;
    border-radius: 8px;
    padding: 5px 15px;
    font-weight: bold;
  }

  &.ant-row {
    margin: 16px 0;
  }

  &__title {
    font-weight: bold;
    color: #000;
    font-size: 14px;
  }

  &__characteristic {
    font-size: 12px;

    &__pink {
      color: $pinkColor;
      font-weight: bold;
    }
  }

  &__row {
    margin-bottom: 8px;
  }

  &__flex_row {
    display: flex;
    align-items: center;
    justify-content: right;
  }

  &__flex_row_item {
    flex: 1;
  }

  &__image {
    border-radius: 16px 0 0 16px;
    overflow: hidden;

    @media screen and (max-width: $laptopMax) {
      border-radius: 16px;
    }

    &--bordered {
      border: 1px solid $greyColor5;
    }
  }

  &__hotel-image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @media screen and (max-width: $laptopMax) {
      min-height: 200px;
    }
  }

  &__empty-hotel-image {
    width: 100px;
  }

  &__info {
    &.ant-col {
      margin-left: -16px;
      background: $whiteColor;
      border-bottom-right-radius: 8px;
      border-top-right-radius: 8px;
      border: 1px solid $greyColor5;
      padding: 16px;

      @media screen and (max-width: $laptopMax) {
        margin: 0;
        border: none;
        background: none;
        padding: 16px 0;
      }
    }
  }

  &__hotel-name {
    font-size: 20px;
    line-height: 24px;

    @media screen and (max-width: $laptopMax) {
      font-size: 16px;
    }
  }

  &__hotel-rating-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 8px 0;

    &__justify-end {
      justify-content: flex-end ;
    }
  }

  &__hotel-rating {
    margin-right: 16px;
  }

  &__original-price-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__original-price {
    display: flex;
    align-items: center;
    font-size: 12px;
  }

  &__original-price-icon {
    width: 20px;
    margin-right: 8px;
  }

  &__discount {
    border-radius: 4px;
    background: #F75497;
    color: $whiteColor;
    padding: 4px 8px;
    font-size: 14px;
  }

  &__price {
    font-size: 18px;
    font-weight: bold;
    margin: 8px 0;
  }

  &__book {
    background: $mainBlueColor;
    display: block;
    color: $whiteColor;
    font-size: 14px;
    font-weight: bold;
    padding: 6px 16px;
    border-radius: 10px;
    text-align: center;

    @media screen and (max-width: $laptopMax) {
      padding: 10px 16px;
    }

    &:hover,
    &:active,
    &:visited {
      color: $whiteColor;
    }
  }

  &__link {
    text-decoration: underline;
    color: $mainBlueColor;

    &:hover,
    &:active,
    &:visited {
      color: $mainBlueColor;
      text-decoration: underline;
    }
  }

  &__free-cancellation {
    display: flex;
    align-items: center;
    color: $pinkColor;
    font-weight: bold;
    font-size: 12px;

    img {
      margin-right: 8px;
    }
  }
  &__reason-for-sale {
    display: flex;
    align-items: center;
    color: $pinkColor;
    font-weight: bold;
    font-size: 12px;
  }
}

.searching-row {
  margin:10px 20px 0 10px;
  display:flex;
  flex-flow: column;
  .placeholder-info {
    filter: blur(1px);
    opacity: 0.3;
  }
  .search-label {
    margin-top: 20px;
    color: $pinkColor;
    font-size: 14px;
  }
}

.no-availability-row {
  margin:40px 20px 0 10px;
  color: $pinkColor;
  height:150px;
  font-size: 14px;
}

@keyframes pulse {
  0% {
    opacity: 0.8;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.8;
  }
}
