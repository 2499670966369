.resale-listing-item {
    &__detail {
        position: absolute;
        bottom: 1.6em;
        left: 0;
        right: 0;
        padding: 0 1.6em;
        font-size: 10px;
      }
    
    &__city-name {
        color: $whiteColor;
        text-shadow:  rgba(0, 0, 0, 0.5) 0 4px 8px, rgba(0, 0, 0, 1) 0 1px 2px, rgba(0, 0, 0, 1) 0 1px 2px;
        -webkit-font-smoothing: antialiased;
        font-size: 2.8em;
        line-height: 2.8em;
        font-weight: bold;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

    &__city-name::before {
        content: '';
        background-image: url(/images/location-pin.svg);
        background-repeat: no-repeat;
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 5px;
    }
    
    &__dates {
        color: $whiteColor;
        text-shadow:  rgba(0, 0, 0, 0.5) 0 4px 8px, rgba(0, 0, 0, 1) 0 1px 2px, rgba(0, 0, 0, 1) 0 1px 2px;
        -webkit-font-smoothing: antialiased;
        font-weight: bold;
        font-size: 2.2em;
    }

    .listing-item__image--bordered {
        .resale-listing-item__city-name {
            text-shadow:  rgba(255, 255, 255, 1) 0 1px 2px, rgba(255, 255, 255, 1) 0 1px 2px, rgba(255, 255, 255, 1) 0 1px 2px, rgba(255, 255, 255, 1) 0 1px 2px;
            color: rgb(191,191,191);
            &::before {
              background-image: url(/images/location.svg);
            }
        }
        .resale-listing-item__dates {
            text-shadow:  rgba(255, 255, 255, 1) 0 1px 2px, rgba(255, 255, 255, 1) 0 1px 2px, rgba(255, 255, 255, 1) 0 1px 2px, rgba(255, 255, 255, 1) 0 1px 2px;
            color: rgb(191,191,191);
        }
      
    }
}
