.file-upload {
  &__container {
    color: $greyColor2;

    .ant-upload {
      display: flex;
      justify-content: center;
      min-height: 135px;
      color: $greyColor;
    }
  }
  &__text {
    &-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    &-link {
      color: #0091FF;
      margin-right: 4px;
    }
  }
  &__button-upload {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    padding: 5px 15px;
    color: $blackColor;
    border: 1px solid $mainYellowColor;
    background: $whiteColor;
    border-radius: 4px;
    max-width: 75px;
    margin-top: 5px;
  }
  &__file {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 8px 0;
    font-size: 14px;
    padding: 0 10px 0 2px;

    @include mobile {
      padding-right: 0;
    }
  }
  &__delete-uploaded-file {
    cursor: pointer;
  }
  &__file-types {
    margin-top: 15px;

    &--error {
      color: $errorColor;
    }
  }
  &__files--error {
    .ant-upload.ant-upload-drag {
      border-color: $errorBorderColor;
    }
  }
}
