.wallet-pending {
  display: flex;
  align-items: center;
  margin: 0 -32px;

  &__title {
    margin: 0;
  }

  &__item {
    padding: 0 32px;
  }

  &__subtext {
    font-size: 12px;
  }

  &__balance {
    font-size: 36px;
    font-weight: bold;
  }
}

@media screen and (max-width: $laptopMax) {
  .wallet-pending {
    flex-flow: wrap row;
    margin: -16px;

    &__item {
      display: flex;
      justify-content: center;
      width: 50%;
      padding: 16px;
    }
  }
}

@media screen and (max-width: 576px) {
  .wallet-pending {
    &__item {
      width: 100%;
    }
  }
}
