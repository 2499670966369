.home-page-reviews {
  margin-top: 24px;

  @include tablet {
    padding: 0 20px;
  }

  @include mobile {
    padding: 0 15px;
    margin-top: 8px;
  }

  .ant-carousel {
    width: 100%;

    .slick-arrow, .slick-arrow:hover, .slick-arrow:focus {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin: 0;
      transform: translateY(-50%);
      z-index: 10;
      color: $mainBlueColor;

      @include mobile {
        width: 30px;
        height: 30px;
      }
    }

    .slick-prev {
      left: -60px;

      @include tablet {
        left: -35px;
      }

      @include mobile {
        left: -25px;
      }
    }

    .slick-next {
      right: -60px;

      @include tablet {
        right: -35px;
      }

      @include mobile {
        right: -25px;
      }
    }
  }

  &__review {
    border: 1px solid #F3F3F4;
    border-radius: 10px;
    background-color: #FFFFFF;
    color: $blackColor;
    padding: 24px;
    height: 100%;
    min-height: 350px;

    .ant-rate {
      margin-top: 4px;

      .ant-rate-star:not(:last-child) {
        margin-right: 2px;
      }
    }
  }

  &__review-heading {
    margin-bottom: 16px;
  }

  &__review-name {
    font-size: 24px;
    font-weight: bold;
  }

  &__review-location {
    font-size: 18px;
    font-style: italic;
    color: $mainBlueColor;
  }

  &__review-rating {
    text-align: right;

    @include laptop {
      text-align: left;
    }

    @include tablet {
      text-align: left;
    }
  }

  &__review-text {
    font-size: 18px;
  }

  .swiper-initialized {
    height: 300px;
  }

  .swiper-with-navigation {
    .swiper-initialized {
      padding-left:20px;
      padding-right:20px;

      .swiper-button-prev {
        left: 1px;
        &::after {
          color: #3D4CC1;
          font-size: 35px;
        }
      }
      .swiper-button-next {
        right: 1px;
        &::after {
          color: #3D4CC1;
          font-size: 35px;
        }
      }
    }
  }

  .swiper-pagination {
    bottom: unset;
    margin: 10px 0;
  }
}
