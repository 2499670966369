.listing-search {
  height: 100%;
  background-color: $whiteColor;
  padding: 16px 24px;
  border-radius: 16px;
  border: 1px solid #BEBFC9;

  &__field-container {
    padding: 0 16px;

    &:first-child {
      padding-left: 0 !important;
    }

    &--bordered {
      border-right: 1px solid #D8D8D8;
    }
  }

  &__highlighted {
    .listing-search__field-container {
      &--bordered {
        border-right: none;
      }
    }
    .listing-search__field-guests {
      padding-left:10px;
    }
    .listing-search__field-dates {

      margin-top:-16px;
      padding-top: 16px;
      margin-bottom: -16px;
      background:#e7edf7;
      border: 1px solid #b5d6fc;
      border-radius: 4px;
      -webkit-box-shadow: 0px 0px 6px 2px rgba(149,159,214,1);
      -moz-box-shadow: 0px 0px 6px 2px rgba(149,159,214,1);
      box-shadow: 0px 0px 6px 2px rgba(149,159,214,1);

      padding-top:6px !important;
      padding-left:6px !important;

      .listing-search__label-wrapper {
        padding-left:16px !important;
      }

      @include mobile {
        padding-top: 8px !important;
        margin-top: -8px;
        margin-bottom: 8px;
        padding-bottom:16px;
        margin-left:-16px;
        min-width: calc(100% + 32px);
      }

      .ant-picker {
        border: 1px solid #b5d6fc;
        margin-top:4px;
        padding:3px;
        padding-left:10px;
        border-radius: 4px;
        -webkit-box-shadow: 0px 0px 4px 0px rgba(149,159,214,1);
        -moz-box-shadow: 0px 0px 4px 0px rgba(149,159,214,1);
        box-shadow: 0px 0px 4px 0px rgba(149,159,214,1);
        input {
          cursor: pointer;
        }
        input::placeholder {
          color: #9bb3d3 !important;
        }
      }
    }
    @media screen and (max-width: 1199px) and (min-width: 767px) {
      .listing-search__field-dates {
        position: relative;
        right: -14px;
        border-top-right-radius: 14px;
      }
      .listing-search__label-wrapper .listing-search__label {
        margin-top:8px;
      }
    }
  }

  &__wrapper {
    width: 100%;
    padding: 16px;
    background-color: #F7F7F7;

    h3 {
      //font-size: 18px;
      margin-bottom: 16px;
      text-align: center;
      color: #020202;
      opacity: 0.8;
    }
  }

  &__input {
    width: 100%;

    .ant-select-selector {
      border: none !important;
      padding: 0 !important;
      //font-size: 18px;
      box-shadow: none !important;

      .ant-select-selection-placeholder {
        color: $greyColor4;
        opacity: 1;
        margin-top: 2px;
      }

      .ant-select-selection-item {
        margin-top: 3px;
      }

      .ant-select-selection-search {
        left: 0 !important;
        margin-top: 3px;
      }
    }

    &.ant-picker {
      border: none;
      padding: 0;
      box-shadow: none;
      margin-top: 12px;

      .ant-picker-range-separator {
        @include mobile {
          display: none;
        }
      }

      .ant-picker-input {
        input {
          //font-size: 18px;

          &::placeholder {
            color: $greyColor4;
            //font-size: 18px;
          }
        }
      }

      .ant-picker-active-bar {
        display: none;
      }
    }

    &-wrapper {
      position: relative;

      > div {
        width: 100%;
        overflow: hidden;
        position: relative;
      }

      &--error {
        .ant-select-selector {
          .ant-select-selection-placeholder {
            color: $errorColor;
          }
        }

        .ant-picker {
          color: $errorColor;

          .ant-picker-input {
            input {
              &::placeholder {
                color: $errorColor;
              }
            }
          }
        }
        > div {
          border-color: $errorColor;
        }
      }

      .rooms-guests__select {
        &-wrapper {
          //font-size: 18px;
          margin-top: 4px;

          .ant-select, .ant-select-selector {
            z-index: 1;
            background-color: transparent !important;
          }
        }

        &-value-wrapper {
          text-overflow: ellipsis;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          left: 0;
          z-index: 0;
        }
      }

      .location__select-wrapper {
        position: relative;
      }
    }

    &-icon {
      margin-right: 8px;
    }

    &-rooms {
      min-width: 135px;
    }

    &.ant-picker, .ant-picker-input {
      position: static;
    }

    .ant-picker-clear {
      font-size: 12px;
    }
  }

  &__label {
    display: inline-block;
    font-weight: bold;
    font-size: 14px;
  }

  &__label-wrapper {
    &--filters {
      &.ant-row {
        display: none;

        @include mobile {
          display: initial;
        }
      }
    }
  }

  &__dropdown {
    &-location {
      min-width: 400px !important;
    }

    &-rooms {
      min-width: 200px !important;
    }
  }

  &__buttons.ant-col {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding: 0 0 0 10px;

    @include mobile {
      align-items: flex-end;
    }

    .search-icon {
      height: 48px;

      @include mobile {
        // display: none;
      }

      &--loading {
        display: block;
      }
    }

    .button {
      border: none;
      padding: 0;
      background: transparent;
      border-radius: 50%;
      box-shadow: none;
      max-width: 80px;

      @include mobile {
        width: 100%;
        border-radius: 8px;
        background: $mainBlueColor;
        color: $whiteColor;
        padding: 7px 16px;
        font-weight: bold;
      }

      &--margin-left {
        @include mobile {
          margin-left: 24px;
        }
      }

      .mobile-label {
        display: none;

        @include mobile {
          display: initial;
        }
      }
    }
  }

  &__place {
    display: flex;
    align-items: center;
  }

  &__place-icon {
    margin-right: 10px;
  }

  &__place-title {
    font-weight: 600;
  }

  &__place-subtitle {
    font-size: 12px;
    color: $greyColor;
  }

  &__datepicker {
    &.ant-picker-dropdown {
      border-radius: 6px;

      .ant-picker-range-arrow {
        @include mobile {
          display: none;
        }
      }

      .ant-picker-panel-container {
        min-width: 590px;
        border-radius: 8px;

        @include mobile {
          min-width: auto;
        }

        .ant-picker-panels {
          width: 100%;

          @include mobile {
            display: flex;
            flex-direction: column;
          }

          .ant-picker-panel {
            width: 50%;

            @include mobile {
              width: 100%;
            }

            .ant-picker-date-panel {
              width: auto;
            }
          }
        }
      }

      .ant-picker-header-view {
        font-weight: 600;

        button {
          &:hover {
            color: black;
          }
        }
      }

      .ant-picker-content {
        border-spacing: 1px;
        width: 280px;

        .ant-picker-cell {
          min-width: 42px;
          &:before {
            height: 34px;
            border-radius: 6px;
          }

          &:not(.ant-picker-cell-in-view) {
            color: rgba(0, 0, 0, 0.5);
          }
        }

        .ant-picker-cell-inner {
          border: 2px solid white;
          border-radius: 6px !important;
          height: 34px;
          min-width: 42px;
          line-height: 32px;

          &:before {
            border-radius: 6px;
          }
        }

        .ant-picker-cell-selected {
          .ant-picker-cell-inner {
            color: $whiteColor;
            background: $mainBlueColor;
            border-radius: 6px !important;

            &:before {
              border-color: $mainBlueColor;
              border-radius: 6px;
            }
          }
        }

        .ant-picker-cell-today {
          .ant-picker-cell-inner {
            &:before {
              border-color: $mainBlueColor;
              border-radius: 6px;
            }
          }
        }
      }
    }
  }

  &__filters-container {
    &.ant-col {
      display: flex;
      align-items: center;

      @include mobile {
        margin-top: 6px;
      }
    }
  }

  &__filter-row {
    &.ant-row {
      height: 100%;
      align-items: center;

      @include mobile {
        height: initial;
      }
    }
  }

  &__filters {
    display: flex;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
    width: 100%;

    @include mobile {
      width: 100%;
      justify-content: space-between;
    }
  }

  &__filters-icon {
    margin-right: 10px;

    @include tablet {
      margin-left: 0;
    }
  }

  &__filters-label {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: normal;
  }

  &__filters-mobile-label {
    display: none;

    @include mobile {
      display: initial;
      font-size: 16px;
      font-weight: normal;
    }
  }
}

@include mobile {
  .listing-search {
    padding: 16px;
    height: auto !important;

    &__wrapper {
      height: auto;
    }
    > .ant-row > .ant-col:not(.listing-search__field-dates) {
      margin-bottom: 16px;
      padding: 0 !important;
      border: none !important;
    }
    .button {
      width: 100%;
    }
    &__dropdown-location {
      min-width: 235px !important;
    }
  }
  .listing-search:not(.listing-search__highlighted) {
    > .ant-row > .ant-col.listing-search__field-dates {
      margin-bottom: 16px;
      padding: 0 !important;
      border: none !important;
    }
  }



}
