.profile-details {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__form {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__title {
    color: $greyColor;
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 16px;
  }

  &__field {
    margin-bottom: 10px;
    font-size: 14px;

    &--first-name {
      margin-right: 14px;
    }

    &-tooltip {
      padding: 0 1px;
      color: $dangerColor;
    }

    &--error {
      .ant-select-selector {
        border-color: $errorBorderColor !important;

        &:focus {
          border-color: $errorBorderColor !important;
        }
      }
    }
  }

  &__label {
    margin-top: 12px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5);
  }

  &__input {
    height: 38px;

    &.ant-input {
      color: rgba(0, 0, 0, 0.87);
      font-family: "SF Pro Display", Calibri, Candara, Arial, sans-serif;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 18px;
    }

    &:focus.ant-input,
    &:focus.ant-input-password {
      border-color: $greyBorderColor;
      box-shadow: none;
    }

    &--error.ant-input,
    &--error.ant-input-password {
      border-color: $errorBorderColor;

      &:focus {
        border-color: $errorBorderColor;
      }
    }
  }

  &__error-container {
    display: flex;
    justify-content: space-between;
  }

  &__error {
    display: block;
    color: $errorColor;
    font-size: 12px;
  }

  &__date-picker {
    &.ant-picker {
      display: block;
    }

    &--error {
      &.ant-picker {
        border-color: $errorBorderColor;

        &:focus {
          border-color: $errorBorderColor;
        }
      }
    }
  }

  &__nationality-select {
    &.ant-select {
      display: block;
    }
  }

  &__submit {
    margin-top: 10px;
  }
}
