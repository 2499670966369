.feedback-list {
  &__filters {
    margin-bottom: 16px;
  }

  &__filter {
    margin-right: 16px;

    &.ant-select {
      margin-right: 16px;

      .ant-select-selector {
        border: 1px solid $greyBorderColor !important;
      }
    }

    &.ant-picker {
      border: 1px solid $greyBorderColor;
    }
  }

  &__search {
    .anticon {
      font-size: 16px;
    }
  }

  &__delete {
    cursor: pointer;
  }
}
