.resale-event-list {
  margin-top:50px;
}

.event-item {

    margin-bottom:30px;

    &__title {
        font-weight: 600;
        font-size: 20px;
    }
    &__dates {
        margin-left: 5px;
    }

    &__info {
      padding:10px;
      padding-right:20px;
      margin-bottom: 10px;
    }

    &__btn {
      display: flex;
      text-align: center;
      justify-content: center;
      height: 80px;
      margin-bottom:10px;
      align-items: center;
      border-radius: 8px;
      font-size: 16px;
      font-weight: bold;
      padding: 12px 19px;
      color: $whiteColor;
      background: $mainBlueColor;
      border-color: $mainBlueColor;

      @include mobile {
        height: 50px;
      }

      &:hover,
      &:focus {
        color: $whiteColor;
        background: $mainBlueColor;
        border-color: $mainBlueColor;
      }
    }
}
