.checkbox {
  &--primary {
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: $mainBlueColor;
      border-color: $mainBlueColor;
    }

    .ant-checkbox:hover {
      .ant-checkbox-inner {
        border-color: $mainBlueColor;
      }
    }

    .ant-checkbox-disabled {
      &.ant-checkbox-checked{
        .ant-checkbox-inner {
          &:after {
            border-color: $whiteColor;
          }
        }
      }
    }
  }

  &--primary.ant-checkbox-wrapper:hover {
    .ant-checkbox-inner {
      border-color: $mainBlueColor;
    }
  }
}
