.hotels-list {
  &__search-wrapper {
    display: flex !important;
    justify-content: flex-end;

    .ant-input-search {
      width: auto;
    }

    .button {
      margin-left: 16px;
      padding: 0 15px;
    }

    .button--primary {
      color: $whiteColor;
      background: $mainBlueColor;
      border-color: $mainBlueColor;

      &:hover,
      &:focus {
        background: $mainBlueColor;
        color: $whiteColor;
        border-color: $mainBlueColor;
      }
    }
  }
}
