.blog-single-article {
  &__container {
    &.ant-col {
      max-width: 680px;
    }
  }

  &__main-image-container {
    height: 450px;

    @include mobile {
      height: 225px;
    }
  }

  &__main-image, &__content-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &__content-image {
    border-radius: 16px;
  }

  &__body-content {
    overflow-wrap: break-word;

    img {
      width: 100%;
    }
  }

  &__section {
    padding-bottom: 16px;
  }

  &__main-title {
    font-size: 48px;
    margin: 0;
  }

  &__subtitle {
    font-size: 36px;
  }

  &__categories {
    margin: 0 -8px;
  }

  &__category {
    font-size: 16px;
    color: #F75497;
    margin: 0 8px;
  }

  &__info-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 -8px;
  }

  &__info-item {
    display: flex;
    margin: 8px 0;
  }

  &__info-item-block, &__info-item-link {
    margin: 0 8px;
  }

  &__info-item-block {
    padding: 4px 8px;
    border-radius: 8px;
    background-color: #F3F3F4;
  }

  &__social-img {
    height: 24px;
    width: 24px;
  }

  .reverse-row {
    flex-direction: row-reverse;
  }
}
