.profile-history {
  margin-top: 24px;

  &__title {
    text-transform: uppercase;
    color: $greyColor;
    font-size: 12px;
  }

  .ant-collapse {
    background: $whiteColor;

    .ant-collapse-item {
      border-bottom: none;
      .ant-collapse-header {
        padding: 5px 40px 5px 8px;
      }

      &.ant-collapse-item-active {
        .ant-collapse-header {
          border-bottom: 1px solid rgba(0,0,0,0.1);
        }
      }

      .ant-collapse-content {
        .ant-collapse-content-box {
          padding: 10px;
        }
      }
    }
  }

  &__list-item {
    background: $greyColor3;
    margin: 8px 0;
    border-radius: 4px;
    padding-left: 8px;
    color: $blackColor;
  }

  &__list-item-field {
    margin-bottom: 13px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__list-item-field-name {
    color: $greyColor;
    padding-right: 10px;
  }
}
