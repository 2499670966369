.tabs.ant-tabs-top {
    margin-top: 12px;
    .ant-tabs-nav {
      margin-bottom: 30px;
      &::before {
        border: none;
      } 
      .ant-tabs-nav-wrap {
        &::before, &::after {
          opacity: 0 !important;
        }
  
        .ant-tabs-nav-list {
          .ant-tabs-tab {
            background: none;
            border: none;
            padding: 6px 16px;
            background-color: $ghostWhite;
            border-radius: 32px;
            
            .ant-tabs-tab-btn { 
              color: $blackColor;
              font-weight: bold;
            }
          }
    
          .ant-tabs-tab-active {
            .ant-tabs-tab-btn {
              color: $whiteColor;

            }
          }
    
          .ant-tabs-ink-bar {
            height: 100%;
            z-index: -1;
            border-radius: 32px;
          }
        }
      }
    } 
  }