.home-page-blog {
  &__article-image {
    width: 100%;
    height: 300px;
    border-radius: 6px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    @include tablet {
      height: 200px;
    }

    @include mobile {
      height: 175px;
    }
  }

  &__article-title {
    color: $blackColor;
    font-size: 20px;
    font-weight: 500;
    margin-top: 16px;
    margin-bottom: 24px;
  }

  &__article-read {
    border: 1px solid $mainBlueColor;
    padding: 14px 60px;
    color: $mainBlueColor;
    font-weight: bold;
    font-size: 16px;
    border-radius: 8px;
  }
}
