.blog-article-card {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  overflow-wrap: break-word;
  color: inherit;


  &:hover {
    color: inherit;
  }

  &__image {
    border-radius: 16px;
    object-fit: cover;
    height: 167px;
  }

  &__title {
    font-size: 16px;
    line-height: 16px;
    font-weight: bold;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 32px;
  }

  &__date-container {
    margin-bottom: 8px;
  }

  &__date {
    color: #666777;
    font-size: 14px;
  }

  &__item {
    margin: 6px 0;
  }

  &__description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-size: 14px;
  }
}

.blog-card-wrapper.ant-col {

  @include desktop {
    flex: 0 0 20% ;
    max-width: 20% ;
  }
}
