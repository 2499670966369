.balance {
  color: $greyColor;
  font-size: 12px;

  &__heading {
    margin-bottom: 15px;
  }

  &__title {
    text-transform: uppercase;
  }

  &__kyc-popover-content {
    max-width: 300px;

    @include mobile {
      max-width: 200px;
    }
  }

  &__kyc-status {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-weight: bold;
  }

  &__kyc-status-text {
    &--failed {
      color: #CB3E2A;
    }

    &--verified {
      color: #00A021;
    }

    &--pending {
      color: #F08505;
    }
  }

  &__info-icon {
    margin-right: 4px;
  }

  &__funds-container {
    margin-bottom: 15px;
    font-size: 14px;
  }

  &__funds {
    font-size: 18px;
    font-weight: bold;
    color: $blackColor;
  }

  &__hint {
    margin-bottom: 15px;
  }

  &__hint-first-line {
    margin-bottom: 5px;
  }
}
