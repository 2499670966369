.modal-actions {
  margin-top: 48px;
  display: flex;

  &--left {
    justify-content: flex-start;
  }

  &--center {
    justify-content: center;
  }

  &--right {
    justify-content: flex-end;
  }
}

.modal-action {
  margin: 0 16px;

  @include mobile {
    margin: 16px 0;
  }
}
