.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  &__title {
    font-size: 32px;
    line-height: 32px;
    font-weight: bold;
    margin: 0;
    flex: 1;

    @include mobile {
      font-size: 24px;
    }
  }

  &__close-button {
    &.ant-btn {
      padding: 0;
      width: 32px;
      height: 32px;
      margin-left: 16px;
      min-width: 0;
    }
  }

  &__close-icon {
    position: relative;
    top: -2px;
    margin: 10px;
  }
}
