.subscribe-form {
  display: flex;
  max-width: 350px;

  @include tablet {
    padding-right: 24px;
  }

  @include mobile {
    padding-right: 0;
    margin: 16px 0;
    justify-content: center;
    max-width: 100%;
  }

  &__icon {
    position: absolute;
    top: 13px;
    left: 16px;
    z-index: 10;
    width: 18px;
  }

  &__input-container {
    position: relative;
  }

  &__input {
    &.ant-input {
      padding: 12px 24px;
      border-radius: 8px 0 0 8px;
      border-right: none;

      @include mobile {
        padding: 10px;
      }
    }

    &:focus.ant-input {
      border-color: $greyBorderColor;
      box-shadow: none;
    }

    &--error.ant-input {
      border-color: $errorBorderColor;

      &:focus {
        border-color: $errorBorderColor;
      }
    }

    &--with-icon {
      &.ant-input {
        padding-left: 44px !important;
      }
    }
  }

  &__button {
    &.ant-btn,
    &.ant-btn:disabled {
      display: flex;
      align-items: center;
      border-radius: 0 8px 8px 0;
      font-size: 16px;
      font-weight: bold;
      padding: 12px 19px;
      color: $whiteColor;
      background: $mainBlueColor;
      border-color: $mainBlueColor;

      @include mobile {
        padding: 10px;
      }

      &:hover,
      &:focus {
        color: $whiteColor;
        background: $mainBlueColor;
        border-color: $mainBlueColor;
      }
    }
  }

  &__error {
    display: block;
    color: $errorColor;
    font-size: 12px;
    text-align: left;
    position: absolute;
    bottom: -18px;
  }

  &__success-icon {
    margin-right: 5px;
  }
}
