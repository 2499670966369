.listings-search-filters {
  @include mobile {
    padding-bottom: 12px;
  }

  .modal-header {
    margin-bottom: 0;
  }

  &__section-title {
    font-weight: bold;
    font-size: 22px;
    margin: 24px 0 12px;

    @include mobile {
      font-size: 18px;
    }

    &--rating {
      margin-bottom: 8px;
    }
  }

  &__filter-row {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 8px;

    @include mobile {
      justify-content: space-between;
      max-width: 250px;
    }

    &--rating {
      align-items: center;
      margin-bottom: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .ant-rate-star > div:hover,
    .ant-rate-star > div:focus {
      transform: none;
    }

    .ant-rate {
      width: 65%;

      @media screen and (max-width: $tabletMax) {
        width: 100%;
      }
    }
  }

  &__filter-label {
    display: flex;
    align-items: center;
    font-size: 16px;
    width: 65%;
    padding-right: 8px;

    @media screen and (max-width: $tabletMax) {
      font-size: 16px;
      width: 100%;
    }
  }

  &__filter-icon {
    width: 24px;
    max-height: 24px;
    margin-right: 16px;

    &--single-room {
      max-height: 18px;
    }
  }

  &__show-container {
    text-align: center;
    padding-top: 16px;
  }

  &__show {
    color: $pinkColor;
    cursor: pointer;
    font-size: 14px;
    text-decoration: underline;
  }

  &__buttons {
    &.ant-col {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 48px;
    }

    .ant-btn {
      width: 100%;
      padding: 8px 16px;
      white-space: nowrap;

      @include mobileES {
        height: auto;
        white-space: normal;
      }
    }
  }

  &__clear {
    &.ant-btn {
      border: none;
      background: none;
      color: $mainBlueColor;
      box-shadow: none;

      &:hover,
      &:focus,
      &:active {
        border: none;
        background: none;
        color: $mainBlueColor;
        box-shadow: none;
      }
    }
  }
}
