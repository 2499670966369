.admin-financial-accounting {
  .ant-table-content {
    overflow-y: auto;

    .ant-table-tbody {
      .ant-table-cell {
        &:first-child {
          font-weight: normal;
        }
      }
    }
  }

  &__filters {
    .ant-select {
      margin-bottom: 10px;
    }
  }

  &__date-range-picker {
    max-width: 210px;

    &.ant-picker-range {
      margin-right: 16px;
    }
  }

  &__date-type {
    &.ant-switch {
      margin-right: 16px;
    }
  }

  &__download-csv {
    &.ant-col {
      padding-left: 8px;
      text-align: right;

      .ant-btn {
        padding: 8px 15px;
      }
    }
  }
}
