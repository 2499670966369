.legal-page {
  @include mobile {
    padding: 0 16px;
  }

  &__sidebar {
    @media screen and (max-width: $desktopMin) {
      display: none;
    }

    .ant-menu {
      &.ant-menu-vertical-left {
        .ant-menu-item {
          height: auto;
          line-height: initial;
          margin: 0 0 20px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  &__title {
    font-size: 42px;
    line-height: 42px;
    font-weight: bold;
    margin-bottom: 10px;

    @include mobile {
      font-size: 30px;
      line-height: 30px;
    }
  }

  &__updated {
    color: $greyColor2;
    margin-bottom: 40px;
  }

  &__mobile-menu {
    &.ant-row {
      display: none;

      @media screen and (max-width: $desktopMin) {
        display: flex;
        margin-bottom: 20px;
      }
    }

    .ant-menu {
      @include mobile {
        line-height: 30px;
      }

      .ant-menu-item {
        @include mobile {
          line-height: 30px;
          padding: 0 5px;
          margin-right: 8px !important;
        }
      }
    }
  }

  &__content {
    margin-bottom: 32px;

    @include mobile {
      margin-bottom: 16px;
    }
  }
}
