.listing {
  &-content {
    position: relative;
    padding-bottom: 150px;
    min-height: 100vh;
  }

  &__create-title {
    margin-top: 40px;
    font-size: 24px;
    font-weight: 600;
  }

  &__create-error {
    display: block;
    color: $errorColor;
    font-size: 12px;
    margin-top: 15px;
  }

  &__preview-save-as-draft-button {
    margin-left: 15px;
  }

  &__subtitle {
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 16px;
  }

  &__hotel-description {
    word-break: break-word;
  }

  &__container {
    .profile__form {
      padding: 0 0 32px;

      &__submit-btn {
        margin-top: 32px;
      }
    }

    .blog-article__back {
      margin-bottom: 32px;
    }

    .form-section__field {
      margin-bottom: 16px;

      .above-label {
        position: absolute;
        margin-top:-20px;
        font-size: 13px;
        font-family: 'SF Pro Display', sans-serif;
        font-variant: tabular-nums;

        @media screen and (max-width: 575px) {
          position: relative;
          margin-top: 10px;
        }
      }

      .ant-input-number-disabled {
        color: rgba(0, 0, 0, 0.5);
      }
    }

    .form-section__input {
      &.ant-input {
        &:disabled {
          color: rgba(0, 0, 0, 0.5);
        }
      }
    }

    .form-section__input.ant-input-number {
      width: 100%;
    }

    .ant-checkbox-group {
      display: flex;
      flex-wrap: wrap;

      .ant-checkbox-wrapper,
      .ant-checkbox-group-item {
        width: 33.33%;
        margin: 10px 0 0;

        @include mobile {
          width: 100%;
        }
      }
    }

    .file-upload {
      &__container .ant-upload {
        min-height: auto;

        .button {
          margin-bottom: 10px;
        }

        &.ant-upload-drag .ant-upload {
          padding: 16px 32px;
        }

        ul {
          margin-bottom: 0;
        }
      }

      &__file {
        padding: 14px 24px;
        background-color: #F2F2F2;

        &-types {
          font-size: 12px;
        }
      }
    }

    .sign-up__checkbox-container {
      margin: 24px 0 16px;
      padding: 0 32px;

      .ant-checkbox-wrapper {
        .ant-checkbox {
          .ant-checkbox-inner {
            border-color: $blackColor;
          }
        }
      }
    }
  }

  &__checkboxes-container {
    padding-top: 16px;

    .ant-radio-inner {
      border-color: $blackColor;
    }

    @include mobile {
      padding: 32px 0 0;
    }

    &--form {
      padding: 0 32px;
    }
  }

  &__red-text-container {
    color: $errorColor;
  }

  &__field {
    &-hotel {
      .anticon.anticon-down {
        display: none;
      }
    }

    &-facilities {
      display: flex;
      flex-wrap: wrap;

      &-item {
        width: 50%;
        margin-top: 8px;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;

        img {
          margin: 0 14px 4px 6px;
        }
      }
    }

    &-rating {
      label {
        display: block;
      }
    }

    &-radio {
      margin: 16px 0 24px !important;

      .ant-radio {
        &-wrapper {
          display: block;
        }

        &-inner {
          background-color: transparent;
        }
      }

      &--error {
        .ant-radio-wrapper > span:last-child {
          color: $errorColor;
        }

        .ant-radio-inner {
          border: 2px solid $errorColor;
        }
      }
    }

    &-is-paid {
      margin: 10px 0 !important;
      font-weight: 600;

      .ant-checkbox {
        background-color: $whiteColor;

        &-wrapper {
          display: block;
          margin-left: 0 !important;

          &:hover,
          &:focus,
          &:active {
            .ant-checkbox-inner {
              border-color: $blackColor;
            }
          }
        }

        &-inner {
          background-color: transparent;
          border-color: $blackColor;
        }
      }

      .ant-checkbox-checked {
        .ant-checkbox-inner {
          background-color: $blueCheckboxColor;
          border-color: $blueCheckboxColor;
        }
      }

      .ant-checkbox-wrapper-checked {
        &:hover,
        &:focus,
        &:active {
          .ant-checkbox-inner {
            border-color: $blueCheckboxColor;
          }
        }
      }

      &--error {
        .ant-checkbox-wrapper > span:last-child {
          color: $errorColor;
        }

        .ant-checkbox-inner {
          border: 2px solid $errorColor;
        }
      }
    }

    &-room-type {
      &--error {
        .form-section__label {
          color: $errorColor;
        }

        .ant-checkbox-wrapper > span:last-child {
          color: $errorColor;
        }

        .ant-checkbox-inner {
          border: 1px solid $errorColor;
        }

        .profile__error {
          margin-top: 10px;
        }
      }
    }
  }

  &__price-label {
    margin-top: 24px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5);

    @media screen and (max-width: 992px) {
      margin-bottom: 24px;
    }
  }

  &__commissions-label {
    font-size: 14px;
    font-weight: bold;
  }

  &__commissions-receive-item {
    font-size: 16px;
    font-weight: bold;
    color: $mainBlueColor;
    border-top: 1px solid $greyColor4;

    &--saving {
      color: $pinkColor;
    }
  }

  &__commissions {
    font-size: 14px;

    &-item {
      &.ant-row {
        margin-bottom: 16px;
      }
    }

    &-money {
      text-align: right;
    }
  }

  &__preview-container {
    margin-left: 32px;

    @include mobile {
      margin-left: 0;
    }
  }

  &__provider {
    position: relative;
    top: 4px;
    margin-bottom: 16px;
  }

  &__not {
    font-weight: bold;
    text-transform: uppercase;
  }

  &__room-type-title {
    font-weight: 600;
    font-size: 14px;
  }

  &__input-field-other {
    margin-top:6px !important;
  }

  &__checkbox--disabled {
    &.ant-checkbox-wrapper-disabled {
      span {
        color: rgba(0, 0, 0, 0.5)
      }
    }
  }

  &__checkbox-group--disabled {
    .ant-checkbox-wrapper-disabled {
      span {
        color: rgba(0, 0, 0, 0.5)
      }
    }
  }

  &__rating-container {
    &.ant-row {
      justify-content: center;

      @include mobile {
        justify-content: flex-start;
      }
    }
  }
}

@include mobile {
  .listing {
    &__create-title {
      margin-top: 16px;
    }

    &__container {
      .button-preview {
        width: 100%;
      }

      .ant-checkbox-group .ant-checkbox-group-item {
        width: 49.99%;
      }

      .sign-up__checkbox-container {
        padding: 0 32px;
      }
    }

    &__field-facilities-item {
      width: 100%;
    }

    &__field-additional-info {
      .ant-checkbox-group {
        .ant-checkbox-group-item {
          width: 100%;
        }
      }
    }

    &__preview-save-button {
      &.button {
        &.ant-btn {
          padding: 5px;
        }
      }
    }

    &__preview-save-as-draft-button {
      &.button {
        &.ant-btn {
          padding: 5px;
          margin-left: 8px;
        }
      }
    }
  }
}

.rooms-guests__select {
  padding: 18px 24px;

  > div {
    display: flex;
    justify-content: space-between;

    .form-section__label {
      font-size: 14px;
      line-height: 18px;
      margin-right: 10px;
    }
  }

  .select-row {
    margin-bottom: 14px;

    &--no-margin {
      margin-bottom: 0;
    }
  }

  .ant-input-number {
    border: none;
    background-color: transparent;
    width: 80px;

    &-handler {
      border: 2px solid #E0E0E0;
      width: 20px;
      height: 20px !important;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      color: #E0E0E0;

      &-up:hover, &-down:hover {
        height: 20px !important;
      }

      &-up-inner, &-down-inner {
        display: none !important;
      }

      &-wrap {
        opacity: 1;
        border: none;
        background-color: transparent;
        width: 100%;
      }

      &-up {
        right: 2px;

        &:after {
          content: "+";
        }
      }

      &-down {
        left: 2px;

        &:after {
          content: "-";
        }
      }

      &:hover {
        border-color: $mainYellowColor;
        color: $mainYellowColor;
      }
    }

    &-input {
      padding: 0 20px;
      text-align: center;
    }

    &-focused {
      box-shadow: none;
    }
  }

  &-wrapper {
    position: relative;
  }

  &-placeholder {
    opacity: 0.4;
  }

  &-value {
    &-wrapper {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
