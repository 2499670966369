.listing-offer-price-modal {
  text-align: left !important;
  padding: 0 5px !important;

  &__container {
    width: 100%;
  }

  .form-section__input {
    width: 100%;
  }

  .modal__buttons {
    .button {
      &.ant-btn {
        padding: 7px 15px;
        margin: 8px 0 0;
      }
    }
  }
}
