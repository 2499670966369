.listing-create-form {
  &__tooltip-label {
    width: 100%;
  }

  &__tooltip-label-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__count-input-wrapper {
    display: flex;
    align-items: center;
    height: 45px;

    .count-input {
      width: 100%;
    }
  }

  &__agree-checkbox-container {
    display: flex;
    justify-content: center;
    padding: 40px;
  }

  &__preview {
    border-radius: 2px 2px 20px 20px;
    padding: 40px;
    border: 1px solid #F3F3F4;
    text-align: center;
    margin-top: 40px;
  }

  &__preview-default {
    background-color: $whiteColor;
  }

  &__preview-error {
    background-color: #fff2f2;
  }

  &__preview-button.ant-btn {
    border-radius: 8px;
    height: 50px;
  }

  &__preview-button.ant-btn[disabled],
  &__preview-button.ant-btn[disabled]:hover {
    background-color: $greyColor4;
    color: $whiteColor;
  }

  &__count-items-row {
    margin: 0 -16px;
  }

  &__count-item {
    padding: 0 16px;
    text-align: center;
  }

  &__child-count-items-row {
    margin: -8px -16px;
  }

  &__child-count-item {
    text-align: center;
    padding: 8px 16px;
  }

  &__hotel-not-found {
    text-align: right;
    display: block;
  }

  @media screen and (max-width: 1000px) {
    &__count-items-row {
      margin: 0 -8px;
    }

    &__count-item {
      padding: 0 8px;
    }

    &__child-count-items-row {
      margin: -8px -8px;
    }

    &__child-count-item {
      padding: 8px 8px;
    }
  }
}

.form-file-upload {
  &__container.ant-upload.ant-upload-drag {
    border: 1px solid #F3F3F4;
    border-radius: 20px;
    padding: 40px;
    background-color: #FBFBFE;
    display: flex;
    justify-content: center;
  }

  &__title-wrapper {
    margin-bottom: 32px;
  }

  &__title, &__subtext {
    font-weight: bold;
  }

  &__title {
    font-size: 18px;
    margin: 0;
  }

  &__subtext {
    font-size: 14px;
  }

  &__file-types {
    margin-top: 16px;
    color: $greyColor4;
  }
}


