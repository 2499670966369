.admin-question {
  &__title {
    text-transform: uppercase;
    margin-bottom: 15px;
    color: $greyColor;
    font-size: 12px;
  }

  &__row {
    margin-top: 24px;
  }

  .ql-editor {
    height: 600px;
  }

  &__submit {
    margin: 24px 32px 32px;

    .button {
      padding: 7px 40px;
      margin-right: 16px;
    }
  }

  &__error {
    display: block;
    color: $errorColor;
    font-size: 12px;
  }

  &__category {
    margin-top: 15px;
  }
}
