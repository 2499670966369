.page-not-found {
  text-align: center;
  min-height: calc(100vh - 215px);

  @include mobile {
    min-height: calc(100vh - 315px);
  }

  &__title {
    font-size: 140px;
  }

  &__text {
    font-size: 16px;
    opacity: 0.8;
  }

  &__link {
    text-transform: uppercase;
    font-weight: 600;
    margin: 40px 0 32px;
  }
}
