.user-wallet {
  &__info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }

  &__info-icon {
    margin-right: 24px;
    height: 24px;
    width: 24px;
  }

  &__info-text {
    text-align: justify;
    font-size: 14px;
    line-height: 18px;
    color: $greyColor2;
  }

  &__pending {
    color: $greyColor;
    font-size: 12px;
  }

  &__pending-title {
    text-transform: uppercase;
  }

  &__pending-label {
    font-size: 14px;
  }

  &__pending-money {
    text-align: right;
    font-size: 18px;
    font-weight: bold;
    color: $blackColor;
  }

  &__withdraw {
    &.ant-btn {
      padding: 7px 40px;
    }
  }

  &__withdraw {
    &.ant-btn {
      padding: 7px 40px;
    }
  }

  &__withdraw-bold {
    font-weight: bold;
  }

  &__know-your-customer-wrapper {
    margin-top: 42px;
  }

  &__success-send-kyc {
    display: inline-block;
    margin-top: 42px;
    color: $successColor;
  }
}

.ant-modal {
  .user-wallet__withdraw-modal {
    .modal__icon {
      width: 80px;
      height: 90px;
    }
  }
}
