.form-section {
  background-color: $greyColor3;
  padding: 16px 32px;
  margin: 8px 0;

  &__title {
    text-transform: uppercase;
    margin-bottom: 15px;
    color: grey;
    font-size: 12px;
  }
  &__field {
    width: 100%;
    margin-bottom: 24px;

    &--error {
      label {
        color: $errorColor;
      }
      .ant-input, .ant-input:focus, .ant-input:hover, .ant-input-number, .ant-input-number:focus, .ant-input-number:hover, .ant-select-selector, .ant-picker,
      .ant-input-password, .ant-input-password:focus, .ant-input-password:hover {
        border-color: $errorColor !important;
      }
    }
    .ant-input-number {
      display: block;
    }
    &.placeholder-image {
      .form-section {
        &__input-wrapper {
          display: block;
          position: relative;

          .ant-select-selector {
            padding-left: 40px;
          }
          input {
            padding-left: 28px;
          }
        }
        &__image {
          position: absolute;
          left: 9px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
  &__input--error {
    border-color: $errorBorderColor !important;
  }
  &__label {
    margin-top: 12px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5);

    &-tooltip {
      display: flex;
      justify-content: space-between;
      margin: 2px 0;

      &-image {
        width: 15px;
        height: 15px;
      }
      &-text {
        font-size: 12px !important;
      }
    }
  }
  &__select.ant-select {
    display: block;
  }
  &__separator {
    padding: 14px 0;

    > .ant-col {
      padding-left: 18px;

      &:not(:first-child) {
        border-left: 1px solid #D8D8D8;
      }
    }
    &-label {
      font-size: 14px;
      line-height: 18px;
      color: $greyColor2;
    }
    &-value {
      font-size: 24px;
      line-height: 31px;
      color: #000000;
    }
  }

  &__range-piker {
    width: 100%;

    .ant-picker-range-separator {
      opacity: 0.5;
    }

    input {
      &:disabled {
        color: rgba(0, 0, 0, 0.5) !important;
      }
    }
  }

  &__error {
    display: block;
    color: $errorColor;
    font-size: 12px;
  }
}
