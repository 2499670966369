.faq-accordion {
  &__content {
    color: #666777;
    font-size: 16px;
    margin: 0;

    img {
      width: 100%;
    }
  }

  &__arrow-icon-wrapper {
    transform: translateY(-50%);
    margin-right: 8px;
    top: calc(50% - 5px) !important;
  }

  &__arrow-icon--open {
    transform: rotate(180deg);
  }

  &.ant-collapse {
    background-color: $whiteColor;
    border: none;
    border-radius: 8px;

    .ant-collapse-content, .ant-collapse-item {
      border: none;
    }

    .ant-collapse-item.ant-collapse-item-active,
    .ant-collapse-content.ant-collapse-content-active {
      border-radius: 8px;
    }

    .ant-collapse-item-active {
      border: 1px solid $mainBlueColor;
      margin: -1px;
    }

    .ant-collapse-item {
      .ant-collapse-header {
        padding: 16px 52px 16px 24px;
        font-size: 20px;
        overflow-wrap: break-word;
        word-wrap: break-word;
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;
      }

      .ant-collapse-content-box {
        padding: 0 36px 16px 24px;
      }
    }

  }
}
