.bank-account {
  &__priority-item, &__priority-button {
    background-color: #F3F3F4;
    border-radius: 4px;
    color: $successColor;
    padding: 4px 12px;
    text-transform: capitalize;
    white-space: nowrap;
  }

  &__wrapper {
    margin-bottom: 40px;
  }

  &__remove-button.ant-btn {
    font-weight: bold;
  }

  &__priority-button {
    border: none;
    cursor: pointer;
  }

  &__priority-button:focus {
    outline: 0;
  }

  &__modal-button {
    margin: 0 16px;
  }

  &__add-account-section {
    display: flex;
    justify-content: flex-end;

    @media screen and (max-width: 576px) {
      justify-content: center;
    }
  }

  @include mobile {
    padding: 0 16px;
  }

  &__empty-container {
    text-align: center;
  }

  &__empty {
    &.profile-section {
      padding: 55px;
    }
  }

  &__empty-icon {
    height: 48px;
    width: 48px;
  }

  &__empty-text {
    margin-top: 30px;
    font-size: 16px;
  }

  &__add-button {
    margin: 30px 0;
  }

  &__add-other-container {
    &.profile-section {
      margin: 12px 0 40px;
      text-align: center;
      padding: 35px;
    }
  }

  &__add-other-text {
    font-size: 16px;
    color: $greyColor;
  }

  .bank-account-item {
    position: relative;
    background-color: $greyColor3;
    padding: 16px 32px;
    margin: 20px 0;

    @include mobile {
      padding: 16px;
    }

    &:first-child {
      margin-top: 8px;
    }

    &__label {
      text-transform: uppercase;
      font-size: 12px;
      margin-bottom: 5px;
      color: $greyColor;
    }

    &__value {
      font-size: 16px;
      font-weight: 600;
      color: #424242;
      margin-bottom: 20px;
    }

    &__priority {
      color: $mainBlueColor;
      text-decoration: underline;
      cursor: pointer;
    }

    &--primary {
      &.bank-account-item {
        border-left: 8px solid $mainBlueColor;
      }

      .bank-account-item__priority {
        color: #1BCA6F;
        text-decoration: none;
        text-transform: capitalize;
      }
    }

    &__delete {
      position: absolute;
      top: 25px;
      right: 30px;
      cursor: pointer;

      @include mobile {
        top: 20px;
        right: 20px;
      }
    }
  }

  &__modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 40px;
    text-align: center;

    @include mobile {
      padding: 0;
    }
  }

  &__modal-icon {
    margin-bottom: 40px;
    height: 54px;
    width: 54px;
  }

  &__modal-text {
    padding: 0 50px;
    margin-bottom: 30px;
    font-size: 18px;

    @include mobile {
      padding: 0;
    }
  }

  &__modal-buttons {
    .button {
      margin: 15px;
      padding: 7px 50px;

      @include mobile {
        padding: 7px 30px;
        margin: 0 8px;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &__modal-error {
    color: $errorColor;
    font-size: 12px;
  }
}
