.count-input {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__count-value {
    font-size: 32px;
    line-height: 32px;
    font-weight: bold;
  }

  &__item {
    display: flex;
    justify-content: center;
    &.left {
      flex: 1;
      margin-left:auto;
      margin-right:10px;
    }
    &.right {
      flex: 1;
      margin-left:10px;
      margin-right:auto;
    }
  }

  &__central-item {
    flex: 1;
  }
}

