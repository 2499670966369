.seller-account-sidebar, .admin-sidebar {
  margin-top: -8px;

  .menu {
    &.ant-menu {
      border-right: none;
      line-height: 25px;
    }
    @include mobile {
      display: none;
    }
  }
  .menu-mobile {
    display: none;

    @include mobile {
      display: block;
      padding: 0 16px;
    }
  }
}
