.blog__container {
  &__section {
    margin: 32px 0;
  }

  .blog {
    &__container {
      color: #020202;
    }

    &__header {
      h2 {
        margin: 0;
        font-size: 42px;
        line-height: 52px;
        font-weight: bold;
      }

      p {
        max-width: 450px;
        font-size: 16px;
        margin: 0 0 0 auto;
        opacity: 0.8;

        @include mobile {
          margin: 0 auto;
        }
      }
    }

    &__menu {
      @include mobile {
        padding: 0;
      }
    }

    &-articles {
      &__container {
        display: flex;
        flex-wrap: wrap;
      }

      &__item {
        padding: 16px;

        @include mobile {
          width: 100%;
        }

        .blog-article {
          &__categories {
            margin: 14px 0 8px;
            text-align: left;
          }

          &__title {
            font-size: 18px;
            line-height: 23px;
            text-align: left;
          }

          &__date {
            margin-bottom: 0;
            text-align: left;
          }
        }
      }

      &__pagination {
        padding: 32px 16px;
        text-align: center;
      }
    }
  }
}

.blog-article {
  &__container {
    margin-top: -42px;
  }

  &__related-articles {
    &.ant-row {
      margin-top: 32px;

      @include mobile {
        padding: 0 16px;
      }
    }
  }

  &__back {
    height: 48px;
    padding: 0 20px;
    background-color: #F7FAFF;

    .back-link {
      cursor: pointer;
    }
  }

  &__image {
    img {
      width: 100%;
    }

    .hover-text {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.25);
      display: none;
      justify-content: center;
      align-items: center;

      .button {
        width: 138px;
        height: 52px;
        padding: 15px;
        font-size: 16px;
        line-height: 20px;
        border-radius: 1px;
      }
    }

    &:hover {
      .hover-text {
        display: flex;
      }
    }
  }

  &__categories {
    margin: 32px 0 16px;
    text-align: center;

    span {
      margin-right: 4px;
      padding: 0 8px;
      background-color: $mainYellowColor;
      border-radius: 4px;
    }
  }

  &__title {
    margin-bottom: 4px;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    font-weight: 600;
    word-break: break-word;
  }

  &__date {
    color: rgba(0, 0, 0, 0.5);
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 40px;
    text-align: center;
  }

  &__body.ql-editor {
    margin: 32px 0;

    p, ol, ul {
      margin-bottom: 16px;
      padding: 0;
      opacity: 0.8;
      color: #020202;
      font-size: 16px;
      line-height: 24px;
    }

    a, a:active, a:link, a:visited {
      color: $mainBlueColor;
      opacity: 0.8;
    }

    li:before {
      margin-right: 0.8em;
    }

    img {
      max-width: 100%;
    }

    .ql-size-large, .ql-size-huge {
      font-weight: 600;
    }
  }

  &__social-share {
    margin-bottom: 32px;
    text-align: center;
  }
}

.blog {
  &__section-title {
    &.ant-typography {
      font-size: 42px;
      line-height: 42px;
      margin-bottom: 26px;

      @include mobile {
        font-size: 32px;
        margin-bottom: 16px;
      }
    }

    &__without-margin {
      &.ant-typography {
        margin-bottom: 0;

        @include mobile {
          margin-bottom: 0;
        }
      }
    }
  }

  &__section-subtitle {
    &.ant-typography {
      line-height: 28px;
      font-weight: normal;
      margin-bottom: 52px;

      @include mobile {
        font-size: 20px;
      }

      @media screen and (min-width: $laptopMin) {
        width: 50%;
      }
    }
  }

  &__updates {
    padding-top: 36px;
  }

  &__sections {
    padding-top: 52px;
  }
}
