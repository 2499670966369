.faq-preview {
  .questions {
    &__title {
      text-transform: uppercase;
      color: $greyColor;
      font-size: 12px;
    }

    .ant-collapse {
      background: $whiteColor;

      .ant-collapse-item {
        border-bottom: none;

        .ant-collapse-header {
          padding: 5px 40px 5px 16px;
          font-size: 18px;
          font-weight: 600;
        }

        .ant-collapse-content {
          .ant-collapse-content-box {
            padding: 16px;
            font-size: 16px;
            color: $greyColor2
          }
        }
      }
    }

    &__list-item {
      background: $greyColor3;
      margin: 8px 0 13px;
      border-radius: 4px;
      padding-left: 8px;
      color: $blackColor;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__category {
      font-weight: 600;
      font-size: 22px;
      margin-bottom: 16px;
    }

    &__container {
      margin-bottom: 32px;
    }
  }
}
