.listing-details {
  font-size: 14px;

  &__title {
    font-size: 28px;
    line-height: 28px;
  }

  &__row {
    &.ant-row {
      margin-bottom: 16px;
    }

    &:last-child {
      &.ant-row {
        margin-bottom: 8px;
      }
    }
  }

  &__label {
    color: initial;
    font-weight: bold;
  }

  &__date {
    border: 1px solid $greyColor5;
    border-radius: 8px;
    padding: 10px 15px;
    color: $greyColor4;
    background-color: #FCFCFC;

    &-content {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      img {
        width: 14px;
        margin-right: 8px;
      }
    }
  }

  &__original-price-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__original-price {
    img {
      width: 20px;
      margin-right: 8px;
    }
  }

  &__discount {
    border-radius: 4px;
    background: #F75497;
    color: $whiteColor;
    padding: 4px 8px;
  }

  &__action-button {
    width: 100%;
    margin-bottom: 8px;

    &.ant-btn-primary[disabled] {
      background-color: $mainBlueColor;
      border-color: $mainBlueColor;
      color: $whiteColor;

      &:hover,
      &:focus,
      &:active {
        background-color: $mainBlueColor;
        border-color: $mainBlueColor;
        color: $whiteColor;
      }
    }

    @include mobile {
      margin-bottom: 16px;
    }
  }

  &__cancellation {
    color: $pinkColor;
  }

  &__free-cancellation {
    font-size: 16px;
    font-weight: bold;

    &.ant-col {
      display: flex;
      align-items: center;

      img {
        margin-right: 8px;
      }
    }
  }

  &__deposit-required {
    text-transform: uppercase;
    font-weight: bold;
  }

  &__expires-in-container {
    &.ant-row {
      background-color: $pinkColor;
      border-radius: 8px;
      color: $whiteColor;
      padding: 8px 16px !important;
      margin-bottom: 16px;

      .ant-btn {
        margin-bottom: 0;
      }
    }
  }

  &__expires-in {
    margin-bottom: 8px;
    font-size: 16px;
  }

  &__offer-price-container {
    display: flex;
    justify-content: center;
  }

  &__offer-price {
    &.ant-btn {
      padding: 4px 8px;
      height: auto;
      border-radius: 8px;
    }
  }
}
