.know-your-customer {
  font-size: 12px;

  &__profile {
    margin-bottom: 32px;
  }

  &__file-container {
    margin-top: 16px;
  }

  &__submit-button {
    margin-top: 16px;
  }

  &__title {
    text-transform: uppercase;
    margin-bottom: 15px;
  }

  &__form {
    position: relative;

    .file-upload__container {
      padding-right: 32px;

      @include mobile {
        padding-right: 0;
      }
    }

    .file-upload__container--error {
      .ant-upload {
        border-color: $errorColor;
      }
    }

    .file-upload__error {
      color: $errorColor;
    }
  }

  &__document-type {
    @include mobile {
      margin: 20px 0;
    }
  }

  &__file-types {
    margin-top: 15px;

    &--error {
      color: $errorColor;
    }
  }

  &__document-hint {
    padding: 0 16px;
    margin: 16px 0;
    color: $greyColor2;
  }
}
