.hotel-card-guests {
  padding: 0;
  
  li {
    display: inline;
    margin: 0 8px;


    &:first-child {
      margin-left: 0;
    }
  }
}
