.cancel-booking-modal {
  padding: 10px 0 0 !important;
  align-items: unset !important;

  @include mobile {
    padding: 0 !important;
  }

  &__content {
    text-align: left;
    margin-bottom: 0 !important;
  }

  &__title {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 16px;
  }

  &__commissions {
    padding: 16px;
    background-color: $greyColor3;
    font-size: 14px;
    margin-bottom: 16px;

    @include mobile {
      padding: 16px 8px;
    }

    .form-section__title {
      margin-bottom: 0;
    }

    &-money {
      text-align: right;
    }

    &-total {
      padding: 10px 0 0;
      border-top: 1px solid #D8D8D8;
      font-weight: 600;
      font-size: 18px;
    }

    .ant-row{
      margin: 8px 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    @include mobile {
      margin-bottom: 32px;
    }
  }

  &__label {
    display: inline-block;
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 16px !important;
  }

  .modal__buttons {
    .ant-row {
      width: 100%;
    }

    .ant-col {
      .button {
        margin: 0;

        @include mobile {
          width: 100%;
          margin: 0 0 10px;
        }
      }
    }
  }
}
