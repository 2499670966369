.listing-view {
  @extend .container-large;
  padding: 0 16px;

  &__hotel {
    &-images {
      @include mobile {
        margin-bottom: 16px;
      }

      .ant-carousel {
        width: 100%;
        max-width: 1000px;
        border-radius: 16px;
        overflow: hidden;

        .slick-arrow, .slick-arrow:hover, .slick-arrow:focus {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          background-color: rgba(255, 255, 255, 0.6);
          margin: 0;
          transform: translateY(-50%);
          z-index: 10;

          img, svg {
            width: 100%;
            height: 100%;
          }

          @include mobile {
            width: 32px;
            height: 32px;
          }
        }

        .slick-prev {
          left: 25px;
          transform: rotate(180deg) translateY(50%);

          @include mobile {
            left: 10px;
          }

          &:hover {
            transform: rotate(180deg) translateY(50%);
          }
        }

        .slick-next {
          right: 25px;

          @include mobile {
            right: 10px;
          }
        }
      }

      &-item {
        width: 100%;
        cursor: pointer;
      }

      &-mini {
        margin: 16px 0 16px -14px !important;

        .ant-col {
          flex: auto;
          max-width: calc(25% - 14px);
          width: calc(25% - 14px);
          margin: 0 0 14px 14px;
          position: relative;
          border: none;
          border-radius: 8px;
          overflow: hidden;
          cursor: pointer;

          &.active {
            border: 2px solid $mainBlueColor;
          }
        }

        &-button {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #F7F7F7;
          opacity: 0.9;
          cursor: pointer;
        }
      }
      &-empty {
        margin-bottom: 40px;
      }
    }

    &-description {
      &.ant-row {
        font-size: 14px;
        line-height: 20px;
        word-break: break-word;

        @include mobile {
          display: none;
        }

        &.mobile-view {
          @include mobile {
            display: block;
          }
        }
      }

      &-title {
        font-size: 28px;
        margin-bottom: 32px;
      }

      &.mobile-view {
        display: none;
      }
    }

    &-name {
      .ant-typography {
        font-weight: 600;
        font-size: 48px;
        line-height: 56px;
        margin-bottom: 8px;

        @include mobile {
          font-size: 32px;
          line-height: 40px;
        }
      }
    }

    &-name-container {
      margin-bottom: 16px;
    }

    &-rate {
      margin-bottom: 16px;
      align-items: center;

      .ant-rate {
        .ant-rate-star {
          margin: 0 0 0 1px;

          div {
            &:hover {
              transform: none;
            }
          }
        }
      }

      .rating {
        display: inline-block;
        font-size: 18px;
        font-weight: 600;
        margin: 5px 4px 0 0;
      }
    }
    &-data {
      img {
        width: 24px;
        height: 24px;
        margin: 0 14px 8px 0;
      }
      span {
        font-size: 14px;
        font-weight: 600;
        color: $greyColor2;
      }
    }

    &-location {
      display: flex;
      align-items: center;

      &-row {
        font-size: 14px;
        font-weight: 600;
        color: $greyColor2;
      }
    }
  }

  &__facilities-container {
    margin-top: 10px;

    &:first-child {
      margin-top: 0;
    }
  }

  &__facilities {
    width: 100%;
    margin: 48px 0;

    &.mobile-view {
      display: none;
    }

      > span, > div {
      width: 100%;
    }
    .listing__field-facilities-item {
      @include tablet {
        width: 100%;
      }
    }

    .listing__field-facilities-item-room-type {
      width: 100%;
    }
  }

  &__facilities-label-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    img {
      width: 20px;
      margin-left: 12px;
    }
  }

  &__facilities-label {
    display: inline-block;
    font-size: 28px;
    line-height: 28px;
  }

  &__facilities-see-all {
    margin-top: 5px;
    cursor: pointer;
  }

  &__facilities-icon {
    width: 24px;
    margin-right: 16px;
  }

  &__details-container {
    position: sticky;
    top: 110px;
    z-index: 5;
  }

  &__additional-info {
    &.ant-col {
      margin-top: 32px;
    }
  }

  &__rating {
    &.ant-col {
      text-align: right;
      padding-right: 50px !important;

      @media screen and (max-width: $laptopMax) {
        padding-right: 32px !important;
      }

      @include mobile {
        text-align: left;
        margin-top: 8px;
      }
    }
  }

  &__ref-number {
    color: $greyColor4;
    margin-top: 16px;
  }

  &__room-type {
    padding-left: 38px;
  }

  &__price {
    margin: 32px 0 16px;

    > div {
      width: 100%;
    }
    &-original {
      margin: 0 0 14px;
      color: $greyColor2;

      b {
        font-weight: 700;
        margin-left: 6px;
      }
    }
    &-selling {
      display: flex;
      align-items: flex-end;

      b {
        font-size: 24px;
        line-height: 26px;
      }
      .listing-view__price-discount {
        display: inline-block;
        padding: 0 8px 14px 4px;

        span {
          padding: 2px 4px;
          border-radius: 4px;
          font-size: 14px;
          font-weight: 400;
          background-color: #009688;
          color: white;
        }
      }
      .listing-view__price-days {
        color: $greyColor2;
      }
    }
  }

  &__non-refundable {
    color: $greyColor2;
  }

  &__seller-info {
    display: flex;
    align-items: center;
    margin: 24px 0 8px;
    padding: 16px;
    background-color: $greyColor3;
    color: $greyColor2;

    .icon {
      margin: 0 15px;
    }
  }

  &__admin-info {
    &.ant-col {
      display: flex;
      align-items: center;
    }
  }

  &__commissions {
    margin-top: 32px;

    &-money {
      text-align: right;
    }

    &-total {
      padding: 10px 0 0;
      border-top: 1px solid #D8D8D8;
      font-weight: 600;
      font-size: 18px;
    }

    .ant-row{
      margin: 8px 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    @include mobile {
      margin-bottom: 32px;
    }
  }
  &__submit {
    .button {
      min-width: 146px;
      height: 40px;
    }
    &.mobile-view {
      display: none;
    }
  }
  &__status {
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 15px;
    text-align: center;

    &--published, &--active {
      color: #00A021;
    }
    &--sold {
      color: $mainBlueColor;
    }
    &--draft {
      color: $greyBorderColor;
    }
    &--expired {
      color: $dangerColor;
    }
    &--suspended {
      color: $errorColor;
    }
    &--canceled {
      color: $dangerColor;
    }
    &--pending {
      color: $mainYellowColor;
    }
    .listing-view__dispute-status {
      margin-left: 5px;
    }
  }
  &__documents {
    &.ant-col {
      margin-top: 32px;
      font-size: 14px;
    }

    &-item {
      display: flex;
      align-items: center;
      margin: 4px 0;
      cursor: pointer;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      span {
        margin-left: 8px;
        color: #000;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  &__dispute {
    margin-top: 16px;

    &-submit {
      text-transform: uppercase;
      color: #9E9E9E;
      cursor: pointer;

      &.active:hover {
        color: #000000;
      }
    }
    &-status {
      text-transform: uppercase;
      color: #9E9E9E;
    }
  }
  &__carousel-modal {
    display: none;
    justify-content: center;
    align-items: center;

    &.visible {
      display: flex;

      @include mobile {
        display: none;
      }
    }
    .listing-view__carousel-modal-close {
      position: absolute;
      top: 24px;
      right: 24px;
      padding: 4px;
      cursor: pointer;
    }
  }
  &__user-native-price {
    margin-top: 5px;
    background: $greyColor3;
    padding: 16px;
  }
  &__user-native-price-value {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  &__user-native-price-text-row {
    margin-bottom: 15px;
  }

  &__cancel-order-container {
    text-align: right;

    .listing-view__admin-info {
      text-align: left;
    }
  }

  &__cancel-order {
    margin-top: 16px;
  }

  &__item-ref {
    margin-top: 16px;
  }
}

.listing-view {
  @include mobile {
    .listing-view {
      &__status, &__documents-wrapper {
        padding: 0 16px;
      }
      &__hotel {
        &-images {
          max-width: 100vw;
        }
        &-images-mini {
          display: none;
        }
        &-name {
          font-size: 24px;
          line-height: 31px;
        }
      }

      &__facilities {
        display: none;

        &.mobile-view {
          display: block;
          padding: 16px;
          margin-bottom: 32px;
        }
      }

      &__dispute-submit {
        text-align: center;
        padding: 8px 16px 24px;
      }
      &__submit {
        display: none;

        &.mobile-view {
          display: block;
          margin: 16px 0 -30px;

          .button {
            width: 100%;
            border-radius: 0;
          }
        }

        &.mobile-view--hidden {
          display: none;
        }

        &.mobile-view--dispute {
          margin: auto;
        }
      }
      &__facilities .listing__field-facilities-item {
        width: 100%;
      }
    }
    .mobile-padding {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}

.listing__loader {
  position: absolute;
  width:100%;
}

.listing-view-loading {
  opacity: 0.6;
  filter: grayscale(1) blur(1px);

  .details-transparent {
    opacity: 0.2;
  }

  .listing-view__hotel-description {
    opacity: 0.2;
    filter: blur(2px);
  }

  .image-bg-cover {
    opacity: 1;
    animation: pulseStrong 2s infinite;
  }

}

@keyframes pulseStrong {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
}
