.feedback {
  &__back-link-container {
    margin-bottom: 30px;
  }

  &__user {
    color: $greyColor2;
  }

  &__user-name {
    margin-bottom: 5px;
    font-size: 18px;
    font-weight: 600;
    color: $blackColor;
  }

  &__user-email {
    display: inline-block;
    margin-left: 16px;
    font-weight: 600;
  }

  &__message-title {
    color: $greyColor;
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
}
