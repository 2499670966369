@mixin mobileES {
  @media screen and (max-width: $mobileES) { @content; }
}

@mixin mobile {
  @media screen and (max-width: $mobileMax) { @content; }
}

@mixin tablet {
  @media screen and (min-width: $tabletMin) and (max-width: $tabletMax) { @content; }
}

@mixin laptop {
  @media screen and (min-width: $laptopMin) and (max-width: $laptopMax) { @content; }
}

@mixin desktop {
  @media screen and (min-width: $desktopMin) { @content; }
}

@mixin desktopMiddle {
  @media screen and (min-width: $desktopMiddle) { @content; }
}

@mixin trim($numLines: null){
  @if $numLines != null {
    display: -webkit-box;
    -webkit-line-clamp: $numLines;
    -webkit-box-orient: vertical;
    overflow: hidden;
  } @else {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
  }
}
