.article-list {
  .ant-table-content {
    overflow-y: auto;
  }

  &__search-wrapper {
    display: flex !important;

    .ant-input-search {
      width: auto;
    }
    .button {
      margin-left: 16px;
      padding: 4px 15px;
    }
  }
  &__image {
    width: 68px;
    height: 40px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }
  &__items {
    .ant-select {
      width: 116px;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.65);
    }
    &-name {
      min-width: 100px;
      word-break: break-word;
    }
  }
  &__categories {
    max-width: 200px;
  }
  &__filter {
    &-size {
      min-width: 64px;
    }
    &-category {
      min-width: 150px;
      max-width: 150px;
    }
  }
  .category-name {
    padding: 0 8px 1px;
    margin-right: 4px;
    border-radius: 4px;
    background-color: $mainYellowColor;
  }
}
