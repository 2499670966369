.dispute {

  &__container {
    .form-section__label {
      font-size: 14px;
      font-weight: 600;
      color: black;
    }
    .file-upload__container  .ant-checkbox-group .ant-checkbox-group-item {
      width: 100%;
    }
    textarea.ant-input {
      min-height: 66px;
    }
  }

  &-list__filter-status {
    min-width: 150px;
  }

  &__title {
    font-size: 18px;
    font-weight: 600;
  }

  &__text {
    color: $greyColor2;
    display: flex;
    flex-wrap: nowrap;

    span {
      display: inline-block;
      min-width: 85px;
      width: 85px;
    }
    b {
      font-weight: 600;
    }
  }
  &__user {
    &.buyer {
      border-right: 1px solid #D8D8D8;
    }
    &-title {
      text-transform: uppercase;
      font-size: 12px;
      color: rgba(0,0,0,0.5);
    }
    &-text {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
      width: calc(100% - 49px);

      .dispute__text {
        width: 100%;
      }
    }
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin: 8px 8px 0 0;
    }
  }
  .seller-listings__item {
    margin-bottom: 24px;
  }
  .form-section {
    margin: 0 0 24px;

    &__label {
      margin-top: 0;
    }
    &__input.ant-input-number {
      width: 150px;
    }
    .hidden {
      display: none;
    }
  }
  &__price-wrapper {
    display: flex !important;
    flex-wrap: nowrap;

    .form-section__field {
      width: auto;
      margin-right: 8px;
    }
  }
  &__submit-button {
    margin-left: 24px;
    width: 160px;
  }
  &__add-note {
    .dispute__user-title {
      margin-bottom: 16px;
    }
    .button {
      margin-top: 8px;
      width: 100%;
    }
  }
  .profile-history__list-item {
    padding: 0;
    border: none !important;

    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0 8px;
    }
    &-field {
      margin: 0;
      padding: 8px 16px;
      border-top: 1px solid #d9d9d9;
    }
  }
}
