.form-input {
  caret-color: $mainBlueColor;

  &:focus.ant-input,
  &:focus.ant-input-password,
  &:hover.ant-input-affix-wrapper-focused,
  &.ant-input-affix-wrapper-focused {
    border-color: $mainBlueColor;
    box-shadow: none;
  }

  &--error.ant-input,
  &--error.ant-input-password {
    border-color: $errorBorderColor;

    &:focus {
      border-color: $errorBorderColor;
    }
  }
}
