.transactions {
  overflow: auto;

  .ant-table-wrapper {
    min-width: 700px;
  }

  &__hotel-image {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    margin-right: 16px;
  }

  .ant-table {
    color: inherit !important;
    min-width: 600px;
    overflow: auto;

    .ant-table-tbody > tr.ant-table-row-level-0:hover > td {
      background: unset;
    }

    .ant-table-tbody > tr:last-child {
      .ant-table-cell {
        border-bottom: none;
        padding-bottom: 0;
      }
    }

    .ant-table-placeholder {
      display: flex;
      justify-content: center;

      .ant-table-cell {
        padding: 0;
      }
    }

    .ant-table-thead {
      display: none;
    }
  }

  &__amount-wrapper {
    display: flex;
    align-items: center;
  }

  &__amount {
    font-size: 20px;
    color: $mainBlueColor;
    width: 120px;
    text-align: right;
  }

  .ant-menu {
    margin-bottom: 32px;

    @include mobile {
      padding: 0 18px;
    }

    .ant-menu-item {
      padding: 0 5px;
      margin-right: 32px;

      @include mobile {
        font-size: 12px;
        margin-right: 16px;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .ant-table {
    font-size: 14px !important;

    @include mobile {
      font-size: 12px !important;
    }

    .ant-table-thead {
      .ant-table-cell {
        &:first-child {
          padding-left: 36px;

          @include mobile {
            padding-left: 0;
          }
        }

        &:last-child {
          padding-right: 36px;
          text-align: right;

          @include mobile {
            padding-right: 16px;
          }
        }
      }
    }

    .ant-table-tbody {
      .ant-table-cell {
        &:first-child {
          font-weight: normal;
          padding-left: 0;

          @include mobile {
            padding-left: 8px;
          }
        }

        &:last-child .transactions__amount-wrapper {
          color: #424242;
          font-weight: 600;
          display: flex;
          justify-content: flex-end;

          @include mobile {
            padding-right: 16px;
          }
        }
      }
    }
  }

  &__icon {
    margin-right: 16px;
    width: 16px;
  }

  &__type {
    display: flex;
    align-items: center;
  }

  &__pagination-container {
    display: flex;
    justify-content: center;
    margin-top: 42px;
  }

  &__pending-item {
    background: #FEFBEA;
    padding: 4px 16px;
    border-radius: 4px;
    color: #F7D300;
  }

  &__pending-amount {
    color: $greyColor4;
  }
}
