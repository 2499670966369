html {
  overflow-y: auto;
}

body {
  position: relative;
  font-family: "SF Pro Display", serif;
  overflow-y: auto;
  min-width: 375px;
}

.section,
.list-item {
  margin-bottom: 32px;
}

.list-pagination {
  text-align: center;
}

.button {
  &.ant-btn {
    height: auto;
    border-radius: 4px;
    padding: 8px 24px;
  }

  &--default {
    &.ant-btn {
      color: $blackColor;
      background: $whiteColor;

      &:hover,
      &:focus {
        background: $whiteColor;
        color: $blackColor;
        border-color: $mainBlueColor;
      }
    }
  }

  &--danger {
    &.ant-btn {
      color: $whiteColor;
      background: $dangerColor;
      border-color: $dangerColor;

      &:hover,
      &:focus {
        color: $whiteColor;
        background: $dangerColor;
        border-color: $dangerColor;
      }

      &:disabled,
      &:disabled:hover,
      &:disabled:focus {
        color: $whiteColor;
        background: $dangerColorDisabled;
        border-color: $dangerColorDisabled;
      }
    }
  }

  &--primary {
    &.ant-btn {
      color: $blackColor;
      background: $mainYellowColor;
      border-color: $mainYellowColor;

      &:hover,
      &:focus {
        background: $mainYellowColor;
        color: $blackColor;
        border-color: $mainYellowColor;
      }

      &:disabled,
      &:disabled:hover,
      &:disabled:focus {
        color: #7c7c7c;
        background-color: $mainYellowColorDisabled;
        border-color: $mainYellowColorDisabled;
      }
    }
  }

  &--responsive {
    img {
      display: none;
      height: 20px;
    }

    @include mobile {
      padding: 5px !important;

      span {
        display: none !important;
      }
      img {
        display: block;
      }
    }
  }
}

.container {
  &-small {
    max-width: 969px !important;
    padding: 0 16px;

    @include tablet {
      max-width: 100% !important;
    }

    @include mobile {
      max-width: 100vw !important;
    }
  }

  &-medium {
    max-width: 1032px !important;
    padding: 0 16px;

    @include mobile {
      max-width: 100vw !important;
    }
  }

  &-large {
    max-width: 1366px !important;
    margin: 0 auto;
  }

  &-xl {
    max-width: 1440px !important;
    margin: 0 auto;
  }
}

.text-semi-bold {
  font-weight: 600;
}

.ql-editor {
  &.ql-editor--content-parsed {
    line-height: inherit;
    padding: 0;

    img {
      max-width: 100%;
    }

    a {
      color: $mainBlueColor;
      opacity: 0.8;
    }
  }
}

.animation-slide-box {
  overflow: hidden;
  width: 100%;
  height: 100%;

  &__inner {
    position: relative;
    width: 0;
    height: 100%;
    background-color: $mainBlueColor;
    animation-name: slide;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    -webkit-box-shadow: 0 0 100px 100px $mainBlueColor;
    -moz-box-shadow: 0 0 100px 100px $mainBlueColor;
    box-shadow: 0 0 100px 100px $mainBlueColor;

    @-webkit-keyframes slide {
      0% {
        left: -200%;
      }
      100% {
        left: 300%;
      }
    }

    @keyframes slide {
      0% {
        left: -200%;
      }
      100% {
        left: 300%;
      }
    }
  }
}

.drag-sorting-table tr.drop-over-downward td {
  border-bottom: 2px dashed $mainBlueColor;
}

.drag-sorting-table tr.drop-over-upward td {
  border-top: 2px dashed $mainBlueColor;
}

.dob-picker {
  .ant-select {
    width: 100%;
  }
}

.link {
  text-decoration: underline;

  &--primary {
    @extend .link;
    color: $mainBlueColor;
  }
}

.react-tel-input {
  .form-control {
    width: 100%;
    height: 48px;
    font-size: 16px;
    border-color: #d9d9d9;
    border-radius: 4px;

    &::placeholder {
      font-size: 16px;
    }
  }
}

.lazyload-wrapper {
  height: 100%;
}

.ant-typography.title-level-4 {
  font-size: 23px;
}

.swiper-container {
  overflow: hidden;
}

.freeprivacypolicy-com---nb .cc-nb-main-container {
  padding: 2rem !important;
}

.freeprivacypolicy-com---palette-light .cc-cp-foot-save,
.freeprivacypolicy-com---palette-light .cc-nb-okagree,
.freeprivacypolicy-com---palette-light .cc-nb-reject {
  background: #467CCF !important;
}

.freeprivacypolicy-com---pc-dialog .cc-cp-foot-byline {
  font-size: 11px !important;
}
