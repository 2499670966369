.switch-button {
  &.ant-btn {
    background-color: #2636B80A;
    border-radius: 32px;
    color: #000000;
    border: none;
    box-shadow: none;
    padding: 8px 16px;
    height: unset;
  }

  &.ant-btn:hover,
  &.ant-btn:focus {
    background-color: #161F6A0A;
    color: #000000;
  }

  &--active.ant-btn {
    background-color: $mainBlueColor;
    color: #ffffff;
  }

  &--active.ant-btn:hover,
  &--active.ant-btn:focus {
    background-color: $mainBlueColor;
    color: #ffffff;
  }
}
