.user-management {
  .profile-info {
    padding: 24px 32px 16px;

    &__avatar-img-container {
      width: 80px;
      height: 80px;
      margin-bottom: 28px;
      border: 1px dotted $greyBorderColor;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
    &__text {
      margin-bottom: 16px;

      h3 {
        color: #000000;
        font-size: 24px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 28px;
      }
      &-info {
        display: flex;
        color: $greyColor2;
        font-size: 14px;
        line-height: 18px;

        span {
          display: block;
          width: 82px;
        }
        b {
          font-weight: 600;
        }
      }
    }
    &__status {
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;

      .button {
        padding: 7px 25px;
      }
      .ant-select-single .ant-select-selector {
        height: 38px;
        width: 112px;
      }
      &-select {
        display: flex;
        flex-wrap: nowrap;

        .select-with-label__field {
          margin-right: 16px;
        }
      }
    }
  }
  &__verification {
    margin-bottom: 8px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.4px;
    line-height: 15px;

    .button {
      margin: 16px 0 8px;
    }
  }
  .kyc-section {
    padding: 16px 30px;

    p, span {
      color: $greyColor2;
      font-size: 14px;
      margin-bottom: 0;
    }
    &__info {
      div {
        display: flex;
        align-items: center;
        margin-bottom: 6px;
      }
      img {
        width: 18px;
        height: 18px;
        margin-right: 14px;
      }
    }
    &__status {
      flex-wrap: wrap;
      flex-direction: column;
      margin-bottom: 8px;
      font-size: 12px;
      letter-spacing: 0.4px;
      line-height: 15px;

      div {
        margin: 0;
      }
      img {
        width: 18px;
        height: 18px;
        margin-right: 14px;
      }
      &-info {
        display: flex;
        flex-wrap: nowrap;

        b {
          text-transform: uppercase;
        }
      }
      &-document p {
        margin: 16px 0;

        span {
          margin-right: 8px;
        }
      }
      &-files {
        > div {
          display: flex;
          margin-bottom: 4px;
          cursor: pointer;
        }
      }
      &-buttons {
        margin-top: 16px !important;
      }
      .button--danger {
        margin-right: 16px;

        span {
          color: $whiteColor;
        }
      }
    }

    .address__view {
      display: flex;
      align-items: center;
      margin-top: 10px;
      margin-bottom: 0;
    }

    .address__view-text {
      display: block;
      color: $greyColor2;
      margin-bottom: 0;

      div {
        margin-bottom: 0;
      }
    }

    &__hint {
      color: $greyColor2;
      margin-top: 10px !important;
    }
  }

  &__listings {
    margin-top: 32px;

    .ant-table-content {
      overflow-y: auto;
    }
  }
}
.status-text {
  &--not-verified {
    color: #555555;
  }
  &--verified {
    color: #00A021;
  }
  &--failed {
    color: #CB3E2A;
  }
  &--pending {
    color: #F08505;
  }
}
