.additional-service {
  &__row {
    margin-bottom: 20px;
  }

  &__section-title {
    text-transform: uppercase;
    margin-bottom: 10px;
    color: $greyColor;
    font-size: 12px;
  }
}
