.admin-reservations {
  .admin-reservation-search {
    &__title {
      font-size: 1.6em;
      margin-bottom:10px;
    }
    &__submit {
      margin-left: 30px;
      margin-bottom: 20px;
      margin-top:-10px;
    }
    &__result {
      margin-left: 30px;
      border:1px solid silver;
      padding: 20px;
      word-break: break-all;
      white-space: pre-wrap;
    }
  }
  .admin-reservation-modify {
    &__title {
      font-size: 1.6em;
      margin-bottom:10px;
    }
    &__submit {
      margin-left: 30px;
      margin-bottom: 20px;
      margin-top:-10px;
    }
    &__result {
      margin-left: 30px;
      border:1px solid silver;
      padding: 20px;
      word-break: break-all;
      white-space: pre-wrap;
    }
  }
}
