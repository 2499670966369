.ant-pagination {
  text-transform: uppercase;
  font-weight: 600;

  &-item {
    border-color: $mainYellowColor !important;
    font-weight: 600 !important;

    &:focus, &:hover, &-active {
      background-color: $mainYellowColor !important;

      a {
        color: inherit !important;
      }
    }
  }

  .ant-pagination-button {
    display: flex;
    align-items: center;

    &:not([disabled]) {
      color: #000;
    }
  }

  &-prev span {
    margin: 0 15px 0 10px;
  }

  &-next span {
    margin: 0 10px 0 15px;
  }

  &.ant-table-pagination {
    float: unset !important;
    text-align: center;
    margin: 30px 0 !important;
  }
}

.ant-table {
  font-size: 12px !important;
  color: rgba(0,0,0,0.65) !important;

  a {
    color: rgba(0,0,0,0.65);

    &:hover,
    &:active,
    &:visited {
      color: rgba(0,0,0,0.65);
    }
  }

  .ant-table-tbody {
    .ant-table-cell {
      &:first-child {
        color: #424242;
        font-weight: 600;

        a {
          color: #424242;
        }
      }
    }
  }
}

.ant-list {
  .ant-list-pagination {
    margin: 32px 0;
    text-align: center;
  }
}

.ant-picker-dropdown {
  .ant-picker-header-view {
    font-weight: 600;

    button {
      &:hover {
        color: black;
      }
    }
  }

  .ant-picker-content {
    .ant-picker-cell {
      &:not(.ant-picker-cell-in-view) {
        color: rgba(0, 0, 0, 0.5);
      }
    }

    .ant-picker-cell-inner {
      border-radius: 2px;
    }

    .ant-picker-cell-selected {
      .ant-picker-cell-inner {
        color: $whiteColor;
        background: $mainBlueColor;
        border-radius: 2px;

        &:before {
          border-color: $mainBlueColor;
          border-radius: 2px;
        }
      }
    }

    .ant-picker-cell-today {
      .ant-picker-cell-inner {
        &:before {
          border-color: $mainBlueColor;
          border-radius: 2px;
        }
      }
    }
  }

  .ant-picker-footer {
    .ant-picker-today-btn {
      color: $blueCheckboxColor;
    }
  }
}

.ant-rate-disabled .ant-rate-star > div {
  &:hover, &:focus {
    transform: scale(1);
  }
}

.ant-checkbox {
  .ant-checkbox-inner {
    border-color: #666;
  }
}
