.password-updated {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mobile {
    box-shadow: none;
    padding: 24px 16px 12px;
  }

  &__title {
    font-size: 26px;
    font-weight: bold;
    color: $blackColor;
    margin-bottom: 8px;
    max-width: 80%;
    text-align: center;
  }

  &__icon {
    margin: 23px 0;
  }

  &__login-link {
    width: 100%;
    margin: 18px 0 20px;
  }
}
