.notification-list {
  .ant-table-row {
    cursor: pointer;
  }
  .unread {
    .ant-table-cell, .ant-table-column-sort {
      background-color: #F2F2F2;
    }
  }
}
