.selling {
  &__top-background {
    background-image: url('/images/selling/hotel-room-selling.png');
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 700px;
    position: absolute;
    z-index: 1;

    @include tablet {
      height: 1000px;
    }

    @include mobile {
      height: 1150px;
      margin-top: -16px;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.70);
    }
  }

  &__section {
    @extend .container-large;

    margin-bottom: 100px;

    .plainHtml {
      h1 {
        font-size: 48px;
        line-height: 72px;
        font-weight: 400;
        margin-bottom: 0;
      }
      p {
        font-size: 20px;
        margin-bottom: 0;
      }
    }
    .asais {
      p {
        margin: 24px 0 64px;
        font-size: 16px;
      }
    }

    @include mobile {
      margin-bottom: 64px;
    }

    &--reviews {
      margin-bottom: 150px;
    }

    &--top {
      position: relative;
      top: 95px;
      z-index: 5;
      color: $whiteColor;

      @include mobile {
        top: 32px;
      }

      .plainHtml {
        h1 {
          font-family: "Lato", sans-serif;
          color: $whiteColor;

          @include mobile {
            font-size: 36px;
          }
        }
      }
    }
  }

  &__steps-title {
    @extend .plainHtml;

    font-size: 16px;
    margin-bottom: 4px;

    @include tablet {
      margin-top: 72px;
    }

    @include mobile {
      margin-top: 72px;
    }
  }

  &__steps-bg-container {
    border-radius: 7px;
    background: rgba(224, 228, 255, 0.24);
    margin-bottom: 128px;

    .divider {
      height: 1px;
      opacity: 0.2;
      background: $whiteColor;
    }
  }

  &__steps-container {
    padding: 36px 16px;

    @include mobile {
      padding: 24px 16px;
    }

    .plainHtml {
      h1 {
        font-family: "Lato", sans-serif;
        font-size: 55px;
        line-height: 58px;
      }

      h2 {
        color: $whiteColor;
        margin-bottom: 0;
        font-size: 30px;
        line-height: 58px;
      }
    }
  }

  &__img {
    max-width: 100%;
  }

  &__preview-listing-img {
    max-width: 100%;
    box-shadow: 0 4px 14px 2px rgba(0, 0, 0, 0.20);
  }

  &__hotel-room-selling {
    margin-top: -22px;
    text-align: center;

    img {
      max-width: 100%;

      @include tablet {
        object-fit: cover;
        height: 450px;
      }
    }
  }

  &__head {
    &.ant-row {
      position: absolute;
      bottom: 10%;
    }

    .plainHtml {
      h1 {
        color: $whiteColor;
        font-weight: normal;
      }
    }
  }

  &__title {
    h1 {
      font-family: "Lato", sans-serif;
      font-weight: normal;
      font-size: 45px;
      line-height: 58px;
      margin-bottom: 0;

      @include mobile {
        font-size: 36px;
      }
    }
  }

  &__description {
    font-size: 20px;

    @include laptop {
      font-size: 16px;
    }

    @include tablet {
      font-size: 16px;
    }

    @include mobile {
      font-size: 16px;
    }
  }

  &__plans-changed-container {
    display: inline-flex;
    align-content: center;
    justify-content: space-between;
    margin-bottom: 25px;
    background: $mainBlueColor;
    border-radius: 4px;
    color: $whiteColor;
    font-size: 14px;
    padding: 3px 12px;
    border: 2px solid rgba(95, 107, 195, 0.48);
    box-shadow: 2px 4px 30px 0px rgba(0, 0, 0, 0.46);

    @include mobile {
      margin-bottom: 16px;
    }

    &:hover,
    &:active,
    &:visited {
      color: $whiteColor;
    }
  }

  &__listing-review-subtitle {
    font-weight: 400;
    font-size: 15px;
    margin-bottom: 8px;
    margin-top: 22px;
  }

  &__selling-guide-block {
    margin-top: 24px;
  }

  &__block-title {
    &.ant-typography {
      font-size: 24px;
      font-weight: 400;
      line-height: 32px;

      @include mobile {
        font-size: 22px;
        font-weight: 600;
      }
    }

    &--blue {
      &.ant-typography {
        color: $mainBlueColor;
      }
    }
  }

  &__selling-guide-point {
    display: flex;
    align-items: flex-start;
    margin: 4px 0;
    font-weight: 500;
    min-height: 75px;

    &:first-child {
      margin-top: 16px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    @include mobile {
      font-weight: 400;
    }
  }

  &__selling-guide-icon-container {
    min-width: 35px;
    margin-right: 10px;
  }

  &__first-time-selling-description {
    margin-bottom: 16px;
  }

  &__first-time-selling-point-container {
    margin: 24px 0;
    display: flex;
    align-items: flex-start;

    @include mobile {
      font-size: 14px;
    }
  }

  &__first-time-selling-point {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $pinkColor;
    width: 20px;
    height: 20px;
    margin: 2px 8px 0 0;
    border-radius: 50%;
    color: $whiteColor;
    font-size: 10px;
    padding: 10px;
  }

  &__selling-guide-button {
    display: block;
    margin-top: 48px;
    background: $mainBlueColor;
    border-radius: 8px;
    width: 150px;
    padding: 12px;
    color: $whiteColor;
    font-weight: 500;
    font-size: 14px;

    &:hover,
    &:active,
    &:visited {
      color: $whiteColor;
    }
  }

  &__simple-subtitle {
    margin: 24px 0 64px;
  }

  &__block {
    height: 100%;
    padding: 32px;
    font-size: 14px;

    .asaisCard {
      h1 {
        color: #3d4cc1;
      }
      h2 {
        color: #3d4cc1;
      }
      h3 {
        color: #3d4cc1;
      }
      h4 {
        color: #3d4cc1;
        font-size: 24px;
        font-weight: 400;
        line-height: 32px;
      }
      p {
        margin-top: 0;
        margin-bottom: 1em;
        font-size: 14px;
      }
    }
  }

  &__subtitle {
    font-size: 28px;
    line-height: 40px;
    margin: 8px 0 16px;
    font-weight: 300;

    &.ant-typography {
      font-size: 28px;
      line-height: 40px;
      font-weight: 300;
      margin: 8px 0 16px;
    }

    @include mobile {
      font-size: 20px;
      font-weight: 600;
    }
  }

  &__small-text {
    font-size: 14px;

    p {
      font-size: 14px !important;
    }
  }

  &__seller-info-title {
    font-size: 16px;
    margin-bottom: 56px;
  }

  &__step {
    &.ant-col {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 16px 0;
    }

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      background: $whiteColor;
      border-radius: 50%;
      width: 44px;
      height: 44px;
      margin-right: 16px;
      padding: 16px;
    }
  }

  &__questions {
    padding: 40px;

    @include mobile {
      padding: 24px;
    }
  }

  &__questions-sell-link {
    text-decoration: underline;

    &:hover,
    &:active,
    &:visited {
      text-decoration: underline;
    }
  }

  &__questions-links {
    &.ant-row {
      margin-top: 32px;
    }
  }

  &__questions-button {
    display: block;
    border: 1px solid $mainBlueColor;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    padding: 7px;

    &:hover,
    &:active,
    &:visited {
      color: $mainBlueColor;
    }

    &--blue {
      background: $mainBlueColor;
      color: $whiteColor;

      &:hover,
      &:active,
      &:visited {
        color: $whiteColor;
      }
    }
  }

  &__review-heading {
    margin-bottom: 32px;
  }

  &__review-title {
    font-size: 20px;

    @include mobile {
      font-weight: 600;
    }
  }

  &__create-listing-button {
    display: inline-block;
    border-radius: 4px;
    margin-top: 42px;
    background: $mainBlueColor;
    color: $whiteColor;
    font-size: 18px;
    font-weight: 500;
    padding: 10px 26px;

    &:hover,
    &:active,
    &:visited {
      color: $whiteColor;
    }
  }

  &__underscore {
    background: $pinkColor;
    height: 4px;
    width: 68px;
    margin-top: 16px;
  }

  &__listing-preview-block-right-container {
    &.ant-col {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      h1 {
        font-family: "Lato", sans-serif;
        font-size: 65px;
        line-height: 95px;

        @include laptop {
          font-size: 48px;
          line-height: 72px;
        }

        @include mobile {
          font-size: 36px;
          line-height: 48px;
        }
      }

      border: 1px solid #FFEEF6;
      background: rgba(253, 158, 206, 0.04);
    }
  }

  &__listing-preview-block-right {
    &.ant-col {
      padding: 36px 36px 48px;

      @include mobile {
        padding: 24px 24px 32px;
      }

      .selling__create-listing-button {
        padding: 10px 48px;

        @include mobile {
          padding: 6px 26px;
        }
      }
    }
  }

  &__service {
    &.ant-col {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 55px;

      @include mobile {
        margin-bottom: 32px;
      }

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        background: $whiteColor;
        border-radius: 50%;
        border: 1px solid #3D4CC14D;
        width: 44px;
        height: 44px;
        margin-right: 16px;
        padding: 16px;
      }
    }
  }

  &__fees-cards-container {
    &.ant-row {
      display: flex;
      margin-top: 38px;
    }
  }

  &__fees-card-container {
    height: 100%;
  }

  &__fees-card {
    position: relative;
    border: 1px solid #FFEEF6;
    background: rgba(253, 158, 206, 0.02);
    box-shadow: 0 0 30.4px 0 rgba(0, 0, 0, 0.10);
    border-radius: 4px;
    padding-bottom: 80px;
  }

  &__fees-card-title {
    color: $whiteColor;
    padding: 24px 35px;
    font-size: 25px;
    background: $mainBlueColor;
    border-radius: 4px 0 0 4px;

    @include laptop {
      font-size: 18px;
    }

    @include mobile {
      font-size: 16px;
      padding: 14px 24px;
      border-radius: 4px 0 0 0;
    }
  }

  &__fees-card-price {
    color: $whiteColor;
    font-size: 45px;
    font-weight: 700;
    background: $pinkColor;
    border-radius: 0 4px 4px 0;
    text-align: right;
    padding: 8px 35px;

    @include laptop {
      font-size: 36px;
    }

    @include mobile {
      font-size: 24px;
      border-radius: 0 4px 0 0;
    }
  }

  &__fees-card-subtitle {
    padding: 20px 35px;
    font-style: italic;
    color: $greyColor;
    border-bottom: 1px solid #F1DEE5;
  }

  &__fees-card-description {
    &.ant-col {
      padding: 30px;

      @include mobile {
        padding: 16px;
      }
    }
  }

  &__fees-card-line {
    &.ant-col {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 18px;
      font-weight: 400;
      padding: 18px 0;

      @include mobile {
        padding: 10px 0;
        font-size: 16px;
      }

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        background: $whiteColor;
        border-radius: 50%;
        border: 1px solid #F1DEE5;
        width: 36px;
        height: 36px;
        margin-right: 16px;
        padding: 16px;
      }
    }
  }

  &__fees-card-note {
    &.ant-col {
      position: absolute;
      bottom: 0;
      width: 100%;
      font-size: 12px;
      padding: 30px 32px;

      @include mobile {
        padding: 16px;
      }
    }
  }
}
