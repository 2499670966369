.reset-password {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mobile {
    box-shadow: none;
    padding: 24px 16px 12px;
  }

  &__form {
    width: 100%;
    margin: 24px 0;
  }

  &__title {
    font-size: 26px;
    font-weight: bold;
    color: $blackColor;
    margin-bottom: 16px;
  }

  &__subtitle {
    text-align: center;
    font-size: 14px;
    color: $blackColor;
  }

  &__field {
    margin-bottom: 24px;
    font-size: 14px;
  }

  &__label {
    font-size: 12px;
    color: $greyColor;
  }

  &__input {
    &:focus.ant-input,
    &:focus.ant-input-password {
      border-color: $greyBorderColor;
      box-shadow: none;
    }

    &--error.ant-input,
    &--error.ant-input-password {
      border-color: $errorBorderColor;

      &:focus {
        border-color: $errorBorderColor;
      }
    }
  }

  &__error {
    display: block;
    color: $errorColor;
    font-size: 12px;
  }

  &__submit.ant-btn {
    width: 100%;
    height: 45px;
    font-size: 18px;
    font-weight: 600;
    color: $blackColor;
    margin: 18px 0 20px;
    border-radius: 8px;
    padding: 0 32px;

    &:hover,
    &:focus {
      color: $blackColor;
      border: $mainYellowColor;
    }
  }
}
