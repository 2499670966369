.share-listing {
  &__margin-top {
    margin-top: 32px;
  }

  &__container {
    text-align: center;
    padding: 55px 25px;

    @include mobile {
      padding: 32px 16px;
    }
  }

  &__container-back {
    text-align: center;
    margin-bottom:  20px;

    &__top_margin {
      margin-top:  20px;
    }
  }

  &__icon {
    display: inline-block;
    margin-bottom: 25px;
  }

  &__text {
    color: #424242;
    font-size: 16px;
  }

  &__list {
    max-width: 360px;
    margin: 0 auto;
    text-align: left;
    font-size: 16px;

    li {
      list-style-image: url("/images/check-grey.svg");
    }
    a, a:active, a:link, a:hover, a:visited {
      color: $blueCheckboxColor;
    }
  }

  &__share-link {
    margin: 0 12px;
  }

  &__back {
    margin: 40px 0 20px;

    &.ant-btn {
      padding: 5px 15px;
    }
    &:last-child {
      margin-left: 16px;
    }
  }
}
