.admin-resale-deals {
  &__title {
    text-transform: uppercase;
    margin-bottom: 15px;
    color: $greyColor;
    font-size: 12px;
  }

  &__row {
    &.ant-row {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__label {
    font-weight: bold;
    margin-bottom: 8px !important;
    font-size: 14px;
  }

  .ql-editor {
    height: 200px;
  }

  &__submit {
    margin: 16px 32px 32px;

    .button {
      padding: 7px 40px;
    }
  }

  &__error {
    display: block;
    color: $errorColor;
    font-size: 12px;
  }
}
