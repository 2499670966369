.main-content {
  position: relative;
  min-height: 100vh;
  padding-bottom: 300px;
  background-color: #FCFCFC;

  @media screen and (max-width: $tabletMax) {
    padding-bottom: 560px;
  }
}

.ant-layout-content {
  background-color: #fcfcfc;
  padding: 16px 0;
}

.legal-content {
  margin-top: 35px;
}

.pointer {
  cursor: pointer;
}
