.admin-event {
  .file-upload {
    &__container {
      color: $greyColor;
      font-size: 12px;

      .ant-upload.ant-upload-drag {
        display: flex;
        justify-content: center;
        width: 168px;
        height: 105px;
        margin-bottom: 7px;
        color: $greyColor;
      }
      .ant-upload.ant-upload-btn {
        padding: 0;
      }
      p {
        margin: 0;
      }
    }
    &__image {
      &-container {
        width: 168px;
        height: 105px;
        margin-bottom: 7px;
        overflow: hidden;
        position: relative;
        cursor: default;
      }
      &-preview {
        width: 100%;
        height: 100%;
      }
      &-delete {
        position: absolute;
        top: 6px;
        right: 6px;
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }
    &__text-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: $greyColor;
    }
    &__button {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 12px;
      padding: 5px 15px;
      color: $blackColor;
      border: 1px solid $mainYellowColor;
      background: $whiteColor;
      border-radius: 4px;
      max-width: 75px;
      margin-top: 5px;
    }
    &__error {
      color: $errorColor;

      .ant-upload.ant-upload-drag {
        border-color: $errorBorderColor;
      }
    }
  }

  .form-section__input {
    width: 100%;
  }

  &__dropdown-location {
    min-width: 400px !important;
  }
}
