.auth-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.auth-content {
  min-height: 100vh;
  background-color: #FCFCFC;
}

.auth-row {
  min-height: calc(100vh - 135px);
  padding: 10px 0 40px;

  > div {
    max-width: 484px;
  }
}
