.admin {
  &-content {
    margin: 32px 0;
  }

  &-sidebar {
    margin-top: -13px;

    .menu.ant-menu {
      @include mobile {
        display: block;
      }
    }
  }

  &-website-management {
    &.ant-col {
      margin-top: -1px;
      margin-bottom: 16px;
    }

    .menu.ant-menu {
      line-height: 25px;
    }

    @include mobile {
      padding: 0 15px;
    }
  }

  &-section {
    background: $greyColor3;
    padding: 20px 32px;
    margin-bottom: 16px;
  }
}
