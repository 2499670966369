.bank-account-form {
  &__info-container {
    &.profile-section {
      width: 100%;

      @include mobile {
        margin: 16px;
      }
    }
  }

  &__info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__info-icon {
    margin-right: 25px;
  }

  &__info-text {
    text-align: justify;
    font-size: 14px;
    color: $greyColor2;
  }

  &__row {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__sort-code-inputs-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type=number] {
      -moz-appearance: textfield;
    }

    .input-with-label__field {
      width: 48px;
      margin-right: 10px;

      @include mobile {
        margin-right: 5px;
      }

      &:last-child {
        margin-right: 0;
      }

      .ant-input-number-handler-wrap {
        display: none;
      }
    }
  }

  &__aba-number-inputs-container {
    .input-with-label__field {
      max-width: 120px;
    }
  }

  &__sort-code-label {
    font-size: 12px;
    color: $greyColor;
  }

  &__error,
  &__sort-code-error {
    color: $errorColor;
    font-size: 12px;
  }

  &__title {
    text-transform: uppercase;
    margin-bottom: 10px;
    color: $greyColor;
    font-size: 12px;
  }

  &__submit-container {
    @include mobile {
      padding: 16px;
    }
  }

  &__submit.ant-btn {
    margin: 32px;

    @include mobile {
      margin: 16px 0;
      width: 100%;
    }
  }
}
