.admin-hotel {
  .form-section__input {
    width: 100%;

    &.ant-select {
      width: 100%;
    }
  }

  .react-tel-input .form-control,
  input {
    height: 45px;
  }

  &__dropdown-location {
    min-width: 400px !important;
  }

  .form-file-upload__container {
    &.ant-upload.ant-upload-drag {
      height: 250px;
    }
  }
}
