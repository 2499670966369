.contact-us {
  min-height: calc(100vh - 215px);

  .plainHtml {
    h1 {
      font-size: 38px;
      margin-bottom: 0.5em;
      color: rgba(0,0,0,.85);
      font-weight: 600;
      line-height: 1.23;
    }
    h2 {
      font-size: 23px;
    }
    p {
      font-size: 18px;
      margin: 0;
    }
  }

  &__title-info {
    margin-bottom: 2em;
  }

  &__info-description {
    margin-bottom: 2em;
  }

  &__contact {
    margin-bottom: 0.5em;
  }

  &__info-description, &__contact {
    font-size: 18px;
  }

  &__social-list {
    padding: 0;
    margin: 0 -16px;

    li {
      display: inline;
      padding: 0 16px;
    }
  }

  &__textarea-description.textarea-with-label__input {
    min-height: 100px;
  }

  &__social-icon {
    height: 32px;
    width: 32px;
  }

  &__title-container {
    padding-right: 100px;

    &.ant-col {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    @include tablet {
      padding-right: 30px;
    }

    @include mobile {
      padding: 16px;
    }
  }

  &__title {
    &.ant-typography {
      font-size: 38px;
    }
  }

  &__text-info {
    margin-bottom: 2em;

    @include mobile {
      font-size: 14px;
    }
  }

  &__form {
    background: $greyColor3;
    padding: 16px 32px;

    @include mobile {
      padding: 16px;
      margin-bottom: 16px;
    }
  }

  &__row {
    margin-bottom: 16px;
  }

  &__first-name {
    padding-right: 12px;

    @include mobile {
      padding-right: 0;
      margin-bottom: 16px;
    }
  }

  &__last-name {
    padding-left: 12px;

    @include mobile {
      padding-left: 0;
    }
  }

  &__submit {
    margin-top: 8px;

    @include mobile {
      width: 100%;
    }
  }

  &__success {
    &.ant-col {
      padding: 85px 32px;
      text-align: center;
      color: #424242;
      font-size: 16px;
    }
  }

  &__success-icon {
    margin-bottom: 25px;
  }

  &__submit-btn-wrapper {
    @media screen and (max-width: 576px) {
      display: flex;
      justify-content: center;
    }
  }
}
