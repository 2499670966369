.user-content {
  position: relative;
  padding-bottom: 450px;
  min-height: 100vh;

  @media screen and (max-width: $tabletMax) {
    padding-bottom: 560px;
  }

  .card-verification-failed {
    background: rgba(203, 62, 42, 0.1);
    padding: 5px 0;

    &__message {
      display: flex;
      align-items: center;
    }

    &__icon {
      max-height: 48px;
      max-width: 48px;
      margin-right: 32px;
    }

    &__title {
      color: $dangerColor;
      font-weight: bold;
    }

    &__link {
      color: $blueCheckboxColor;

      &:hover,
      &:visited,
      &:active {
        color: $blueCheckboxColor;
      }
    }
  }

  .profile-content {
    padding: 16px 0;

    @include mobile {
      padding: 16px;
    }
  }
}
