.listing-card-details-modal {
  text-align: left !important;
  padding: 0 5px !important;

  &__container {
    width: 100%;
  }

  &__title {
    font-size: 24px;
    font-weight: 600;
  }

  .modal__buttons {
    @include mobile {
      flex-direction: column;
    }

    .button {
      &.ant-btn {
        padding: 7px 15px;
        margin: 8px 0 0;
      }
    }

    .profile-details__submit-container {
      margin-top: 24px;
      text-align: right;
    }
  }
}
